import { combineReducers } from 'redux';

import main from 'src/store/main';
import hrBoards from 'src/pages/HrBoards/store/reducer';
import enums from 'src/store/enums';
import erpDiagram from 'src/pages/ErpDiagram/store';
import userAccount from 'src/pages/Account/store';
import portfolioBuilder from 'src/pages/documents/PortfolioBuilder/store';
import cvBuilder from 'src/pages/documents/CVBuilder/store';
import projects from 'src/pages/Projects/store';
import staff from 'src/pages/Staff/store';
import articles from 'src/pages/Articles/store';
import inventory from 'src/pages/Inventory/store';
import extra from 'src/pages/ExtraHours/store';
import team from 'src/pages/Team/store';
import reviewers from 'src/pages/Reviewers/store';
import requestsPage from 'src/pages/ListOfRequests/store';
import internship from 'src/pages/Internship/store';

export const fullStore = {
  main,
  hrBoards,
  enums,
  userAccount,
  portfolioBuilder,
  cvBuilder,
  projects,
  staff,
  articles,
  erpDiagram,
  inventory,
  extra,
  team,
  reviewers,
  requestsPage,
  internship,
};

const combinedReducer = combineReducers(fullStore);

export const RESET_STORE_ACTION_TYPE = 'RESET_WHOLE_STORE';

const rootReducer = (state, action) => {
  if (action.type === RESET_STORE_ACTION_TYPE) {
    return combinedReducer(undefined, action);
  }

  return combinedReducer(state, action);
};

export const resetStore = () => ({ type: RESET_STORE_ACTION_TYPE });

export default rootReducer;
