import styled from 'styled-components';

const StyledSyllabus = styled.div`
  .nav-header {
    display: flex;
    margin-bottom: 20px;
    .header-syllabus {
      border-bottom: solid 2px ${({ theme }) => theme.colors.primary.main};
    }
  }

  .tasks {
  }

  .tasks-holder {
    margin: 40px auto;
    width: 100%;
    max-width: 1600px;

    .task-description {
      font-size: 18px;
      a {
        color: ${({ theme }) => theme.colors.primary.main};
        text-decoration: none;
      }

      a:hover {
        color: ${({ theme }) => theme.colors.iconsIntern.new};
        text-decoration: underline;
      }
      code {
        background-color: black;
        border-radius: 4px;
        padding: 2px 6px;
        font-family: "Courier New", Courier, monospace;
        color: inherit;
        border: 1px solid ${({ theme }) => theme.colors.navbar.text};
      }

      pre {
        tab-size: 2;
        background-color: black;
        border-radius: 5px;
        padding: 10px;
        font-family: "Courier New", Courier, monospace;
        color: inherit;
        border: 1px solid ${({ theme }) => theme.colors.navbar.text};
        overflow-x: auto;
        white-space: pre-wrap;
        line-height: 1.5;
        max-height: 400px;
        overflow-y: auto;
        word-wrap: break-word;
        overflow-wrap: break-word;
        hyphens: auto;
        white-space: normal;
      }

      p {
        margin: 5px 0;
        font-size: inherit;
      }

      ol,
      ul {
        padding-left: 25px;
      }
      .ql-align-center {
        text-align: center;
      }

      .ql-align-right {
        text-align: end;
      }
      .ql-align-justify {
        text-align: justify;
      }

      blockquote {
        border: dotted ${({ theme }) => theme.colors.primary.primaryGray} 1px;
        border-left: solid ${({ theme }) => theme.colors.primary.main} 7px;
        padding: 20px;
        background-color: ${({ theme }) => theme.colors.diagram.headerBorder};
        color: inherit;
        font-style: italic;
        font-size: 14px;
      }

      .ql-align-justify {
        text-align: justify;
      }
    }
  }
`;

export default StyledSyllabus;

export const StyledLottie = styled.div`
  display: flex;
  justify-content: center;
  height: 100svh;
  width: 100%;
  .container-lottie {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 50%;
    font-size: 1.4rem;
    text-align: center;
    transform: translateY(-10%);
  }

  @media (max-width: 700px) {
    .container-lottie {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 100%;
      font-size: 1rem;
      text-align: center;
      transform: translateY(-10%);
    }
  }
`;
