import localeRu from 'date-fns/locale/ru';
import formatDistanceToNowStrict from 'date-fns/formatDistanceToNowStrict';
import addYears from 'date-fns/addYears';
import addMonths from 'date-fns/addMonths';
import startOfDay from 'date-fns/startOfDay';
import _random from 'lodash/random';
import moment from 'moment';

import store from 'src/store';
import { setToken } from 'src/apiV2/axios';
import { resetStore } from 'src/store/rootReducer';
// import { signOut } from 'api/authApi';

import token from './token';

export const logOut = () => {
  store.dispatch(resetStore());
  token.access.set(null);
  token.refresh.set(null);
  setToken(null);
  // signOut();
};

export const isMdWidth = (size = '') => {
  return ['sm', 'xs'].includes(size);
};

/**
 * @param {object} user
 * @param {"full" | "short" | "initials"} type
 * @param {"ru" | "en"} lang
 */
export const getFullName = (user, type = 'full', lang = 'ru') => {
  const firstName = (lang === 'ru' ? user?.firstName_ru || user?.firstName : user?.firstName) || '';
  const lastName = (lang === 'ru' ? user?.lastName_ru || user?.lastName : user?.lastName) || '';

  let fullName;

  switch (type) { // eslint-disable-line default-case
    case 'full':
      fullName = `${lastName} ${firstName}`.trim();
      break;

    case 'short':
      fullName = `${lastName} ${firstName.substring(0, 1)}.`;
      // If only a dot
      if (fullName.length === 1) {
        fullName = user?.email;
      }
      break;

    // For avatars
    case 'initials':
      fullName = `${lastName.substring(0, 1)}${firstName.substring(0, 1)}`;
      if (!fullName) {
        fullName = user?.email?.substring(0, 1);
      }
      break;
  }
  return fullName || user?.email;
};

// BURN IT WITH FIRE!!!
export const getHrAvatar = (id) => {
  const state = store.getState();
  const hrs = state.hrBoards.hrs || [];
  let hrAvatar = null;
  for (let i = 0; i < hrs.length; i++) {
    if (hrs[i].id === id) {
      hrAvatar = hrs[i].avatar;
      break;
    }
  }
  return hrAvatar;
};

export const getDistanceToNow = (date) => {
  const getNumberFromString = (str) => {
    return +str.match(/[0-9]*/g)[0];
  };

  const yearString = formatDistanceToNowStrict(
    date,
    { unit: 'year', locale: localeRu, roundingMethod: 'floor' },
  );
  const yearsCount = getNumberFromString(yearString);

  const montsDate = addYears(date, yearsCount);
  const monthsString = formatDistanceToNowStrict(
    montsDate,
    { unit: 'month', locale: localeRu, roundingMethod: 'floor' },
  );
  const monthsCount = getNumberFromString(monthsString);

  const daysDate = addMonths(montsDate, monthsCount);
  const daysString = formatDistanceToNowStrict(
    daysDate,
    { unit: 'day', locale: localeRu, roundingMethod: 'floor' },
  );

  return `${yearString} ${monthsString} ${daysString}`.replace(/(?<=^| )0 [^0-9 ]+/gi, '') || '0 дней';
};

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const WEEK = DAY * 7;
const MONTH = DAY * 30;
const YEAR = DAY * 365;

const durationStrings = {
  year: ['год', 'года', 'лет'],
  month: ['месяц', 'месяца', 'месяцев'],
  week: ['неделю', 'недели', 'недель'],
  day: ['день', 'дня', 'дней'],
};

/**
 * @param {number | null} count
 * @param {"year" | "month" | "week" | "day"} type
 * @returns {string}
 */
export const getDurationItemStringString = (count, type) => {
  if (!count) {
    return '';
  }

  let postfix;
  const lastNumber = +count.toString().slice(-1);

  if (lastNumber === 1) {
    postfix = durationStrings[type][0];
  } else if (lastNumber > 1 && lastNumber < 5) {
    postfix = durationStrings[type][1];
  } else {
    postfix = durationStrings[type][2];
  }

  return `${count} ${postfix}`;
};

/**
 * @param {Date} date
 * @returns {string}
 */
export const getDuration = (date) => {
  if (!date || new Date(date) < new Date()) {
    return 'Свободен';
  }
  if ((Number(startOfDay(date)) === Number(startOfDay(new Date())))) {
    return 'Освобождается завтра';
  }

  let dateDiff = new Date(date) - Date.now();

  const years = Math.floor(dateDiff / YEAR);
  dateDiff -= years * YEAR;
  const months = Math.floor(dateDiff / MONTH);
  dateDiff -= months * MONTH;
  const weeks = Math.floor(dateDiff / WEEK);
  dateDiff -= weeks * WEEK;
  const days = Math.floor(dateDiff / DAY);

  const durationString = [
    getDurationItemStringString(years, 'year'),
    getDurationItemStringString(months, 'month'),
    getDurationItemStringString(weeks, 'week'),
    getDurationItemStringString(days, 'day'),
  ]
    .join(' ')
    .trim()
    .replace(/ {2,}/gm, ' ');

  return `освобождается через ${durationString}`;
};

/**
 * @param {Array<any>} list
 */
export const getRandomListItem = (list) => {
  return list[_random(0, list.length - 1, false)];
};

/**
 * @param {Array} list
 * @param {Array} functionsArray
 */
export const applyFiltersToList = (list, functionsArray) => {
  let newList = list;
  functionsArray.forEach((func) => {
    if (Array.isArray(newList)) {
      newList = [...func(newList)];
    } else {
      newList = { ...func(newList) };
    }
  });
  return newList;
};

/**
 *
 * @param {File} file
 * @returns {Promise<File>}
 */
export const convertFileToBase64 = (file) => {
  const reader = new FileReader();
  return new Promise((resolve, reject) => {
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.onerror = () => {
      reject(reader.error);
    };
    reader.readAsDataURL(file);
  });
};

/**
 * @param {Array<File>} fileList
 * @returns {Promise<Array<string>>}
 */
export const convertFileListToBase64 = async (fileList) => {
  const newFileList = fileList.map(async (file) => {
    if (typeof file !== 'string') {
      const result = await convertFileToBase64(file);
      return result;
    }
    return file;
  });

  return Promise.all(newFileList);
};

export const getDateRange = (start, end) => {
  return `${moment(start).format('DD.MM.YYYY')}
  ${(end !== start) ? ` - ${moment(end).format('DD.MM.YYYY')}` : ''}
  `;
};

export const getTotalTime = (duration) => {
  const totalHours = Math.floor(duration / 60);
  const totalMinutes = duration - totalHours * 60;
  return {
    totalHours,
    totalMinutes,
  };
};

export const updateUserData = (item) => {
  const technologies = item.technologies.map((tech) => {
    const technology = { ...tech };
    technology.level = tech.user_technologies.level;
    technology.userId = tech.user_technologies.userId;
    delete technology.user_technologies;
    return technology;
  });
  const crmTasks = item.tasks.map((item) => {
    const task = { ...item.crm_task };
    task.role = item.role;
    return task;
  });
  const user = { ...item, technologies, crmTasks };
  delete user.filter_technologies;
  delete user.tasks;
  return user;
};

export default {
  logOut,
  isMdWidth,
  getFullName,
  getRandomListItem,
};
