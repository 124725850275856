import styled from 'styled-components';

const StyledTask = styled.div`
  border-bottom: solid 1px gray;

  .MuiTypography-root {
    width: 100%;
    overflow: hidden;
    white-space: normal;
    word-wrap: break-word;
    hyphens: auto;
    text-overflow: ellipsis;
    text-align: center;
  }
  .task__ready-finish {
    display: flex;
    font-size: 10px;
    max-width: 170px;
    max-height: 27px;
    justify-content: center;
    align-items: center;
    .MuiButtonBase-root {
      text-align: center;
    }
    .iuptHB {
      font-size: 1rem;
    }
  }
  .task__ready-finish-accordion {
    display: none;
    .MuiButtonBase-root {
      padding: 6px 0;
    }
  }
  .MuiAccordion-root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
  }

  .MuiAccordionSummary-content {
    display: grid;
    grid-template-columns: 23px minmax(30px, max-content) 100px;
    gap: 1%;
    align-items: center;
  }
  .MuiTypography-root {
    line-height: 170%;
  }
  .MuiSvgIcon-root {
  }
  .MuiAccordionDetails-root {
    padding: 10px 16px;
    .task__level-limit {
      margin-top: 20px;
    }
  }
  .MuiPaper-root {
    background: none;
  }

  @media (max-width: 700px) {
    .MuiAccordionSummary-content {
      display: grid;
      grid-template-columns: 23px minmax(30px, max-content) 100px;
      gap: 5%;
      align-items: center;
    }
    .task__ready-finish {
      display: none;
    }
    .task__ready-finish-accordion {
      display: block;
    }
  }
`;

export default StyledTask;
