import useTheme from '@mui/material/styles/useTheme';

import type {
  Breakpoint,
  Theme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

// eslint-disable-next-line @typescript-eslint/naming-convention
type BreakpointOrNull = Breakpoint | null;

const useWidth = () => {
  const theme: Theme = useTheme();
  const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
};

export default useWidth;
