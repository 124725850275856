import React from 'react';
import PropTypes from 'prop-types';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmModal = (props) => {
  const { onClose, onAccept, title, content, open } = props;

  return (
    <Dialog
      open={open}
      onClose={onClose}
    >
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {content}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button onClick={onAccept} color="primary">
          Подтвердить
        </Button>
        <Button onClick={onClose} color="primary">
          Отмена
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConfirmModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onAccept: PropTypes.func,
  title: PropTypes.string,
  content: PropTypes.string,
};

ConfirmModal.defaultProps = {
  open: false,
  onClose: () => null,
  onAccept: () => null,
  title: 'Удалить элемент',
  content: 'Вы действительно хотите удалить этот элемент?',
};

export default ConfirmModal;
