import classnames from 'classnames';
import type { TaskType } from '../utils/types';
import React, { useCallback, useState } from 'react';
import IconTaskProcess from './IconTaskProcess';

type PropsType = {
  task: TaskType;
  toOpenTaskDetail: (task: TaskType) => void;
  internTaskIds?: (number | null | undefined)[] | undefined;
  isParenTask?: boolean | undefined;
};

const TaskItem: React.FC<PropsType> = (props) => {
  const [lastTouchEnd, setLastTouchEnd] = useState(0);

  const handleDoubleClick = () => {
    props.toOpenTaskDetail(props.task);
  };

  const handleTouchEnd = useCallback(() => {
    const now = Date.now();
    let timeout;

    if (now - lastTouchEnd < 300) {
      clearTimeout(timeout);
      timeout = setTimeout(() => {
        props.toOpenTaskDetail(props.task);
      }, 300);
    }
    setLastTouchEnd(now);
  }, [lastTouchEnd, props]);

  const isDisabledTask = React.useMemo(() => {
    return (
      props?.internTaskIds && props.internTaskIds.includes(props.task.taskJobId)
    );
  }, [props.internTaskIds, props.task.taskJobId]);

  return (
    <div
      className={classnames('task', {
        'task-disabled': isDisabledTask,
        'task-intern': props.isParenTask === false,
        deadline: props.task.icon === 'deadline',
      })}
      onTouchEnd={handleTouchEnd}
      onDoubleClick={handleDoubleClick}
    >
      {props.task.icon && <IconTaskProcess icon={props.task.icon} />}
      <p className="title__grid">{props.task.title}</p>
    </div>
  );
};

export default TaskItem;
