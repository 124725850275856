import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

const Modal = (props) => {
  const { onClose, title, children, open, maxWidth, scroll, onExited } = props;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth={maxWidth}
      scroll={scroll}
      TransitionProps={{ onExited }}
      disableEscapeKeyDown={props.onEscapeKeyDown}
    >
      <div className="modal__header">
        <IconButton
          color="primary"
          size="small"
          className="modal__close-btn"
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      </div>

      <DialogContent className="modal__content">
        <DialogTitle className="modal__title">{title}</DialogTitle>

        {children}
      </DialogContent>
    </StyledDialog>
  );
};

const StyledDialog = styled(Dialog)`
  @media (max-width: 426px) {
    .MuiDialog-container {
      margin: 0 20px;
    }
  }
  .MuiDialog-paper {
    max-width: 800px;
    overflow: visible;
    position: relative;
    ${({ theme }) => theme.respond(
    'sm',
    `
      width: 100%;
      margin: 0;
    `,
  )};
  }

  .modal__content {
    overflow: visible;
    padding: 20px 10px;
  }
  .modal__title {
    display: flex;
    justify-content: center;
    padding: 10px 0 15px;
  }
  .modal__close-btn {
    position: absolute;
    right: 10px;
    top: 10px;
  }
`;

Modal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  title: PropTypes.string,
  maxWidth: PropTypes.string,
  children: PropTypes.element,
  scroll: PropTypes.string,
};

Modal.defaultProps = {
  open: false,
  onClose: () => null,
  onExited: () => null,
  title: '',
  children: null,
  maxWidth: 'xs',
  scroll: 'body',
};

export default Modal;
