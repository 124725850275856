export enum UserRoleENUM {
  student = 'student',
  user = 'user',
  hr = 'hr',
  sales = 'sales',
  admin = 'admin',
  officeManager = 'officeManager',
  mentor = 'mentor',
}

export enum UserStatusENUM {
  registered = 'registered',
  active = 'active',
  disabled = 'disabled',
}

export enum UserTechRoleENUM {
  admin = 'admin',
  sales = 'sales',
  techLead = 'techLead',
  projectManager = 'projectManager',
  developer = 'developer',
  designer = 'designer',
  qaEngineer = 'qaEngineer',
  hr = 'hr',
  officeManager = 'officeManager',
  englishTeacher = 'englishTeacher',
}

export type UserType = {
  userId: number;
  id: number;
  createdAt?: string;
  updatedAt?: string;
  lastActivity?: string;
  workingFrom?: string;
  status: UserStatusENUM;
  role: UserRoleENUM;
  techRole: UserTechRoleENUM | null;
  isDev: boolean;
  login?: string;
  email: string;
  phone?: string;
  firstName: string | null;
  firstNameRu: string | null;
  lastName: string | null;
  lastNameRu: string | null;
  fullName: string | null;
  fullNameRu: string | null;
  dateOfBirth?: string | null;
  avatar: string | null;
  avatarThumbnail: string | null;
  education?: string | null;
  educationRu?: string | null;
  info?: string | null;
  repo?: string[] | null;
  additionalContactName?: string | null;
  additionalContactPhone?: string | null;
  additionalContactType?: string | null;
  resetPasswordExpires?: string | null;
  resetPasswordToken?: string | null;
  slackConversationalCrmId?: string | null;
  slack_conversationalId?: string | null;
  slackName?: string | null;
  slackMainId?: string | null;
  slackInternsId?: string | null;
  sphera_chat_id?: string | null;
  mentor?: UserType | null;
  mentor_id?: number | null;
};

export enum UserSortByENUM {
  userId = 'userId',
  firstName = 'firstName',
  lastName = 'lastName',
  isDev = 'isDev',
  role = 'role',
  status = 'status',
  techRole = 'techRole',
}
