import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import internship from './thunks';
import _ from 'lodash';
import type {
  ChangeDateType,
  InternHolderType,
  PlanTaskJobType,
  TaskType,
  IStore,
} from '../utils/types';
import {
  addInPlanTaskAction,
  addInternTaskAction,
  deleteFromPlanTaskAction,
  deleteTaskAction,
  deleteTaskPlanAction,
  updatePlanTaskInfoAction,
  updateTaskInfoAction,
  updateTaskStatusAction,
  updateTaskStatusFromInternAction,
} from './actions';
import type { ResponseDeleteTaskType } from 'src/apiV3/internshipApi';

const tasks: TaskType[] = [];
const userIdChain: number[] = [];
const planTaskJobs: PlanTaskJobType[] = [];
const internsHoldersTasks: InternHolderType[] = [];
const currentInternId: number | null = null;
const getInitialStore = (): IStore => ({
  tasks,
  userIdChain,
  planTaskJobs,
  currentInternId,
  internsHoldersTasks,
});

const internshipSlice = createSlice({
  name: 'internship',
  initialState: getInitialStore,
  reducers: {
    setTasks: (store, { payload }: PayloadAction<TaskType[]>) => {
      store.tasks = payload;
    },
    setCurrentInternGlobalState: (
      store,
      action: PayloadAction<number | null>,
    ) => {
      store.currentInternId = action.payload;
    },
    clearPlanHolder: (store) => {
      store.planTaskJobs = [];
      store.userIdChain = [];
      store.currentInternId = null;
    },
    changeDateStartPlanTaskJob: (
      store,
      { payload }: PayloadAction<ChangeDateType>,
    ) => {
      const currentPlanTaskJob = store.internsHoldersTasks
        .find((holder) => holder.id === store.currentInternId)
        ?.plantTasks.find(
          (planTaskJobs) => planTaskJobs.planTaskJobId === payload.id,
        );
      if (!currentPlanTaskJob) return;
      currentPlanTaskJob.startTask = new Date(payload.date);
    },
    changeDateEndPlanTaskJob: (
      store,
      { payload }: PayloadAction<ChangeDateType>,
    ) => {
      const currentPlanTaskJob = store.internsHoldersTasks
        .find((holder) => holder.id === store.currentInternId)
        ?.plantTasks.find(
          (planTaskJobs) => planTaskJobs.planTaskJobId === payload.id,
        );
      if (!currentPlanTaskJob) return;
      currentPlanTaskJob.finishTask = new Date(payload.date);
    },

    [deleteTaskPlanAction.type](store, { payload }: PayloadAction<ResponseDeleteTaskType>) {
      const { actionFlag, id } = payload;

      if (actionFlag !== 'plan') return;

      const planHolderForCurrentUser = store.internsHoldersTasks.find(
        (plan) => plan.id === store.currentInternId,
      );

      if (!planHolderForCurrentUser) return;

      const { plantTasks, chains } = planHolderForCurrentUser;

      const updatedTasks = plantTasks.filter(
        (task) => task.taskJob.taskJobId !== id,
      );

      if (updatedTasks.length === plantTasks.length) return;

      const deletedTask = plantTasks.find(
        (task) => task.taskJob.taskJobId === id,
      );

      const updatedChains = chains.filter(
        (chain) => chain !== deletedTask?.planTaskJobId,
      );

      planHolderForCurrentUser.plantTasks = updatedTasks;
      planHolderForCurrentUser.chains = updatedChains;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(addInternTaskAction, (store, { payload }) => {
      store.tasks.push(payload);
    });

    builder.addCase(updateTaskStatusAction, (store, { payload }) => {
      const { actionFlag, planTaskJobId } = payload;
      const taskHolder = store.internsHoldersTasks.find(
        (holder) => holder.id === store.currentInternId,
      );
      if (!taskHolder) return;

      const currentTask = taskHolder.plantTasks.find(
        (plan) => plan.planTaskJobId === planTaskJobId,
      );

      if (!currentTask) return;

      switch (actionFlag) {
        case 'start':
          currentTask.startTask = new Date();
          break;
        case 'finish':
          currentTask.finishTask = new Date();
          break;
        case 'reboot':
          currentTask.startTask = null;
          currentTask.finishTask = null;
          break;
        default:
          break;
      }
    });

    builder.addCase(updateTaskStatusFromInternAction, (store, { payload }) => {
      const { actionFlag, planTaskJobId } = payload;

      const currentPlanTask = store.planTaskJobs.find(
        (planTask) => planTask.planTaskJobId === planTaskJobId,
      );
      if (!currentPlanTask) return;

      if (actionFlag === 'start') {
        currentPlanTask.startTask = new Date();
      }
      if (actionFlag === 'finish') {
        currentPlanTask.finishTask = new Date();
      }
    });

    builder.addCase(addInPlanTaskAction, (store, { payload }) => {
      const { actionFlag, currentTask, index } = payload;
      if (actionFlag !== 'addTask') return;

      const internsHoldersTasks = store.internsHoldersTasks;
      const planHolderForCurrentUserIdx = internsHoldersTasks.findIndex(
        (plan) => plan.id === store.currentInternId,
      );

      const task = store.tasks.find((task) => task.taskJobId === currentTask);

      if (!task) return;

      const allChains = store.internsHoldersTasks.flatMap(
        (holder) => holder.chains,
      );

      const newPlanTaskJobId = Math.max(...allChains) + 1;

      const newTask = {
        planTaskJobId: newPlanTaskJobId,
        startTask: null,
        finishTask: null,
        status: null,
        taskJob: {
          ...task,
          parent: task.taskJobId,
          isParent: false,
          taskJobId: newPlanTaskJobId,
        },
      };
      if (planHolderForCurrentUserIdx === undefined || planHolderForCurrentUserIdx === -1) {
        store.internsHoldersTasks.push({
          id: store.currentInternId!,
          plantTasks: [newTask],
          chains: [newPlanTaskJobId],
        });
      } else {
        store.internsHoldersTasks[planHolderForCurrentUserIdx].plantTasks.push(newTask);
        store.internsHoldersTasks[planHolderForCurrentUserIdx].chains
          .splice(index, 0, newPlanTaskJobId);
      }
    });

    builder.addCase(deleteFromPlanTaskAction, (store, { payload }) => {
      const { actionFlag, currentTask } = payload;

      if (actionFlag !== 'deleteTask') return;

      const planHolderForCurrentUser = store.internsHoldersTasks.find(
        (plan) => plan.id === store.currentInternId,
      );

      if (!planHolderForCurrentUser) return;

      const { plantTasks, chains } = planHolderForCurrentUser;

      const updatedTasks = plantTasks.filter(
        (task) => task.taskJob.taskJobId !== currentTask,
      );

      if (updatedTasks.length === plantTasks.length) return;

      const deletedTask = plantTasks.find(
        (task) => task.taskJob.taskJobId === currentTask,
      );

      const updatedChains = chains.filter(
        (chain) => chain !== deletedTask?.planTaskJobId,
      );

      planHolderForCurrentUser.plantTasks = updatedTasks;
      planHolderForCurrentUser.chains = updatedChains;
    });

    builder.addCase(updateTaskInfoAction, (store, { payload }) => {
      const {
        title,
        description,
        timeLimits,
        level,
        id,
        flagAction,
      } = payload;

      const currentTask = store.tasks.find((task) => task.taskJobId === id);
      if (!currentTask || flagAction !== 'task') return;

      currentTask.title = title;
      currentTask.description = description;
      currentTask.timeLimits = timeLimits;
      currentTask.level = level;
    });

    builder.addCase(updatePlanTaskInfoAction, (store, { payload }) => {
      const {
        title,
        description,
        timeLimits,
        level,
        startTask,
        finishTask,
        id,
        flagAction,
      } = payload;

      if (!(flagAction === 'plan') || !store.currentInternId) return;
      const currentPlan = store.internsHoldersTasks.find(
        (intern) => intern.id === store.currentInternId,
      )?.plantTasks;
      const currentUserPlan = currentPlan?.find(
        (task) => task.planTaskJobId === id,
      );
      if (!currentUserPlan) return;

      currentUserPlan.taskJob.title = title;
      currentUserPlan.taskJob.description = description;
      currentUserPlan.taskJob.timeLimits = timeLimits;
      currentUserPlan.taskJob.level = level;
      currentUserPlan.startTask = startTask ?? currentUserPlan.startTask;
      currentUserPlan.finishTask = finishTask ?? currentUserPlan.finishTask;
    });

    builder.addCase(deleteTaskAction, (store, { payload }) => {
      store.tasks = store.tasks.filter(
        (task) => payload !== task.taskJobId,
      );
    });

    builder.addCase(deleteTaskPlanAction, (store, { payload }) => {
      const { actionFlag, id } = payload;

      if (actionFlag !== 'plan') return;

      const planHolderForCurrentUser = store.internsHoldersTasks.find(
        (plan) => plan.id === store.currentInternId,
      );

      if (!planHolderForCurrentUser) return;

      const { plantTasks, chains } = planHolderForCurrentUser;

      const updatedTasks = plantTasks.filter(
        (task) => task.taskJob.taskJobId !== id,
      );

      if (updatedTasks.length === plantTasks.length) return;

      const deletedTask = plantTasks.find(
        (task) => task.taskJob.taskJobId === id,
      );

      const updatedChains = chains.filter(
        (chain) => chain !== deletedTask?.planTaskJobId,
      );

      planHolderForCurrentUser.plantTasks = updatedTasks;
      planHolderForCurrentUser.chains = updatedChains;
    });

    builder
      .addCase(internship.changeIndexTasksIntern.pending, (store, { meta }) => {
        const { id, newChains } = meta.arg;

        const tasksHolder = store.internsHoldersTasks.find(
          (intern) => intern.id === id,
        );
        if (!tasksHolder) return;
        tasksHolder.chains = newChains;
      })
      .addCase(
        internship.changeIndexTasksIntern.rejected,
        (store, { meta }) => {
          const { prevChains, id } = meta.arg;
          const tasksHolder = store.internsHoldersTasks.find(
            (intern) => intern.id === id,
          );
          if (!tasksHolder) return;
          tasksHolder.chains = prevChains;
        },
      );

    builder.addCase(internship.getAllTasks.fulfilled, (store, { payload }) => {
      store.tasks = payload.tasks;
      store.internsHoldersTasks = payload.allPlan.map((plan) => {
        return {
          id: plan.intern,
          plantTasks: plan.tasks,
          chains: plan.chains,
        };
      });
    });

    builder.addCase(internship.getUserPlan.fulfilled, (store, { payload }) => {
      store.userIdChain = payload.userIdChain;
      store.planTaskJobs = payload.planTaskJobs;
    });
    builder
      .addCase(
        internship.postNewTaskForInterns.fulfilled,
        (store, { payload }) => {
          store.tasks[store.tasks.length - 1] = payload;
        },
      )
      .addCase(internship.postNewTaskForInterns.rejected, (store, action) => {
        store.tasks = action.payload as TaskType[];
      });
    builder
      .addCase(
        internship.updateTaskStatusFromMentor.rejected,
        (store, action) => {
          const { planTaskJobId } = action.meta.arg;
          const taskHolder = store.internsHoldersTasks.find(
            (holder) => holder.id === store.currentInternId,
          );
          const currentTaskIdx = taskHolder?.plantTasks.findIndex(
            (plan) => plan.planTaskJobId === planTaskJobId,
          );
          if (!taskHolder || currentTaskIdx === undefined || currentTaskIdx === -1) return;

          taskHolder.plantTasks[currentTaskIdx] = action.payload as PlanTaskJobType;
        },
      );
    builder
      .addCase(
        internship.updateTaskStatusFromIntern.rejected,
        (store, action) => {
          const { planTaskJobId } = action.meta.arg;
          let currentPlanTask = store.planTaskJobs.find(
            (planTask) => planTask.planTaskJobId === planTaskJobId,
          );
          if (!currentPlanTask) return;
          currentPlanTask = action.payload as PlanTaskJobType;
        },
      );

    builder
      .addCase(internship.addInPlanTask.fulfilled, (store, action) => {
        if (!store.currentInternId) return;
        const currentHolder = store.internsHoldersTasks.find(
          (internHolders) => internHolders.id === store.currentInternId,
        );
        if (!currentHolder) return;
        currentHolder.chains = action.payload.userIdChain;
        currentHolder.plantTasks = action.payload.planTaskJobs;
      })
      .addCase(internship.addInPlanTask.rejected, (store, action) => {
        if (!action.payload) return;
        const planHolderForCurrentUser = store.internsHoldersTasks.find(
          (plan) => plan.id === store.currentInternId,
        );
        if (!planHolderForCurrentUser) return;
        planHolderForCurrentUser.chains = action.payload.chains;
        planHolderForCurrentUser.plantTasks = action.payload.plantTasks;
      });

    builder
      .addCase(internship.deleteFromPlanTask.fulfilled, (store, action) => {
        if (!store.currentInternId) return;
        const currentHolder = store.internsHoldersTasks.find(
          (internHolders) => internHolders.id === store.currentInternId,
        );
        if (!currentHolder || !action.payload) return;
        currentHolder.chains = action.payload.userIdChain;
        currentHolder.plantTasks = action.payload.planTaskJobs;
      })
      .addCase(internship.deleteFromPlanTask.rejected, (store, action) => {
        if (!action.payload) return;
        const planHolderForCurrentUser = store.internsHoldersTasks.find(
          (plan) => plan.id === store.currentInternId,
        );
        if (!planHolderForCurrentUser) return;
        planHolderForCurrentUser.chains = action.payload.chains;
        planHolderForCurrentUser.plantTasks = action.payload.plantTasks;
      });

    builder
      .addCase(internship.updateTaskInfo.rejected, (store, action) => {
        const currentTaskIdx = store.tasks.findIndex(
          (task) => task.taskJobId === action.meta.arg.id,
        );
        if (currentTaskIdx === undefined || currentTaskIdx === -1) return;
        store.tasks[currentTaskIdx] = action.payload as TaskType;
      });

    builder
      .addCase(internship.updatePlanTaskInfo.rejected, (store, action) => {
        const currentPlan = store.internsHoldersTasks.find(
          (intern) => intern.id === store.currentInternId,
        )?.plantTasks;

        const currentUserPlanIdx = currentPlan?.findIndex(
          (task) => task.planTaskJobId === action.meta.arg.id,
        );
        if (!currentPlan || currentUserPlanIdx === undefined || currentUserPlanIdx === -1) return;
        currentPlan[currentUserPlanIdx] = action.payload as PlanTaskJobType;
      });
    builder
      .addCase(internship.deleteTask.rejected, (store, action) => {
        if (!action.payload?.deletedTask) return;
        store.tasks.push(action.payload.deletedTask);
      });
    builder
      .addCase(internship.deleteTaskPlan.rejected, (store, action) => {
        if (!action.payload) return;
        const planHolderForCurrentUser = store.internsHoldersTasks.find(
          (plan) => plan.id === store.currentInternId,
        );
        if (!planHolderForCurrentUser) return;
        planHolderForCurrentUser.chains = action.payload.chains;
        planHolderForCurrentUser.plantTasks = action.payload.plantTasks;
      });
  },
});

export const {
  setTasks,
  setCurrentInternGlobalState,
  clearPlanHolder,
  changeDateStartPlanTaskJob,
  changeDateEndPlanTaskJob,
} = internshipSlice.actions;

export default internshipSlice.reducer;
