import type { ListResponseType, UserType } from 'src/types';
import http from './http';
import type { PlanTaskJobType, TaskType } from 'src/pages/Internship/utils/types';
import { AxiosRequestConfig } from 'axios';

export type ResponseGetAllTasksType = {
  tasks: TaskType[];
  allPlan: {
    tasks: PlanTaskJobType[];
    intern: number;
    chains: number[];
  }[];
};

export type ResponsePlanType = {
  userIdChain: number[];
  planTaskJobs: PlanTaskJobType[];
};
export type ResponseUpdatePositionTaskType = {
  userId: number;
  actionFlag: 'deleteTask' | 'addTask';
  currentTask: number;
  index: number;
};
export type UpdateStatusStartFinishType = {
  planTaskJobId: number;
  actionFlag: 'start' | 'finish' | 'reboot';
};
export type UpdateChainType = {
  userId: number;
  idChains: number[];
};

export type ResponseDeleteTaskType = {
  userId: number | null;
  actionFlag: 'task' | 'plan';
  id: number;
};
const INTERNS_PATH = '/internship';

const updateMentor = (data: { userId: number; mentorId: number }) => {
  return http.patch<ListResponseType<UserType>>(`${INTERNS_PATH}/mentor`, data);
};

const getTasks = () => {
  return http.get<ListResponseType<ResponseGetAllTasksType>>(INTERNS_PATH);
};

const createTask = (data: TaskType) => {
  return http.post<ListResponseType<TaskType>>(`${INTERNS_PATH}/tasks`, data);
};

const getPlans = () => {
  return http.get<ListResponseType<ResponsePlanType>>(`${INTERNS_PATH}/plan`);
};

const updateTaskPosition = (data: ResponseUpdatePositionTaskType) => {
  return http.patch<ListResponseType<ResponsePlanType>>(
    `${INTERNS_PATH}/tasks`,
    data,
  );
};

const updateTaskInfoAsync = (data: TaskType) => {
  return http.patch<ListResponseType<null>>(`${INTERNS_PATH}/task`, data);
};

const updateTaskStatusAsync = (data: UpdateStatusStartFinishType) => {
  return http.patch<ListResponseType<Date>>(`${INTERNS_PATH}/status`, data);
};

const updateChainAsync = (data: UpdateChainType) => {
  return http.patch(INTERNS_PATH, data);
};

const deleteTask = (data: ResponseDeleteTaskType) => {
  return http.patch(`${INTERNS_PATH}/delete`, data);
};

export default {
  updateMentor,
  getTasks,
  createTask,
  getPlans,
  updateTaskPosition,
  updateTaskInfoAsync,
  updateTaskStatusAsync,
  updateChainAsync,
  deleteTask,
};
