import { isDev } from 'src/config';
import CoreStorageItem from './CoreStorageItem';

class LocalStorageItem<D> extends CoreStorageItem<D> {
  constructor(params: {
    key: string;
    shouldAddPrefixToKey?: boolean;
    defaultValue?: D | null;
    shouldUseEncoding?: boolean;
  }) {
    super({
      key: params.key,
      keyPrefix: 'fusion-staff__',
      shouldAddPrefixToKey: params.shouldAddPrefixToKey ?? true,
      shouldUseEncoding: params.shouldUseEncoding ?? !isDev,
      defaultValue: params.defaultValue || null,
    });
  }
}

export default LocalStorageItem;
