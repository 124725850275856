import { createAsyncThunk } from '@reduxjs/toolkit';
import type {
  ResponseDeleteTaskType,
  ResponseGetAllTasksType,
  ResponsePlanType,
  ResponseUpdatePositionTaskType,
  UpdateStatusStartFinishType,
} from 'src/apiV3/internshipApi';
import internshipApi from 'src/apiV3/internshipApi';
import type { AppStateType } from 'src/store/store';
import { toast } from 'react-toastify';
import _ from 'lodash';
import type { InternHolderType, PlanTaskJobType, TaskType } from '../utils/types';
import { addInPlanTaskAction, addInternTaskAction, deleteFromPlanTaskAction, deleteTaskAction, deleteTaskPlanAction, updatePlanTaskInfoAction, updateTaskInfoAction, updateTaskStatusAction, updateTaskStatusFromInternAction } from './actions';

const getAllTasks = createAsyncThunk<ResponseGetAllTasksType>(
  '/internship/getTasks',
  async () => {
    const response = await internshipApi.getTasks();

    return response.data.payload;
  },
);

const getUserPlan = createAsyncThunk('/internship/getPlans', async () => {
  const response = await internshipApi.getPlans();
  return response.data.payload;
});

const postNewTaskForInterns = createAsyncThunk(
  '/internship/postNewTaskForInterns',
  async (task: TaskType, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const currentStateTasks = (getState() as AppStateType).internship?.tasks;
    const { id, ...taskWithoutId } = task;
    dispatch(addInternTaskAction({
      ...task,
      taskJobId: id,
      isParent: false,
    }));

    try {
      const response = await internshipApi.createTask(taskWithoutId);
      toast.success('Задача создана');
      return response.data.payload;
    } catch (error) {
      toast.error('Не удалось добавить задачу');
      return thunkAPI.rejectWithValue(currentStateTasks);
    }
  },
);

const addInPlanTask = createAsyncThunk<
  ResponsePlanType,
  ResponseUpdatePositionTaskType,
  { rejectValue: InternHolderType | undefined }
>(
  '/internship/addInPlanTask',
  async (data: ResponseUpdatePositionTaskType, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const prevState = (getState() as AppStateType).internship;
    dispatch(addInPlanTaskAction(data));
    try {
      const response = await internshipApi.updateTaskPosition(data);
      toast.success('Задача добавлена');

      return response.data.payload;
    } catch (error) {
      toast.error('Не удалось добавить задачу');

      const planHolderForCurrentUser = prevState.internsHoldersTasks.find(
        (plan) => plan.id === prevState.currentInternId,
      );
      return thunkAPI.rejectWithValue(planHolderForCurrentUser);
    }
  },
);

export type AddOrDeleteTaskFromColumnType = {
  plantTasks: PlanTaskJobType[];
  chains: number[];
};

const deleteFromPlanTask = createAsyncThunk<
  ResponsePlanType,
  ResponseUpdatePositionTaskType,
  { rejectValue: InternHolderType | undefined }
>(
  '/internship/deleteFromPlanTask',
  async (data: ResponseUpdatePositionTaskType, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const prevState = (getState() as AppStateType).internship;
    dispatch(deleteFromPlanTaskAction(data));
    try {
      const response = await internshipApi.updateTaskPosition(data);
      toast.success('Задача удалена');
      return response.data.payload;
    } catch (error) {
      toast.error('Не удалось удалить задачу');
      const planHolderForCurrentUser = prevState.internsHoldersTasks.find(
        (plan) => plan.id === prevState.currentInternId,
      );
      return thunkAPI.rejectWithValue(planHolderForCurrentUser);
    }
  },
);

const updateTaskInfo = createAsyncThunk(
  '/internship/updateTaskInfo',
  async (data: TaskType, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const currentState = (getState() as AppStateType).internship;
    dispatch(updateTaskInfoAction(data));
    try {
      await internshipApi.updateTaskInfoAsync(data);
      toast.success('Задача обновлена');
      return data;
    } catch (error) {
      const currentTask = currentState.tasks.find(
        (task) => task.taskJobId === data.id,
      );
      toast.error('Не удалось обновить данные');
      return thunkAPI.rejectWithValue(currentTask);
    }
  },
);

const updatePlanTaskInfo = createAsyncThunk(
  '/internship/updatePlanTaskInfo',
  async (data: TaskType, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const prevState = (getState() as AppStateType).internship;
    dispatch(updatePlanTaskInfoAction(data));
    try {
      await internshipApi.updateTaskInfoAsync(data);
      toast.success('Задача стажера обновлена');
      return data;
    } catch (error) {
      const currentPlan = prevState.internsHoldersTasks.find(
        (intern) => intern.id === prevState.currentInternId,
      )?.plantTasks;
      const currentUserPlan = currentPlan?.find(
        (task) => task.planTaskJobId === data.id,
      );
      toast.error('Не удалось обновить данные');
      return thunkAPI.rejectWithValue(currentUserPlan);
    }
  },
);

const updateTaskStatusFromMentor = createAsyncThunk(
  '/internship/updateTaskStatusFromMentor',
  async (data: UpdateStatusStartFinishType, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const prevState = (getState() as AppStateType).internship;
    dispatch(updateTaskStatusAction(data));

    try {
      const response = await internshipApi.updateTaskStatusAsync(data);
      toast.success('Статус обновлен');
      return { date: response.data.payload, data };
    } catch (error) {
      const taskHolder = prevState.internsHoldersTasks.find(
        (holder) => holder.id === prevState.currentInternId,
      );
      const currentTask = taskHolder?.plantTasks.find(
        (plan) => plan.planTaskJobId === data.planTaskJobId,
      );
      toast.error('Не удалось обновить статус');
      return thunkAPI.rejectWithValue(currentTask);
    }
  },
);

const updateTaskStatusFromIntern = createAsyncThunk(
  '/internship/updateTaskStatusFromIntern',
  async (data: UpdateStatusStartFinishType, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const prevState = (getState() as AppStateType).internship;
    dispatch(updateTaskStatusFromInternAction(data));
    try {
      const response = await internshipApi.updateTaskStatusAsync(data);
      toast.success('Статус обновлен');
      return { date: response.data.payload, data };
    } catch (error) {
      const currentTask = prevState.planTaskJobs.find(
        (plan) => plan.planTaskJobId === data.planTaskJobId,
      );
      toast.error('Не удалось обновить статус');
      return thunkAPI.rejectWithValue(currentTask);
    }
  },
);

const changeIndexTasksIntern = createAsyncThunk(
  '/internship/changeIndexTasksIntern',
  async (params: { newChains: number[]; id: number; prevChains: number[] }) => {
    try {
      internshipApi.updateChainAsync({
        userId: params.id,
        idChains: params.newChains,
      });
    } catch (error) {
      toast.error('Не удалось поменять позицию задачи в списке');
      throw error;
    }
  },
);

const deleteTask = createAsyncThunk<
  ResponsePlanType,
  ResponseDeleteTaskType,
  { rejectValue: { deletedTask: TaskType | undefined } }
>(
  '/internship/deleteTask',
  async (data: ResponseDeleteTaskType, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const prevState = (getState() as AppStateType).internship;
    dispatch(deleteTaskAction(data.id));

    try {
      const response = await internshipApi.deleteTask(data);
      toast.success('Задача удалена');
      return response.data.payload;
    } catch (error) {
      const deletedTask = prevState.tasks.find(
        (task) => task.taskJobId === data.id,
      );
      toast.error('Не удалось удалить задачу');
      return thunkAPI.rejectWithValue({
        deletedTask,
      });
    }
  },
);

const deleteTaskPlan = createAsyncThunk<
  ResponsePlanType,
  ResponseDeleteTaskType,
  { rejectValue: InternHolderType | undefined }
>(
  '/internship/deleteTaskPlan',
  async (data: ResponseDeleteTaskType, thunkAPI) => {
    const { getState, dispatch } = thunkAPI;
    const prevState = (getState() as AppStateType).internship;
    dispatch(deleteTaskPlanAction(data));

    try {
      const response = await internshipApi.deleteTask(data);
      toast.success('Задача удалена');
      return response.data.payload;
    } catch (error) {
      const planHolderForCurrentUser = prevState.internsHoldersTasks.find(
        (plan) => plan.id === prevState.currentInternId,
      );
      toast.error('Не удалось удалить задачу');
      return thunkAPI.rejectWithValue(planHolderForCurrentUser);
    }
  },
);

// deleteTask

export default {
  getAllTasks,
  getUserPlan,
  postNewTaskForInterns,
  addInPlanTask,
  updateTaskInfo,
  updateTaskStatusFromMentor,
  deleteFromPlanTask,
  updateTaskStatusFromIntern,
  updatePlanTaskInfo,
  changeIndexTasksIntern,
  deleteTask,
  deleteTaskPlan,
};
