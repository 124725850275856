import axios from 'src/apiV2/axios';

const path = '/extra-hours';

/**
 * @param {{
 * page: number,
 * perPage: number,
 * sortBy: string,
 * sortDirection: string,
 * isProcessed?: boolean,
 * selectedUsers?: string,
 * }} params
 */
export const getList = (params) => {
  return axios.get(path, { params });
};

/**
 * @param {{
 * description: string,
 * start: date,
 * end: date,
 * durationMinutes: number,
 * user_id: number,
 * }} data
 */
export const create = (data) => {
  return axios.post(path, data);
};

/**
 * @param {number} id
 */
/**
 * @param {{
 * description: string,
 * start: date,
 * end: date,
 * durationMinutes: number,
 * user_id: number,
 * isProcessed?: boolean,
 * }} data
 */
const update = (id, data) => {
  return axios.patch(`${path}/${id}`, data);
};

/**
 * @param {number} id
 */
export const deleteOne = (id) => {
  return axios.delete(`${path}/${id}`);
};

export default {
  getList,
  create,
  update,
  deleteOne,
};
