import { useAppSelector } from 'src/store';
import themes from './themes';
import type mainTheme from './themes/main';

export type ThemeType = typeof mainTheme;

const useTheme = () => {
  const selectedTheme = useAppSelector(({ main }) => main.theme);

  return themes[selectedTheme as keyof typeof themes];
};

export default useTheme;
