import React from 'react';

import Xzibit from './Xzibit';
import { useAppSelector } from 'src/store';

const BirthDayFeaturesEnteryPoint: React.FC = () => {
  const isVisible = useAppSelector(({ main }) => main.events.isBirthDay);

  if (!isVisible) {
    return null;
  }

  return (
    <Xzibit />
  );
};

export default BirthDayFeaturesEnteryPoint;
