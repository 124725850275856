import React, { useEffect, useState } from 'react';
import Interns from './component/Interns/Interns';
import TasksInterns from './component/TasksInterns/TasksInterns';
import AllTasks from './component/AllTasks/AllTasks';
import InternshipWrapper from './Internship.styles';
import { updateUsersList } from 'src/store/enums/reducer';
import { useAppSelector, useAppDispatch } from 'src/store';
import { UserRoleENUM, type UserType } from 'src/types';
import internship from './store/thunks';
import type { DragStart, DropResult } from 'react-beautiful-dnd';
import { DragDropContext } from 'react-beautiful-dnd';
import {
  getCurrentInternChains,
  getCurrentInternHolder,
} from './utils/selectors';

const Internship: React.FC = () => {
  const dispatch = useAppDispatch();
  const usersList = useAppSelector(({ enums }) => enums.usersList);
  const chains = useAppSelector(getCurrentInternChains);
  const id = useAppSelector((state) => state.internship.currentInternId);
  const currentInternHolder = useAppSelector(getCurrentInternHolder);
  const [currentIntern, setCurrentIntern] = React.useState<UserType | null>(
    null,
  );
  const [dropDisabled, setDropDisabled] = useState<boolean>(false);

  useEffect(() => {
    dispatch(internship.getAllTasks());
  }, [dispatch]);

  const interns = React.useMemo(() => {
    if (!usersList) {
      return [];
    }
    return (Object.values(usersList) as UserType[]).filter(
      (user) => user.role === UserRoleENUM.student,
    );
  }, [usersList]);

  const mentors = React.useMemo(() => {
    if (!usersList) {
      return [];
    }
    return (Object.values(usersList) as UserType[]).filter(
      (user) => user.role === UserRoleENUM.mentor || user.role === UserRoleENUM.admin,
    );
  }, [usersList]);

  const mentorsOptions = React.useMemo(() => {
    const options: {
      label: string;
      value: string | number;
    }[] = [];
    for (const mentor of mentors) {
      const fullName = `${mentor.lastNameRu || mentor.lastName || ''} ${
        mentor.firstNameRu || mentor.firstName || mentor.email || ''
      }`;
      options.push({
        label: fullName.trim(),
        value: mentor.id,
      });
    }
    return options;
  }, [mentors]);

  const updateMentors = (
    userId: number,
    mentorId: string | number | undefined,
  ) => {
    if (!usersList) {
      return;
    }
    let users = usersList as Record<number, UserType>;
    users = {
      ...users,
      [userId]: {
        ...users[userId],
        mentor_id: mentorId !== 0 ? Number(mentorId) : null,
      },
    };
    setCurrentIntern(users[userId]);
    dispatch(updateUsersList(users));
  };

  const addTask = (draggableId: string, index: number) => {
    if (!id) return;
    dispatch(
      internship.addInPlanTask({
        userId: id,
        actionFlag: 'addTask',
        currentTask: +draggableId,
        index,
      }),
    );
  };

  const deleteTaskFromInternHolder = (draggableId: string, index: number) => {
    if (!id) return;
    dispatch(
      internship.deleteFromPlanTask({
        userId: id,
        actionFlag: 'deleteTask',
        currentTask: +draggableId,
        index,
      }),
    );
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination, draggableId } = result;

    if (!destination || !id) {
      return;
    }
    if (
      source.droppableId === 'internTasks' &&
      destination.droppableId === 'internTasks'
    ) {
      const copiedTasksChains = currentInternHolder?.chains.slice() ?? [];
      const prevChains = [...copiedTasksChains];
      const element = copiedTasksChains.splice(source.index, 1)[0];
      copiedTasksChains.splice(destination.index, 0, element);
      const data = {
        newChains: copiedTasksChains,
        id,
        prevChains,
      };
      return dispatch(internship.changeIndexTasksIntern(data));
    }
    if (
      source.droppableId === 'allTasks' &&
      destination.droppableId === 'allTasks'
    ) {
      return;
    }
    if (source.droppableId === 'allTasks') {
      addTask(draggableId, destination.index);
    } else if (source.droppableId === 'internTasks') {
      deleteTaskFromInternHolder(draggableId, destination.index);
    }
  };

  const onDragStart = (start: DragStart) => {
    if (window.navigator.vibrate) {
      window.navigator.vibrate(100);
    }
    const { droppableId } = start.source;
    setDropDisabled(droppableId === 'allTasks');
  };

  return (
    <InternshipWrapper>
      <Interns
        interns={interns}
        currentIntern={currentIntern}
        setCurrentIntern={setCurrentIntern}
      />
      <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
        <TasksInterns
          mentorsOptions={mentorsOptions}
          currentIntern={currentIntern}
          updateMentors={updateMentors}
          interns={interns}
        />

        <AllTasks dropDisabled={dropDisabled} />
      </DragDropContext>
    </InternshipWrapper>
  );
};

export default Internship;
