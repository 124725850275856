export function dateCounter(limits: number, startDate: Date) {
  const startDateTimestamp = new Date(startDate).getTime();
  const endDateTimestamp = startDateTimestamp + limits * 24 * 60 * 60 * 1000;
  const totalMilliseconds = Math.max(0, endDateTimestamp - Date.now());
  const days = Math.floor(totalMilliseconds / (1000 * 60 * 60 * 24));
  const hoursLeft = Math.floor(
    (totalMilliseconds % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
  );
  const minutesLeft = Math.floor(
    (totalMilliseconds % (1000 * 60 * 60)) / (1000 * 60),
  );
  const totalSeconds = Math.floor(totalMilliseconds / 1000);
  return {
    hoursLeft,
    minutesLeft,
    totalSeconds,
    days,
  };
}
