/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const getInitialStore = () => ({
  membersList: [],
  usersList: [],
  currentItem: null,
  currentTask: null,
});

const teamSlice = createSlice({
  name: 'team',
  initialState: getInitialStore(),
  reducers: {
    updateMembersList: (store, { payload }) => ({
      ...store,
      membersList: payload,
    }),
    updateUsersList: (store, { payload }) => ({
      ...store,
      usersList: payload,
    }),
    setCurrentItem: (store, { payload }) => ({
      ...store,
      currentItem: payload,
    }),
    setCurrentTask: (store, { payload }) => ({
      ...store,
      currentTask: payload,
    }),
    createOneMember: (store, { payload }) => ({
      ...store,
      membersList: [...store.membersList, payload],
    }),
    updateOneMember: (store, { payload }) => {
      store.membersList = store.membersList.map((member) => {
        return (member.id === payload.id) ? payload : member;
      });
    },
    deleteOneMember: (store, { payload }) => {
      store.membersList = store.membersList.filter((member) => {
        return !(member.id === payload);
      });
    },
  },
});

export const {
  updateMembersList,
  updateUsersList,
  setCurrentItem,
  createOneMember,
  updateOneMember,
  deleteOneMember,
  setCurrentTask,
} = teamSlice.actions;

export default teamSlice.reducer;
