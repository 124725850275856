import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import theme from 'src/ui/styles/StyledComponentsTheme/themes/main';
import React from 'react';
import {
  isTaskOverdue,
  isTaskOverdueWithFinishDateAndDays,
} from 'src/pages/Internship/utils/servisesSelectors';

interface IIconTaskProps {
  startTask: Date | null;
  finishTask: Date | null;
  limits: number | null;
}

type ConditionKeysType =
  | 'isNewTask'
  | 'isDeadlineTask'
  | 'isStartedTask'
  | 'isBadDoneTask'
  | 'isDoneTask';

const IconTask: React.FC<IIconTaskProps> = ({
  startTask,
  finishTask,
  limits,
}) => {
  const conditions: Record<ConditionKeysType, boolean> = React.useMemo(
    () => ({
      isNewTask: startTask === null,
      isDeadlineTask: Boolean(
        startTask &&
          limits &&
          isTaskOverdue(limits, new Date(startTask)) &&
          finishTask === null,
      ),
      isBadDoneTask: Boolean(
        startTask &&
          finishTask &&
          limits &&
          isTaskOverdueWithFinishDateAndDays(
            new Date(startTask),
            new Date(finishTask),
            limits,
          ) &&
          finishTask,
      ),
      isStartedTask: finishTask === null,
      isDoneTask: finishTask !== null,
    }),
    [startTask, finishTask, limits],
  );

  const iconMap: Record<ConditionKeysType, JSX.Element> = {
    isNewTask: (
      <RadioButtonUncheckedIcon sx={{ color: theme.colors.iconsIntern.new }} />
    ),
    isDeadlineTask: (
      <HighlightOffOutlinedIcon
        sx={{ color: theme.colors.iconsIntern.deadline }}
      />
    ),
    isStartedTask: (
      <AccessTimeIcon sx={{ color: theme.colors.iconsIntern.started }} />
    ),
    isBadDoneTask: (
      <CheckCircleOutlineIcon
        sx={{ color: theme.colors.iconsIntern.badDone }}
      />
    ),
    isDoneTask: (
      <CheckCircleOutlineIcon sx={{ color: theme.colors.iconsIntern.done }} />
    ),
  };

  const conditionKey = Object.keys(conditions).find(
    (key) => conditions[key as ConditionKeysType],
  );
  return iconMap[conditionKey as ConditionKeysType] || null;
};

export default IconTask;
