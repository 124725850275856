import React, { memo, lazy } from 'react';
// import { useSelector } from 'react-redux';

import { Routes, Route } from 'react-router-dom';

import { routePaths, userRoles } from 'src/utils/constants';
import lazyWrapper from 'src/utils/HOC/lazyWrapper';
import protector from 'src/utils/HOC/protector';
import titleSetter from 'src/utils/HOC/titleSetter';
import { useAppSelector } from 'src/store';
// const Internship = lazyWrapper(lazy(() => import('src/pages/Internship')));
import Internship from 'src/pages/Internship/Internship';
import Syllabus from 'src/pages/Syllabus/Syllabus';

const Page404 = lazyWrapper(lazy(() => import('src/pages/Page404')));
const Auth = lazyWrapper(lazy(() => import('src/pages/Auth')));
const Home = lazyWrapper(lazy(() => import('src/pages/Home')));
const Clients = lazyWrapper(lazy(() => import('src/pages/Clients')));
const Customers = lazyWrapper(lazy(() => import('src/pages/CustomerTab')));
const Companies = lazyWrapper(lazy(() => import('src/pages/CompanyTab')));
const Customer = lazyWrapper(lazy(() => import('src/pages/Customer')));
const Company = lazyWrapper(lazy(() => import('src/pages/Company')));
// const HrBoards = lazyWrapper(lazy(() => import('src/pages/HrBoards')));
const ErpDiagram = lazyWrapper(lazy(() => import('src/pages/ErpDiagram')));
// const Articles = lazyWrapper(lazy(() => import('src/pages/Articles')));
const Account = lazyWrapper(lazy(() => import('src/pages/Account')));
const Staff = lazyWrapper(lazy(() => import('src/pages/Staff')));
const Requests = lazyWrapper(lazy(() => import('src/pages/Requests')));
const ListOfRequests = lazyWrapper(
  lazy(() => import('src/pages/ListOfRequests')),
);
const PortfolioBuilder = lazyWrapper(
  lazy(() => import('src/pages/documents/PortfolioBuilder')),
);
const CVBuilder = lazyWrapper(
  lazy(() => import('src/pages/documents/CVBuilder')),
);
const Inventory = lazyWrapper(lazy(() => import('src/pages/Inventory')));
const Projects = lazyWrapper(lazy(() => import('src/pages/Projects')));
// const CreateProject = lazyWrapper(lazy(() => import('src/pages/Projects/CreateProject')));
// const Calendar = lazyWrapper(lazy(() => import('src/pages/Calendar/CalendarPage')));
const Calendar = lazyWrapper(lazy(() => import('src/pages/EventsCalendar')));
const ExtraHours = lazyWrapper(lazy(() => import('src/pages/ExtraHours')));
const Team = lazyWrapper(lazy(() => import('src/pages/Team')));
const Reviewers = lazyWrapper(lazy(() => import('src/pages/Reviewers')));
const CrmAnalytics = lazyWrapper(
  lazy(() => import('src/pages/crm/CrmAnalytics')),
);
const BirthDayPage = lazyWrapper(
  lazy(() => import('src/ui/events/birthDay/BirthDayPage')),
);

const Router = () => {
  const user = useAppSelector(({ main }) => main.user);

  if (!user) {
    return <Auth />;
  }

  return (
    <Routes>
      {protectedRoutes.map((route, index) => (
        <Route
          key={index}
          // {...route}
          path={route.path}
          element={<route.component />}
        />
      ))}
      <Route element={<Clients />}>
        <Route path={routePaths.customers.list} element={<Customers />} />
        <Route path={routePaths.companies.list} element={<Companies />} />
      </Route>
    </Routes>
  );
};

const routes = [
  {
    path: routePaths.home,
    component: Home,
    pageTitle: 'Добро пожаловать!',
  },
  // {
  //   path: routePaths.hrBoards,
  //   component: HrBoards,
  //   pageTitle: 'Custom Trello',
  //   roles: ['hr', 'admin'],
  // },
  {
    path: routePaths.diagram,
    component: ErpDiagram,
    pageTitle: 'ERP Диаграмма',
    roles: ['sales', 'admin'],
  },
  // {
  //   path: routePaths.articles,
  //   component: Articles,
  //   pageTitle: 'Статьи',
  // },
  {
    path: routePaths.sales.createPortfolio,
    component: PortfolioBuilder,
    pageTitle: 'Создание портфолио',
    roles: ['sales', 'admin'],
  },
  {
    path: routePaths.sales.createCv,
    component: CVBuilder,
    pageTitle: 'Создание резюме',
    roles: ['sales', 'admin'],
  },
  {
    path: routePaths.inventory,
    component: Inventory,
    pageTitle: 'Инвентаризация',
    roles: ['admin', 'officeManager'],
  },
  {
    path: routePaths.common.user.one,
    component: Account,
    pageTitle: 'Аккаунт',
  },
  {
    path: routePaths.staff,
    component: Staff,
    pageTitle: 'Сотрудники',
    roles: ['hr', 'admin', 'officeManager'],
  },
  {
    path: routePaths.common.createRequest,
    component: Requests,
    pageTitle: 'Создайте запрос',
  },
  {
    path: routePaths.sales.projects,
    component: Projects,
    pageTitle: 'Проекты',
    roles: ['sales', 'admin'],
  },
  {
    path: routePaths.admin.listOfRequests,
    component: ListOfRequests,
    pageTitle: 'Заявки',
    roles: ['admin', 'hr', 'sales', 'officeManager'],
  },
  {
    path: routePaths.calendar,
    component: Calendar,
    pageTitle: 'Календарь событий',
    roles: Object.values(userRoles).filter((role) => role !== 'student'),
  },
  {
    path: routePaths.extra.extraList,
    component: ExtraHours,
    pageTitle: 'Переработки',
    roles: ['admin'],
  },
  {
    path: routePaths.reviewers.management,
    component: Reviewers,
    pageTitle: 'Ревьюеры',
    roles: ['admin'],
  },
  {
    path: routePaths.crmTeam,
    component: Team,
    pageTitle: 'Команда разработки',
    roles: ['sales', 'admin'],
  },
  {
    path: routePaths.events.birthDayQuiz,
    component: BirthDayPage,
    pageTitle: 'Викторина',
  },
  {
    path: routePaths.crmAnalytics,
    component: CrmAnalytics,
    pageTitle: 'CRM аналитика',
    roles: ['sales', 'admin'],
  },
  {
    path: routePaths.internship,
    component: Internship,
    pageTitle: 'Стажировка',
    roles: ['mentor', 'admin'],
  },
  {
    path: routePaths.syllabus,
    component: Syllabus,
    pageTitle: 'Стажировка',
    roles: ['student'],
  },
  {
    path: routePaths.customers.one,
    component: Customer,
    pageTitle: 'Клиент',
  },
  // {
  //   path: routePaths.customers.list,
  //   component: Customers,
  //   pageTitle: 'Клиенты',
  //   roles: ['sales', 'admin'],
  // },
  // {
  //   path: routePaths.customers.one,
  //   component: Customer,
  //   pageTitle: 'Клиент',
  // },
  {
    path: routePaths.companies.one,
    component: Company,
    pageTitle: 'Клиент',
  },
  {
    path: '*',
    component: Page404,
    pageTitle: 'Страница не найдена',
  },
];

const protectedRoutes = routes.map((route) => {
  let component = route.component as React.FC;
  if (route.roles) {
    component = protector(component, route.roles);
  }
  if (route.pageTitle) {
    component = titleSetter(component, route.pageTitle);
  }
  return { ...route, component };
});

export default memo(Router);
