import store, { resetStore } from 'src/store';
import type { UserType } from 'src/types';
import token from './token';
import moment from 'moment';
import { TaskSelector } from './constants';

const safeJsonParse = <R = unknown>(string: string, fallbackValue?: R | null): R | null => {
  try {
    const parsed = JSON.parse(string);

    return parsed as R;
  } catch {
    return fallbackValue || null;
  }
};

const sleep = (timeout: number) => new Promise((res) => { setTimeout(res, timeout); });

const logOut = () => {
  store.dispatch(resetStore());
  token.access.remove();
  token.refresh.remove();
};

const getUserName = (user: UserType, language: 'ru' | 'en' = 'ru') => {
  if (language === 'ru') {
    return user.fullNameRu || user.fullName || user.email;
  }
  return user.fullName || user.fullNameRu || user.email;
};

const getUrlFromBlob = (blob: Blob) => {
  return window.URL.createObjectURL(
    new Blob([blob]),
  );
};

const downloadFile = (data: {
  fileUrl: string;
  fileName: string;
}) => {
  const link = document.createElement('a');
  link.href = data.fileUrl;
  link.download = data.fileName;
  link.addEventListener('click', () => {
    setTimeout(() => window.URL.revokeObjectURL(link.href), 30 * 1000);
  });
  link.click();
};

const getDateOnly = (date: string | null): string => {
  if (!date) {
    return '';
  }
  const result = moment(date).format('YYYY-MM-DD');
  return result;
};

const getTimeZoneString = (timeZone: string | null): string => {
  if (!timeZone) {
    return '-';
  }
  const timeZoneNumber = Number.parseFloat(timeZone);
  if (timeZoneNumber === 0) {
    return '0';
  }
  const sign = timeZoneNumber > 0 ? '+' : '-';
  return `${sign} ${Math.abs(timeZoneNumber)}`;
};

type GetTaskStageType = {
  archive: boolean;
  proposal: boolean;
  contract: boolean;
};
const getTaskStage = (data: GetTaskStageType) => {
  const { archive, proposal, contract } = data;
  const taskData = Object.values(TaskSelector).find(
    (el) => el.archive === archive && el.proposal === proposal && el.contract === contract,
  );
  if (!taskData) return 'proposal';
  return taskData.taskStage;
};

export default {
  sleep,
  logOut,
  getUserName,
  downloadFile,
  safeJsonParse,
  getUrlFromBlob,
  getDateOnly,
  getTimeZoneString,
  getTaskStage,
};
