import React, { memo } from 'react';
import PropTypes from 'prop-types';

import { ThemeProvider } from 'styled-components';

import useTheme from './useTheme';

const StyledComponentsTheme = ({ children }) => {
  const theme = useTheme();

  return (
    <ThemeProvider theme={theme}>
      {children}
    </ThemeProvider>
  );
};

StyledComponentsTheme.propTypes = {
  children: PropTypes.node,
};

StyledComponentsTheme.defaultProps = {
  children: null,
};

export default memo(StyledComponentsTheme);
