import React from 'react';
import ReactDOM from 'react-dom/client';

import App from 'src/App';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import GlobalStyles from 'src/ui/styles/GlobalStyles';
import StyledComponentsTheme from 'src/ui/styles/StyledComponentsTheme';
import MaterialTheme from 'src/ui/styles/MaterialTheme';

import store from 'src/store';

import reportWebVitals from './reportWebVitals';
import 'react-toastify/dist/ReactToastify.min.css';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import 'react-calendar-timeline/lib/Timeline.css';
import 'react-quill/dist/quill.snow.css';
import 'react-datepicker/dist/react-datepicker.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <StoreProvider store={store}>
    <BrowserRouter>
        <StyledComponentsTheme>
          <MaterialTheme>
            <>
              <GlobalStyles />

              <App />
            </>
          </MaterialTheme>
        </StyledComponentsTheme>
    </BrowserRouter>
  </StoreProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
