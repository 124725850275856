import { createSlice } from '@reduxjs/toolkit';
import moment from 'moment';

import storage from 'src/utils/storage';

export const ThemeType = { main: 'main', dark: 'dark' };

export const getInitialStore = () => ({
  user: null,
  showLoader: false,
  theme: isDarkTheme() ? ThemeType.dark : ThemeType.main,
  isSidebarOpen: false,
  pageTitle: '',
  currentDate: Date.now(),
  events: {
    isNewYear: false,
    isBirthDay: false,
  },
});

const isDarkTheme = () => {
  const { theme } = storage;
  if (Object.values(ThemeType).includes(theme)) {
    return theme === ThemeType.dark;
  }

  if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    return true;
  }

  return false;
};

const mainSlice = createSlice({
  name: 'main',
  initialState: getInitialStore(),
  reducers: {
    updateUser: (store, { payload }) => ({
      ...store,
      user: payload,
    }),
    toggleLoader: (store, { payload }) => ({
      ...store,
      showLoader: typeof payload === 'boolean' ? payload : !store.showLoader,
    }),
    updateTheme: (store, { payload }) => {
      storage.theme = payload;

      return {
        ...store,
        theme: payload,
      };
    },
    toggleSidebar: (store, { payload }) => ({
      ...store,
      isSidebarOpen: typeof payload === 'boolean' ? payload : !store.isSidebarOpen,
    }),
    updatePageTitle: (store, { payload }) => ({
      ...store,
      pageTitle: payload,
    }),
    setCurrentDate: (store, { payload }) => {
      if (!payload) {
        return;
      }
      store.currentDate = payload;
      const now = moment(payload);
      const currentMonth = now.get('month') + 1;

      let isNewYear = false;
      let isBirthDay = false;

      // eslint-disable-next-line default-case
      switch (currentMonth) {
        case 1: {
          const yearStart = now.clone().startOf('year');
          isNewYear = +now > +yearStart && +now < +yearStart.add(2, 'weeks');
          break;
        }

        case 12: {
          const yearEnd = now.clone().endOf('year');
          isNewYear = +now < +yearEnd && +now > +yearEnd.subtract(2, 'weeks');
          break;
        }

        case 4: {
          const monthStart = now.clone().startOf('month');
          isBirthDay = +now < monthStart.add(1, 'week');
          break;
        }
      }

      store.events.isBirthDay = isBirthDay;
      store.events.isNewYear = isNewYear;
    },
  },
});

export const {
  updateUser,
  toggleLoader,
  updateTheme,
  toggleSidebar,
  updatePageTitle,
  setCurrentDate,
} = mainSlice.actions;

export default mainSlice.reducer;
