export type PrevStateType = {
    tasks: TaskType[];
    userIdChain: number[];
    planTaskJobs: PlanTaskJobType[];
    internsHoldersTasks: InternHolderType[];
    currentInternId: number | null;
  };
export interface IRejectedValue {
    state: IStore;
  }

export type TaskType = {
    id?: number;
    taskJobId?: number;
    title: string;
    description: string;
    timeLimits: number | null;
    level: TaskJobsLevelENUM | undefined;
    isParent?: boolean;
    parent?: number | null;
    flagAction?: 'plan' | 'task';
    icon?: 'new' | 'started' | 'done' | 'deadline' | 'badDone';
    planTask?: number;
    startTask?: null | Date;
    finishTask?: null | Date;
  };

export type ChangeDateType = {
    date: string;
    id: number;
  };

export type PlanTaskJobType = {
    planTaskJobId?: number;
    startTask: Date | null;
    finishTask: Date | null;
    status: string | null;
    taskJob: TaskType;
  };

export type CurrentInternType = {
    id: number | null;
  };

export type InternHolderType = {
    id: number;
    plantTasks: PlanTaskJobType[];
    chains: number[];
  };

export type PayloadIndexCurrentColumnType = {
    tasks: TaskType[];
    flag: string;
  };
export interface IStore {
    tasks: TaskType[];
    userIdChain: number[];
    planTaskJobs: PlanTaskJobType[];
    currentInternId: null | number;
    internsHoldersTasks: InternHolderType[];
  }

export enum TaskJobsLevelENUM {
    base = 'base',
    medium = 'medium',
    final = 'final',
    probation = 'probation',
  }
