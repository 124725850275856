import StyledTask from './Task.styles';
import { useEffect, useRef, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from '@mui/material';
import { useAppDispatch } from 'src/store/store';
import thunks from 'src/pages/Internship/store/thunks';
import Button from '@mui/material/Button';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { dateCounter } from '../../utils/dateCounter';
import IconTask from './IconTask';
import TaskProgressInfo from './TaskProgressInfo';
import type {
  PlanTaskJobType,
  TaskType,
} from 'src/pages/Internship/utils/types';

type PropsType = {
  task: TaskType;
  planTask: PlanTaskJobType;
};

const Task: React.FC<PropsType> = (props) => {
  const dispatch = useAppDispatch();
  const [timeLeft, setTimeLeft] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    totalSeconds: 0,
  });

  const myRef = useRef<HTMLDivElement>(null);

  const setStatusTask = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
    flag: 'finish' | 'start',
  ) => {
    e.stopPropagation();
    if (!props.planTask.planTaskJobId) return;
    dispatch(
      thunks.updateTaskStatusFromIntern({
        actionFlag: flag,
        planTaskJobId: props.planTask.planTaskJobId,
      }),
    );
  };

  useEffect(() => {
    if (!props.planTask?.startTask || !props.task.timeLimits) return;

    const { days, hoursLeft, minutesLeft, totalSeconds } = dateCounter(
      props.task.timeLimits,
      props.planTask.startTask,
    );
    setTimeLeft({
      days,
      hours: hoursLeft,
      minutes: minutesLeft,
      totalSeconds,
    });
  }, [props.planTask.startTask, props.task.timeLimits]);

  if (!props.planTask.taskJob) {
    return null;
  }
  return (
    <StyledTask>
      <Accordion>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <IconTask
            startTask={props.planTask.startTask}
            finishTask={props.planTask.finishTask}
            limits={props.planTask.taskJob.timeLimits}
          />
          <Typography>{props.planTask.taskJob.title}</Typography>
          <div className="task__ready-finish">
            {!props.planTask.startTask && (
              <Button onClick={(e) => setStatusTask(e, 'start')}>Начать</Button>
            )}
            {props.planTask.startTask && !props.planTask.finishTask && (
              <Button onClick={(e) => setStatusTask(e, 'finish')}>
                Закончить
              </Button>
            )}
          </div>
        </AccordionSummary>
        <AccordionDetails>
          <div
            className="task-description"
            ref={myRef}
            dangerouslySetInnerHTML={{
              __html: props.planTask?.taskJob.description,
            }}
          />
          <TaskProgressInfo
            limits={props.planTask.taskJob.timeLimits}
            timeLeft={timeLeft}
            levelCopy={props.planTask.taskJob.level}
            startTask={props.planTask.startTask}
            finishTask={props.planTask.finishTask}
          />
          <div className="task__ready-finish-accordion">
            {!props.planTask.startTask && (
              <Button onClick={(e) => setStatusTask(e, 'start')}>Начать</Button>
            )}
            {props.planTask.startTask && !props.planTask.finishTask && (
              <Button onClick={(e) => setStatusTask(e, 'finish')}>
                Закончить
              </Button>
            )}
          </div>
        </AccordionDetails>
      </Accordion>
    </StyledTask>
  );
};

export default Task;
