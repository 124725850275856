const defaultGetFunction = (key: string) => {
  return localStorage.getItem(key);
};

const defaultSetFunction = (key: string, value: string) => {
  localStorage.setItem(key, value);
};

const defaultRemoveFunction = (key: string) => {
  localStorage.removeItem(key);
};

const safeJSONParsing = <T>(str: string): T => {
  try {
    const res = JSON.parse(str as unknown as string);
    return res;
  } catch (e) {
    return str as T;
  }
};

class CoreStorageItem<D> {
  private key: string;

  private defaultValue: D | null;

  private shouldUseEncoding: boolean;

  getFunction: typeof defaultGetFunction;

  setFunction: typeof defaultSetFunction;

  removeFunction: typeof defaultRemoveFunction;

  private encoder: (value: string) => string;

  private decoder: (value: string) => string;

  constructor(
    params: {
      key: string;
      keyPrefix: string;
      shouldAddPrefixToKey: boolean;
      shouldUseEncoding: boolean;
      defaultValue: D | null;
      getFunction?: typeof defaultGetFunction;
      setFunction?: typeof defaultSetFunction;
      removeFunction?: typeof defaultRemoveFunction;
      encoder?: (value: string) => string;
      decoder?: (value: string) => string;
    },
  ) {
    const keyPrefix = params.shouldAddPrefixToKey ? params.keyPrefix : '';
    this.key = `${keyPrefix}${params.key}`;
    this.shouldUseEncoding = params.shouldUseEncoding;
    this.defaultValue = params.defaultValue;

    this.getFunction = params.getFunction || defaultGetFunction;
    this.setFunction = params.setFunction || defaultSetFunction;
    this.removeFunction = params.removeFunction || defaultRemoveFunction;

    this.encoder = params.encoder || ((str: string) => window.btoa(str));
    this.decoder = params.decoder || ((str: string) => window.atob(str));

    if (this.shouldUseEncoding) {
      this.key = this.encoder(this.key);
    }
  }

  get = (): D | null => {
    const value = this.getFunction(this.key);

    if (value === null) {
      return this.defaultValue || null;
    }

    const encodedValue = this.shouldUseEncoding ? this.decoder(value) : value;
    const parsedValue = safeJSONParsing<D | null>(encodedValue);
    return parsedValue;
  };

  set = (value: D) => {
    const stringifyedValue = typeof value === 'string' ? value : JSON.stringify(value);
    const encodedValue = this.shouldUseEncoding ? this.encoder(stringifyedValue) : stringifyedValue;
    this.setFunction(this.key, encodedValue);
  };

  remove = () => {
    this.removeFunction(this.key);
  };
}

export default CoreStorageItem;
