import React from 'react';

import createStorm from 'src/ui/events/newYear/Snow/Snow.animation';
import useEvents from 'src/store/main/useEvents';

const Snow = () => {
  const { isNewYear } = useEvents();

  React.useEffect(() => {
    if (!isNewYear) {
      return;
    }

    const storm = createStorm.bind({})();

    storm.start();

    return () => {
      storm.stop();
    };
  }, [isNewYear]);

  return null;
};

export default Snow;
