/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const getInitialStore = () => ({
  projects: [],
  isProjectFormOpen: false,
  isProjectViewOpen: false,
  editingProject: null,
  countOfProjects: 0,
  page: 0,
  sortBy: 'id',
  sortDirection: 'straight',
  search: '',
  developerFilter: null,
  technologyFilter: null,

  technologies: {},
  techGroups: {},
});

const projectSlice = createSlice({
  name: 'projects',
  initialState: getInitialStore(),
  reducers: {
    setProjects(store, action) {
      store.projects = action.payload;
    },
    setProjectFormOpen(store, action) {
      store.isProjectFormOpen = action.payload;
    },
    setProjectViewOpen(store, action) {
      store.isProjectViewOpen = action.payload;
    },
    setEditingProject(store, action) {
      store.editingProject = action.payload;
    },
    setCountOfProjects(store, action) {
      store.countOfProjects = action.payload;
    },
    setProjectPage(store, action) {
      store.page = action.payload;
    },
    setSortBy(store, action) {
      store.sortBy = action.payload;
    },
    setSortDirection(store, action) {
      store.sortDirection = action.payload;
    },
    setSearch(store, action) {
      store.search = action.payload;
      store.page = 0;
    },
    setDeveloperFilter(store, action) {
      store.developerFilter = action.payload;
      store.page = 0;
    },
    setTechnologyFilter(store, action) {
      store.technologyFilter = action.payload;
      store.page = 0;
    },
    clearFilters(store) {
      store.search = '';
      store.developerFilter = null;
      store.technologyFilter = null;
    },
    setTechnologies(store, action) {
      store.technologies = action.payload;
    },
    createTechnology(store, action) {
      const { id, group_id } = action.payload;
      store.technologies[id] = action.payload;
      store.techGroups[group_id].technologies.push(id);
    },
    updateTechnology(store, action) {
      store.technologies[action.payload.id] = action.payload;
    },
    deleteTechnology(store, action) {
      const { id, index, group_id } = action.payload;
      delete store.technologies[id];
      store.techGroups[group_id].technologies.splice(index, 1);
    },
    moveTechnology(store, action) {
      const { techId, addedIndex, removedIndex, prevGroupId, nextGroupId } = action.payload;
      store.techGroups[prevGroupId].technologies.splice(removedIndex, 1);
      store.technologies[techId].group_id = nextGroupId;
      store.techGroups[nextGroupId].technologies.splice(addedIndex, 0, techId);
    },
    setTechGroups(store, action) {
      store.techGroups = action.payload;
    },
    createTechGroup(store, action) {
      store.techGroups[action.payload.id] = {
        ...action.payload,
        technologies: [],
      };
    },
    updateTechGroup(store, action) {
      store.techGroups[action.payload.id] = action.payload;
    },
    deleteTechGroup(store, action) {
      const id = action.payload;
      store.techGroups[id].technologies.forEach((techId) => {
        store.technologies[techId].group_id = null;
        store.techGroups.null.technologies.push(techId);
      });
      delete store.techGroups[id];
    },
  },
});

export const {
  setProjects,
  setProjectFormOpen,
  setProjectViewOpen,
  setEditingProject,
  setCountOfProjects,
  setProjectPage,
  setSortBy,
  setSortDirection,
  setSearch,
  setDeveloperFilter,
  setTechnologyFilter,
  clearFilters,
  setTechnologies,
  createTechnology,
  updateTechnology,
  deleteTechnology,
  moveTechnology,
  setTechGroups,
  createTechGroup,
  updateTechGroup,
  deleteTechGroup,
} = projectSlice.actions;

export default projectSlice.reducer;
