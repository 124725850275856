import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';

export const getCurrentQuery = () => {
  return queryString.parse(window.location.search, {
    arrayFormat: 'separator',
  });
};

const createSearchString = (queryObject) => {
  return queryString.stringify(queryObject, {
    skipNull: true,
    skipEmptyString: true,
    arrayFormat: 'separator',
  });
};

export const useQueryString = (searchObject) => {
  const navigate = useNavigate();

  const [queryData, setQueryData] = useState(getCurrentQuery());
  const [isInitial, setIsInitial] = useState(true);

  const clearQuery = () => {
    navigate({ search: '' });
  };

  useEffect(() => {
    const parsedUrl = getCurrentQuery();
    const newQueryData = { ...parsedUrl, ...searchObject };
    const searchString = createSearchString(newQueryData);

    navigate({ search: searchString });

    setIsInitial(false);
    setQueryData(newQueryData);
  }, [searchObject, navigate]);

  return { queryData, isInitial, clearQuery };
};
