/* eslint-disable @typescript-eslint/no-var-requires */
import React from 'react';
import dateFnsAdd from 'date-fns/add';
import dateFnsSub from 'date-fns/sub';
import { constantsToSelectOptions } from './arrayToSelectOptions';
import { getRandomListItem } from './utils';

const authSubroute = 'auth';
const request = 'request';
const sales = 'sales';
const extra = 'extra';
const overtime = 'overtime';
const userSubroute = 'user';
const reviewers = 'reviewers';
const admin = 'admin';
const customerSubroute = 'customers';
const companySubroute = 'companies';

export const routePaths = {
  home: '/',
  auth: {
    signIn: `/${authSubroute}/sign-in`,
    signUp: `/${authSubroute}/sign-up`,
    forgotPassword: `/${authSubroute}/forgot-password`,
    resetPassword: `/${authSubroute}/reset-password`,
  },
  common: {
    createRequest: `/${request}/create`,
    createOvertime: `/${overtime}/create`,
    user: {
      list: `/${userSubroute}/list`,
      one: `/${userSubroute}/:id`,
      createLink: (id: string | number) => `/${userSubroute}/${id}`,
    },
  },
  admin: {
    listOfRequests: `/${admin}/requests-list`,
  },
  internship: '/internship',
  syllabus: '/syllabus',
  sales: {
    projects: `/${sales}/projects`,
    createProject: `/${sales}/create-project`,
    createPortfolio: `/${sales}/create-portfolio`,
    createCv: `/${sales}/create-cv`,
  },
  reviewers: {
    management: `/${reviewers}`,
  },
  hrBoards: '/hr-boards',
  diagram: '/diagram',
  articles: '/articles',
  staff: '/staff',
  calendar: '/calendar',
  inventory: '/inventory',
  extra: {
    extraList: `/${extra}`,
  },
  crmTeam: '/crm/:taskId/team',
  crmAnalytics: '/crm/analytics',
  events: {
    birthDayQuiz: '/birth-day-quiz',
  },
  customers: {
    list: `/${customerSubroute}`,
    one: `/${customerSubroute}/:id`,
    createLink: (id: string | number) => `/${customerSubroute}/${id}`,
  },
  companies: {
    list: `/${companySubroute}`,
    one: `/${companySubroute}/:id`,
    createLink: (id: string | number) => `/${companySubroute}/${id}`,
  },
};

export const userRoles = {
  admin: 'admin',
  sales: 'sales',
  officeManager: 'officeManager',
  hr: 'hr',
  mentor: 'mentor',
  user: 'user',
  student: 'student',
};

export const userRolesRu = {
  admin: 'Админ',
  sales: 'Sales',
  officeManager: 'Офис Менеджер',
  hr: 'HR',
  mentor: 'Наставник',
  user: 'Юзер',
  student: 'Студент',
};

export const userTechRolesRu = {
  admin: 'Администратор',
  sales: 'Сейлз',
  techLead: 'Техлид',
  projectManager: 'Проджект менеджер',
  developer: 'Разработчик',
  designer: 'Дизайнер',
  qaEngineer: 'Тестировщик',
  hr: 'HR',
  officeManager: 'Офис менеджер',
  englishTeacher: 'Преподаватель английского',
};

export const userStatusesRu = {
  registered: 'Зарегистрированный',
  active: 'Активный',
  disabled: 'Отключённый',
};

export const extraIsProcessedRu = {
  true: 'Оплачено',
  false: 'Не оплачено',
};

export const getKey = <T extends object>(enums: T, value: unknown) => {
  return Object.keys(enums).find((key) => enums[key as keyof T] === value);
};

export const userStatuses = {
  registered: 'registered',
  active: 'active',
  disabled: 'disabled',
};

export const userRolesList = Object.values(userRoles);

export const userStatusesList = Object.values(userStatuses);

export const defaultErrorMessage = 'Что-то пошло не так 😢😢😢';
export const requiredErrorMessage = 'Поле должно быть заполненно';

export const validationErrorName = 'ValidationError';

export const englishOptions = [
  { value: null, label: 'Не указано' },
  { value: 'beginner', label: 'Beginner (A1)' },
  { value: 'elementary', label: 'Elementary (A2)' },
  { value: 'intermediate', label: 'Intermediate (B1)' },
  { value: 'upperIntermediate', label: 'Upper-Intermediate (B2)' },
  { value: 'advanced', label: 'Advanced (C1)' },
  { value: 'proficiency', label: 'Proficiency (C2)' },
];

export const DOB_FORMAT = 'dd MM yyyy';

export const REQUEST_TYPES = {
  dayOff: 'dayOff',
  technical: 'technical',
  documents: 'documents',
  common: 'common',
  vacation: 'vacation',
  medical: 'medical',
  performanceReview: 'performanceReview',
};

export const REQUEST_TYPES_NAMES = {
  dayOff: 'Отгул',
  technical: 'Технический',
  documents: 'Документы',
  common: 'Бытовой',
  vacation: 'Отпуск',
  medical: 'Больничный',
  performanceReview: 'Перформанс ревью',
};

export const REQUEST_TYPES_FOR_ADMIN = {
  dayOff: 'dayOff',
  technical: 'technical',
  documents: 'documents',
  common: 'common',
  vacation: 'vacation',
  medical: 'medical',
  performanceReview: 'performanceReview',
};

export const REQUEST_TYPES_NAMES_FOR_ADMIN = {
  dayOff: 'Отгул',
  technical: 'Технический',
  documents: 'Документы',
  common: 'Бытовой',
  vacation: 'Отпуск',
  medical: 'Больничный',
  performanceReview: 'Перформанс ревью',
};

export const REQUEST_TYPES_FOR_HR_SL = {
  dayOff: 'dayOff',
  vacation: 'vacation',
  medical: 'medical',
  performanceReview: 'performanceReview',
};

export const REQUEST_TYPES_NAMES_FOR_HR_SL = {
  dayOff: 'Отгул',
  vacation: 'Отпуск',
  medical: 'Больничный',
  performanceReview: 'Перформанс ревью',
};

export const REQUEST_TYPES_FOR_OFMG = {
  technical: 'technical',
  documents: 'documents',
  common: 'common',
  performanceReview: 'performanceReview',
};

export const REQUEST_TYPES_NAMES_FOR_OFMG = {
  technical: 'Технический',
  documents: 'Документы',
  common: 'Бытовой',
  performanceReview: 'Перформанс ревью',
};

export const REQUEST_STATUSES = {
  wait: 'wait',
  inProgress: 'inProgress',
  completed: 'completed',
  accept: 'accept',
  denied: 'denied',
};

export const REQUEST_STATUS_NAMES = {
  wait: 'Ожидает рассмотрения',
  inProgress: 'Выполняется',
  completed: 'Выполнена',
  accept: 'Одобрена',
  denied: 'Отклонена',
};

export const REQUEST_COMPENSATION_STATUSES = {
  wait: 'wait',
  completed: 'completed',
  notCompleted: 'notCompleted',
};

export const REQUEST_COMPENSATION_STATUS_NAMES = {
  wait: 'Ожидаем',
  completed: 'Отработано',
  notCompleted: 'Не отработано',
};

export const REQUEST_HISTORY_CHANGES_TITLES_NAMES = {
  editor: 'отредактировал',
  when: 'когда',
  user: 'пользователь',
  title: 'заголовок',
  comment: 'комментарий',
  type: 'тип',
  dateFrom: 'время с',
  dateTo: 'время до',
  date: 'дата',
  status: 'статус',
  deniedComment: 'причина отказа',
  rest_days_number: 'дней до',
  is_unpaid: 'оплачиваемый',
  willCompensateHours: 'с отработкой',
  withMedicalCertificate: 'есть больничный лист',
  compensationStatus: 'статус отработки',
  workOffStartDate: 'начало отработки',
  workOffEndDate: 'конец отработки',
};

export const REQUEST_TYPE_OPTIONS = constantsToSelectOptions(
  REQUEST_TYPES,
  REQUEST_TYPES_NAMES,
);

export const REQUEST_TYPE_OPTIONS_BY_ROLE = {
  admin: constantsToSelectOptions(
    REQUEST_TYPES_FOR_ADMIN,
    REQUEST_TYPES_NAMES_FOR_ADMIN,
  ),
  hr: constantsToSelectOptions(
    REQUEST_TYPES_FOR_HR_SL,
    REQUEST_TYPES_NAMES_FOR_HR_SL,
  ),
  sales: constantsToSelectOptions(
    REQUEST_TYPES_FOR_HR_SL,
    REQUEST_TYPES_NAMES_FOR_HR_SL,
  ),
  officeManager: constantsToSelectOptions(
    REQUEST_TYPES_FOR_OFMG,
    REQUEST_TYPES_NAMES_FOR_OFMG,
  ),
};

export const REQUEST_STATUS_OPTIONS = constantsToSelectOptions(
  REQUEST_STATUSES,
  REQUEST_STATUS_NAMES,
);

export const REQUEST_COMPENSATION_STATUS_OPTIONS = constantsToSelectOptions(
  REQUEST_COMPENSATION_STATUSES,
  REQUEST_COMPENSATION_STATUS_NAMES,
);

export const DEFAULT_AVATAR = {
  avocado: require('../ui/images/defaultAvatars/avocado.svg').default, // eslint-disable-line global-require
  batman: require('../ui/images/defaultAvatars/batman.svg').default, // eslint-disable-line global-require
  bear: require('../ui/images/defaultAvatars/bear.svg').default, // eslint-disable-line global-require
  einstein: require('../ui/images/defaultAvatars/einstein.svg').default, // eslint-disable-line global-require
  heisenberg: require('../ui/images/defaultAvatars/heisenberg.svg').default, // eslint-disable-line global-require
  lazybones: require('../ui/images/defaultAvatars/lazybones.svg').default, // eslint-disable-line global-require
  ozzy: require('../ui/images/defaultAvatars/ozzy.svg').default, // eslint-disable-line global-require
  superman: require('../ui/images/defaultAvatars/superman.svg').default, // eslint-disable-line global-require
  trump: require('../ui/images/defaultAvatars/trump.svg').default, // eslint-disable-line global-require
  wonderWomen: require('../ui/images/defaultAvatars/wonder-women.svg').default, // eslint-disable-line global-require
};

export const getDefaultAvatar = () => getRandomListItem(Object.values(DEFAULT_AVATAR));

export const REQUEST_TYPE_COLORS = {
  [REQUEST_TYPES.common]: '#888888',
  [REQUEST_TYPES.dayOff]: '#FF9800',
  [REQUEST_TYPES.documents]: '#00BCD4',
  [REQUEST_TYPES.medical]: '#E91E63',
  [REQUEST_TYPES.technical]: '#1976D2',
  [REQUEST_TYPES.vacation]: '#388E3C',
  [REQUEST_TYPES.performanceReview]: '#333',
};

const { FontAwesomeIcon } = require('@fortawesome/react-fontawesome'); // eslint-disable-line global-require
const { faCogs, faHeartbeat, faFile, faHome, faUmbrellaBeach, faWalking, faCommentDollar } = require('@fortawesome/free-solid-svg-icons');

const getRequestTypeIcon = (icon: string) => (
  (props: object) => <FontAwesomeIcon {...props} icon={icon} />
);

export const REQUEST_TYPE_ICONS = {
  [REQUEST_TYPES.common]: getRequestTypeIcon(faHome),
  [REQUEST_TYPES.dayOff]: getRequestTypeIcon(faWalking),
  [REQUEST_TYPES.documents]: getRequestTypeIcon(faFile),
  [REQUEST_TYPES.medical]: getRequestTypeIcon(faHeartbeat),
  [REQUEST_TYPES.technical]: getRequestTypeIcon(faCogs),
  [REQUEST_TYPES.vacation]: getRequestTypeIcon(faUmbrellaBeach),
  [REQUEST_TYPES.performanceReview]: getRequestTypeIcon(faCommentDollar),
};

export const REQUEST_STATUS_COLORS = {
  [REQUEST_STATUSES.accept]: '#388E3C',
  [REQUEST_STATUSES.completed]: '#388E3C',
  [REQUEST_STATUSES.denied]: '#FF5252',
  [REQUEST_STATUSES.inProgress]: '#0288D1',
  [REQUEST_STATUSES.wait]: '#9E9E9E',
};

const CheckCircle = require('@mui/icons-material/CheckCircle').default;
const Cancel = require('@mui/icons-material/Cancel').default;
const WatchLater = require('@mui/icons-material/WatchLater').default;
const PauseCircleFilled = require('@mui/icons-material/PauseCircleFilled').default;

const getRequestStatusIcon = <P extends object>(Icon: React.FC<P>) => (
  (props: P) => <Icon {...props} />
);

export const REQUEST_STATUS_ICONS = {
  [REQUEST_STATUSES.accept]: getRequestStatusIcon(CheckCircle),
  [REQUEST_STATUSES.completed]: getRequestStatusIcon(CheckCircle),
  [REQUEST_STATUSES.denied]: getRequestStatusIcon(Cancel),
  [REQUEST_STATUSES.inProgress]: getRequestStatusIcon(WatchLater),
  [REQUEST_STATUSES.wait]: getRequestStatusIcon(PauseCircleFilled),
};

export const REQUEST_COMPENSATION_STATUS_ICONS = {
  [REQUEST_COMPENSATION_STATUSES.completed]: getRequestStatusIcon(CheckCircle),
  [REQUEST_COMPENSATION_STATUSES.notCompleted]: getRequestStatusIcon(Cancel),
  [REQUEST_COMPENSATION_STATUSES.wait]: getRequestStatusIcon(PauseCircleFilled),
};

export const inventoryTableLabels = {
  title: 'Название',
  serialNumber: 'Серийный номер',
  deviceTypes: 'Тип устройства',
  isHome: 'Используется из дома',
  ownerId: 'Владелец',
};

export const SORT_DIRECTIONS = {
  straight: 'straight',
  reverse: 'reverse',
};
export const extraHoursPerPage = 10;
export const crmTaskRoles = ['PM', 'Sales', 'Code Reviewer', 'Tech Lead', 'QA', 'Designer', 'Developer'];
export const levels = ['junior', 'middle', 'senior'];

export const defaultTimeStart = +dateFnsSub(new Date(), { days: 25 });
export const defaultTimeEnd = +dateFnsAdd(new Date(), { days: 60 });

export const customerSuccessRate = {
  SUCCESS: 'успешно',
  WITH_PROBLEMS: 'удовлетворительно',
  FAIL: 'плохо',
};

export const TaskSelector = {
  task: {
    archive: false,
    proposal: false,
    contract: false,
    taskStage: 'lids',
  },
  taskArchive: {
    archive: true,
    proposal: false,
    contract: false,
    taskStage: 'archive',
  },
  proposalTask: {
    archive: false,
    proposal: true,
    contract: false,
    taskStage: 'proposals',
  },
  proposalTaskArchive: {
    archive: true,
    proposal: true,
    contract: false,
    taskStage: 'archiveproposal',
  },
  contractTask: {
    archive: false,
    proposal: false,
    contract: true,
    taskStage: 'contracts',
  },
  contractTaskArchive: {
    archive: true,
    proposal: false,
    contract: true,
    taskStage: 'archivecontract',
  },
};
