import styled from 'styled-components';

export default styled.form`
  padding-top: 10px;
  padding-bottom: 5px;

  .extra-creation-form__form {
    padding-left: 20px;
  }

  .extra-creation-form__header  {
    display: flex;
    justify-content: center;
  }

  .extra-creation-form__date {
    display: flex;
    justify-content: center;
    margin-left: -15px;
  }

  .extra-creation-form__field-label {
    margin-top: 30px;
    margin-bottom: 5px;
  }

  .extra-creation-form__description-label {
    display: flex;
    justify-content: center;
    margin-left: 10px;
  }

  .extra-creation-form__info-label {
    margin-left: 5px;
    width: 19px;
    height: 19px;
  }

  .extra-creation-form__time {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  .extra-creation-form__buttons {
    margin-top: 20px;
  }

  .extra-creation-form__inputs {
    z-index: 10;
  }

  @media (max-width: 768px) { 
    .extra-creation-form__form {
      margin-left: -16px;
      display: flex;
      justify-content: center;
    }

    .extra-creation-form__buttons {
      margin-left: -8px;
      width: 100%;
    }
  }
`;
