import { createAction } from '@reduxjs/toolkit';
import type { TaskType } from '../utils/types';
import type { ResponseDeleteTaskType, ResponseUpdatePositionTaskType, UpdateStatusStartFinishType } from 'src/apiV3/internshipApi';

export const addInternTaskAction = createAction<TaskType>('internship/addInternTaskAction');
export const updateTaskStatusAction = createAction<UpdateStatusStartFinishType>('internship/updateTaskStatusAction');
export const updateTaskStatusFromInternAction = createAction<UpdateStatusStartFinishType>('internship/updateTaskStatusFromInternAction');
export const addInPlanTaskAction = createAction<ResponseUpdatePositionTaskType>('internship/addInPlanTaskAction');
export const deleteFromPlanTaskAction = createAction<ResponseUpdatePositionTaskType>('internship/deleteFromPlanTaskAction');
export const updateTaskInfoAction = createAction<TaskType>('internship/updateTaskInfo');
export const updatePlanTaskInfoAction = createAction<TaskType>('internship/updatePlanTaskInfo');
export const deleteTaskAction = createAction<number>('internship/deleteTaskAction');
export const deleteTaskPlanAction = createAction<ResponseDeleteTaskType>('internship/deleteTaskPlanAction');
