import axios from 'axios';
import token from 'src/utils/token';
import config from 'src/config';
import type { ResponseType } from './http.types';
import getDeviceId, { DEVICE_ID_KEY } from './getDeviceId';

type RefreshTokenResponseType =
  { isRefreshed: true; authToken: string } |
  { isRefreshed: false; authToken: null };

const refreshToken = async (): Promise<RefreshTokenResponseType> => {
  try {
    const refreshToken = token.refresh.get();

    const response = await axios.post<ResponseType<null, { auth: string; refresh: string }>>(
      `${config.apiUrl}/v3/auth/refresh-token`,
      { refreshToken },
      {
        headers: {
          [DEVICE_ID_KEY]: getDeviceId(),
        },
      },
    );

    token.access.set(response.data.meta.auth);
    token.refresh.set(response.data.meta.refresh);

    return { isRefreshed: true, authToken: response.data.meta.auth };
  } catch {
    return { isRefreshed: false, authToken: null };
  }
};

let refreshingPromise: ReturnType<typeof refreshToken> | null = null;

export default async () => {
  if (!refreshingPromise) {
    refreshingPromise = refreshToken();
  }
  const result = await refreshingPromise;
  refreshingPromise = null;
  return result;
};
