import styled from 'styled-components';

const TasksInternsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.navbar.text};

  .mentors-selector {
    padding: 5px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.navbar.text};
    .select--is-disabled {
      .select__single-value {
        color: ${({ theme }) => theme.colors.navbar.text};
      }
    }
  }
`;

export default TasksInternsWrapper;
