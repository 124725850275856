import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { toast } from 'react-toastify';
import {
  object as yupObject,
  string as yupString,
  number as yupNumber,
} from 'yup';

import Modal from 'src/ui/components/Modal';
import ConfirmModal from 'src/ui/components/ConfirmModal';
import { validationErrorName, defaultErrorMessage, requiredErrorMessage } from 'src/utils/constants';
import { toggleLoader } from 'src/store/main/reducer';
import { setExtraItemForEdit } from 'src/pages/ExtraHours/store/reducer';
import {
  createExtraHourItem,
  updateExtraHoursItem,
} from 'src/pages/ExtraHours/store/thunks';
import ExtraHourForm from './ExtraHoursForm';

const validation = yupObject().shape({
  date: yupString().required(requiredErrorMessage),
  hour: yupNumber().min(0, 'Часы не могут быть меньше 0').max(23, 'Часы не могут быть больше 23')
    .required(requiredErrorMessage),
  minute: yupNumber().min(0, 'Минуты не могут быть меньше 0').max(59, 'Минуты не могут быть больше 59'),
  author: yupObject().shape({
    label: yupString(),
    value: yupNumber(),
  }).nullable().required(requiredErrorMessage),
  description: yupString()
    .min(6, 'Описание не должно быть короче шести символов')
    .max(500, 'Описание не должно быть больше пятисот символов')
    .required(requiredErrorMessage),
});

const ExtraHourModal = (props) => {
  const dispatch = useDispatch();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const extraItemForEdit = useSelector(({ extra }) => extra.extraItemForEdit);

  const handleConfirmModalAccept = () => {
    props.setIsOpen(false);
    setIsConfirmModalOpen(false);
    dispatch(setExtraItemForEdit(null));
  };

  const toggleModalVisibility = (fandler) => {
    if (!isConfirmModalOpen) {
      setIsConfirmModalOpen(fandler);
    }
  };

  const closeConfirmModal = () => {
    setIsConfirmModalOpen(false);
  };

  const handleOnSubmit = async (values, formik) => {
    try {
      dispatch(toggleLoader(true));
      const id = extraItemForEdit?.id;
      const newData = {
        description: values.description,
        start: moment(values.startDate).startOf('day').toDate(),
        end: moment(values.endDate).startOf('day').toDate(),
        durationMinutes: (+values.hour * 60) + (+values.minute || 0),
        user_id: values.author.value,
      };
      if (extraItemForEdit) {
        newData.isProcessed = extraItemForEdit.isProcessed;
        dispatch(updateExtraHoursItem({ id, newData }));
        dispatch(setExtraItemForEdit(null));
      } else {
        dispatch(createExtraHourItem(newData));
      }
    } catch (err) {
      const isValidationError = err?.data?.name === validationErrorName;
      if (isValidationError) {
        return err.data.errors.forEach(({ path, message }) => {
          formik.setErrors({ [path]: message });
        });
      }
      toast.error(defaultErrorMessage);
    } finally {
      formik.setSubmitting(false);
      props.setIsOpen(false);
      formik.resetForm();
      dispatch(toggleLoader(false));
    }
  };

  return (
    <>
      <ConfirmModal
        open={isConfirmModalOpen}
        title="Выход"
        content="Вы действительно хотите выйти?"
        onClose={closeConfirmModal}
        onAccept={handleConfirmModalAccept}
      />

      <Modal
        open={props.isOpen}
        onClose={() => toggleModalVisibility(true)}
        maxWidth="md"
      >
        <ExtraHourForm
          extraItemForEdit={extraItemForEdit}
          toggleModalVisibility={toggleModalVisibility}
          handleOnSubmit={handleOnSubmit}
          validation={validation}
          isPersonalExtra={props.isPersonalExtra}
        />
      </Modal>
    </>
  );
};

ExtraHourModal.propTypes = {
  isOpen: PropTypes.bool,
  isPersonalExtra: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

ExtraHourModal.defaultProps = {
  isOpen: false,
  isPersonalExtra: false,
  setIsOpen: () => null,
};

export default ExtraHourModal;
