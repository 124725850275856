import cookie from 'js-cookie';

import config from '../config';

export class CookieItem {
  name: string;

  constructor(name: string) {
    this.name = name;
  }

  get() {
    return cookie.get(this.name);
  }

  set(token: string) {
    return cookie.set(this.name, token, { domain: config.tokenCookieDomain, path: '/' });
  }

  remove() {
    cookie.remove(this.name);
  }
}

export default {
  access: new CookieItem('token'),
  refresh: new CookieItem('refresh'),
};
