import { createSelector } from '@reduxjs/toolkit';
import type { AppStateType } from 'src/store/store';
import {
  comparePlanTaskJobs,
  findCurrentInternPlanTasks,
  mapTasks,
  sortTasksPlan,
} from './servisesSelectors';

export const selectInternId = (state: AppStateType) => state.internship.currentInternId;
export const internHolders = (state: AppStateType) => state.internship.internsHoldersTasks;
export const allTask = (state: AppStateType) => state.internship.tasks;

export const internTasks = (state: AppStateType) => state.internship.planTaskJobs;
export const internChains = (state: AppStateType) => state.internship.userIdChain;

export const onlyParentTasks = createSelector([allTask], (tasks) => {
  const allParentTasks = tasks.filter((task) => task.isParent);
  return allParentTasks;
});

export const getCurrentInternHolder = createSelector(
  [selectInternId, internHolders],
  (id, internsHoldersTasks) => {
    if (!id) return;
    const currentInternPlanTasks = internsHoldersTasks.find(
      (currentHolder) => currentHolder.id === id,
    );
    return currentInternPlanTasks;
  },
);

export const getCurrentInternPlanTasks = createSelector(
  [selectInternId, internHolders],
  (id, internsHoldersTasks) => {
    if (!id) return;
    const currentInternPlanTasks = internsHoldersTasks.find(
      (currentHolder) => currentHolder.id === id,
    );
    const tasks = currentInternPlanTasks?.plantTasks;
    return tasks;
  },
);

export const getCurrentInternChains = createSelector(
  [selectInternId, internHolders],
  (id, internsHoldersTasks) => {
    if (!id) return;
    const currentInternPlanTasks = internsHoldersTasks.find(
      (currentHolder) => currentHolder.id === id,
    );
    const chains = currentInternPlanTasks?.chains;
    return chains;
  },
);

export const getInternFilteredPlan = createSelector(
  [internTasks, internChains],
  (planTaskJobs, userIdChain) => {
    if (userIdChain.length === 0) return;

    const tasks = planTaskJobs
      .slice()
      .sort((a, b) => comparePlanTaskJobs(a, b, userIdChain));

    return tasks;
  },
);

export const getCurrentInternTasks = createSelector(
  [selectInternId, internHolders],
  (id, internsHoldersTasks) => {
    if (!id) return;
    const currentInternPlanTasks = findCurrentInternPlanTasks(
      id,
      internsHoldersTasks,
    );
    const tasksPlan = sortTasksPlan(currentInternPlanTasks);
    const tasks = mapTasks(tasksPlan);
    return tasks;
  },
);
