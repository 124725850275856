import styled from 'styled-components';

const globeWidth = '12px';
const globeHeight = '28px';
const globeSpacing = '40px';
const globeSpread = '3px';
const lightOffOpacity = '0.4';

export default styled.ul`
  text-align: center;
  white-space: nowrap;
  overflow: visible;
  position: fixed;
  z-index: 1;
  margin: -15px 0 0 0;
  padding: 0;
  pointer-events: none;
  width: 100%;
  top: 58px;

  li {
    position: relative;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    list-style: none;
    margin: 0;
    padding: 0;
    display: block;
    width: ${globeWidth};
    height: ${globeHeight};
    border-radius: 50%;
    margin: calc(${globeSpacing}/2);
    display: inline-block;
    background: rgba(0, 247, 165, 1);
    box-shadow: 0px calc(${globeHeight}/6) calc(${globeWidth}*2) ${globeSpread} rgba(0, 247, 165, 1);
    animation-name: flash-1;
    animation-duration: 2s;

    &:nth-child(2n+1) {
      background: rgba(0, 255, 255, 1);
      box-shadow: 0px calc(${globeHeight}/6) calc(${globeWidth}*2) ${globeSpread} rgba(0, 255, 255, 0.5);
      animation-name: flash-2;
      animation-duration: 0.4s;
    }

    &:nth-child(4n+2) {
      background: rgba(247, 0, 148, 1);
      box-shadow: 0px calc(${globeHeight}/6) calc(${globeWidth}*2) ${globeSpread} rgba(247, 0, 148, 1);
      animation-name: flash-3;
      animation-duration: 1.1s;
    }

    &:nth-child(odd) {
      animation-duration: 1.8s;
    }

    &:nth-child(3n+1) {
      animation-duration: 1.4s;
    }

    &:before {
      content: "";
      position: absolute;
      background: #222;
      width: calc(${globeWidth} - 2px);
      height: calc(${globeHeight} / 3);
      border-radius: 3px;
      top: calc(0px - (${globeHeight} / 6));
      left: 1px;
    }

    &:after {
      content: "";
      top: calc(0px - ${globeHeight} / 2);
      left: calc(${globeWidth} - 6px);
      position: absolute;
      width: calc(${globeSpacing} + 12px);
      height: calc(${globeHeight}/3 * 2);
      border-bottom: solid #222 2px;
      border-radius: 50%;
    }

    &:last-child:after {
      content: none;
    }

    &:first-child {
      margin-left: calc(${globeSpacing} * -1);
    }
  }

  @keyframes flash-1 {
    0%, 100% {
      background: rgba(0, 247, 165, 1);
      box-shadow: 0px calc(${globeHeight}/6) calc(${globeWidth}*2) ${globeSpread} rgba(0, 247, 165, 1);
    }

    50% {
      background: rgba(0, 247, 165, ${lightOffOpacity});
      box-shadow: 0px calc(${globeHeight}/6) calc(${globeWidth}*2) ${globeSpread} rgba(0, 247, 165, 0.2);
    }
  }

  @keyframes flash-2 {
    0%, 100% {
      background: rgba(0, 255, 255, 1);
      box-shadow: 0px calc(${globeHeight}/6) calc(${globeWidth}*2) ${globeSpread} rgba(0, 255, 255, 1);
    }

    50% {
      background: rgba(0, 255, 255, ${lightOffOpacity});
      box-shadow: 0px calc(${globeHeight}/6) calc(${globeWidth}*2) ${globeSpread} rgba(0, 255, 255, 0.2);
    }
  }

  @keyframes flash-3 {
    0%, 100% {
      background: rgba(247, 0, 148, 1);
      box-shadow: 0px calc(${globeHeight}/6) calc(${globeWidth}*2) ${globeSpread} rgba(247, 0, 148, 1);
    }

    50% {
      background: rgba(247, 0, 148, ${lightOffOpacity});
      box-shadow: 0px calc(${globeHeight}/6) calc(${globeWidth}*2) ${globeSpread} rgba(247, 0, 148, 0.2);
    }
  }
`;
