import React from 'react';
import classnames from 'classnames';
import { useNavigate } from 'react-router-dom';
import Avatar from 'src/ui/components/Avatar';
import { InternWrapper } from './Interns.styles';
import type { UserType } from 'src/types';
import { routePaths } from 'src/utils/constants';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import {
  clearPlanHolder,
  setCurrentInternGlobalState,
} from '../../store/reducer';

type PropsType = {
  currentUserId: number | undefined;
  intern: UserType;
  currentIntern: UserType | null;
  setCurrentIntern: React.Dispatch<React.SetStateAction<UserType | null>>;
};

const Intern: React.FC<PropsType> = (props) => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const id = useAppSelector((store) => store.internship.currentInternId);
  const clickHandler = () => {
    if (id === props.intern.id) {
      dispatch(clearPlanHolder());
    } else {
      dispatch(setCurrentInternGlobalState(props.intern.id));
    }
  };

  const onUserClick = React.useCallback(() => {
    navigate(`${routePaths.common.user.createLink(props.intern?.id)}`);
  }, [navigate, props.intern]);

  const isScaled = !!props.intern.avatar;

  const fullName = `${props.intern.lastNameRu || props.intern.lastName || ''} ${
    props.intern.firstNameRu || props.intern.firstName || ''
  }`;
  return (
    <InternWrapper
      onClick={clickHandler}
      onDoubleClick={onUserClick}
      className={classnames({
        'current-item': props.intern.id === id,
        'current-mentor': props.intern.mentor_id === props.currentUserId,
      })}
    >
      <div className="avatar-wrapper">
        <Avatar
          withTolltip={false}
          user={props.intern}
          className="avatar"
          size="md"
          scaleOnHover={isScaled}
          scaleValue={2}
        />
      </div>
      <p className="intern__name">
        {fullName.trim().length === 0
          ? props.intern.email
          : `${fullName.trim()} (${props.intern.email})`}
      </p>
    </InternWrapper>
  );
};

export default Intern;
