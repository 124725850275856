import { TaskJobsLevelENUM } from '../utils/types';

type TaskLevelType = { [key: string]: string };

export const taskLevels: TaskLevelType = {
  [TaskJobsLevelENUM.base]: 'Базовый',
  [TaskJobsLevelENUM.medium]: 'Средний',
  [TaskJobsLevelENUM.final]: 'Финальный',
  [TaskJobsLevelENUM.probation]: 'Испытательный',
};
