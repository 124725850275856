import React from 'react';

import Portal from '@mui/material/Portal';
import StyledChristmasLights from 'src/ui/events/newYear/ChristmasLights/ChristmasLights.styles';

import useEvents from 'src/store/main/useEvents';

const ChristmasLights = () => {
  const { isNewYear } = useEvents();

  if (!isNewYear) {
    return null;
  }

  return (
    <Portal>
      <StyledChristmasLights>
        {new Array(42).fill(<li />)}
      </StyledChristmasLights>
    </Portal>
  );
};

export default ChristmasLights;
