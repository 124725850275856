import React, { memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import MenuIcon from '@mui/icons-material/Menu';
import StyledHeader from 'src/ui/containers/Header/components/StyledHeader';
import CreationDropdown from 'src/ui/containers/Header/components/CreationDropdown';
import Bell from 'src/ui/containers/Header/components/Bell';
import UserMenu from 'src/ui/containers/Header/components/UserMenu';
import ChristmasLights from 'src/ui/events/newYear/ChristmasLights';
import NYCursor from 'src/ui/events/newYear/NYCursor/NYCursor';

import { isMdWidth } from 'src/utils';
import { toggleSidebar } from 'src/store/main';
import useWidth from 'src/utils/hooks/useWidth';

// Remove when will be ready
const isBellWorks = false;

const Header = () => {
  const width = useWidth();
  const dispatch = useDispatch();
  const { isOpen, title, user } = useSelector(({ main }) => ({
    isOpen: main.isSidebarOpen,
    title: main.pageTitle,
    user: main.user,
  }));

  const toggleSidebarAction = () => dispatch(toggleSidebar());

  if (!user) {
    return null;
  }

  const isTablet = isMdWidth(width);

  return (
    <StyledHeader isOpen={isOpen}>
      {!isTablet && <h1 className="page-title">{title}</h1>}
      {isTablet && (
        <MenuIcon className="burger-icon" onClick={toggleSidebarAction} />
      )}

      <nav>
        <NYCursor />

        <CreationDropdown />

        {isBellWorks && <Bell />}

        <UserMenu />
      </nav>

      <ChristmasLights />
    </StyledHeader>
  );
};

export default memo(Header);
