import React, { memo, useState } from 'react';
import styled from 'styled-components';

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { RoleChecker } from 'src/utils/HOC/protector';
import StyledNavLink from 'src/ui/containers/Header/components/StyledNavLink';
import ListItem from '@mui/material/ListItem';

import ExtraHourModal from 'src/ui/components/ExtraHoursModal/ExtraHourModal';
import { routePaths, userRoles } from 'src/utils/constants';

const CreationDropdown = () => {
  const [anchor, setAnchor] = useState(null);
  const [isOpen, setIsOpen] = useState(false);

  const toggleAnchor = (ev) => {
    if (anchor) {
      return setAnchor(null);
    }

    setAnchor(ev.currentTarget);
  };

  const toggleModal = () => {
    setIsOpen(true);
  };

  return (
    <>
      <StyledPlusIcon
        onClick={toggleAnchor}
      />

      <ExtraHourModal
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />

      {/* Have a React warning on open event. Try to solve later */}
      <Menu
        onClose={toggleAnchor}
        open={Boolean(anchor)}
        anchorEl={anchor}
        getContentAnchorEl={null}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
      >
        {menuItemsList.map((item, index) => (
          <RoleChecker key={index} roles={item.role}>
            {item.title === 'Создать переработку'
              ? (
                <ListItem
                  button
                  onClick={() => {
                    toggleModal();
                    toggleAnchor();
                  }}
                >
                  {item.title}
                </ListItem>
              ) : (
                <StyledNavLink to={item.to} className={({ isActive }) => (isActive ? 'current-link' : '')} exact>
                  <MenuItem onClick={toggleAnchor}>
                    {item.title}
                  </MenuItem>
                </StyledNavLink>
              )
            }
          </RoleChecker>
        ))}
      </Menu>
    </>
  );
};

const anchorOrigin = { vertical: 'bottom', horizontal: 'center' };
const transformOrigin = { vertical: 'top', horizontal: 'center' };

const menuItemsList = [
  // {
  //   title: 'Добавить переработку',
  //   to: routePaths.common.createOvertime,
  //   role: 'any',
  // },
  {
    title: 'Подать заявку',
    to: routePaths.common.createRequest,
    role: 'any',
  },
  {
    title: 'Создать резюме',
    to: routePaths.sales.createCv,
    role: ['admin', 'sales'],
  },
  {
    title: 'Создать портфолио',
    to: routePaths.sales.createPortfolio,
    role: ['admin', 'sales'],
  },
  {
    title: 'Создать переработку',
    role: Object.values(userRoles).filter((role) => role !== 'student'),
  },
];

const StyledPlusIcon = styled(AddCircleOutlineIcon)`
  cursor: pointer;
  border-radius: 100%;
  fill: ${({ theme }) => theme.colors.navbar.text};
`;

export default memo(CreationDropdown);
