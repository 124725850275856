import { createAsyncThunk } from '@reduxjs/toolkit';

import authApi from 'src/apiV2/authApi';
import systemApi from 'src/apiV2/systemApi';
import { updateUser, setCurrentDate } from './reducer';
import checkReferrer from 'src/utils/checkReferrer';

export const authorize = createAsyncThunk(
  'main/authorizeUser',
  async (arg, { dispatch }) => {
    try {
      const user = await authApi.check();

      checkReferrer();

      const { currentDate } = await systemApi.getCurrentDate().catch(() => ({ currentDate: 0 }));

      dispatch(setCurrentDate(currentDate));
      dispatch(updateUser(user));
    } catch (err) {
      dispatch(updateUser(null));
    }
  },
);
