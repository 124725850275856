import React, { memo, useCallback } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { useDispatch } from 'react-redux';
import { darken } from 'polished';

import Divider from '@mui/material/Divider';
import HomeIcon from '@mui/icons-material/Home';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import TableChartIcon from '@mui/icons-material/TableChart';
// import WebIcon from '@mui/icons-material/Web';
// import WorkIcon from '@mui/icons-material/Work';
import PeopleIcon from '@mui/icons-material/People';
// import FolderSharedIcon from '@mui/icons-material/FolderShared';
import MemoryIcon from '@mui/icons-material/Memory';
import PeopleStaff from '@mui/icons-material/PeopleAlt';
import LinkItem from 'src/ui/containers/Sidebar/components/LinkItem';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import RateReviewIcon from '@mui/icons-material/RateReview';
import ImportantDevices from '@mui/icons-material/ImportantDevices';
// import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import Assignment from '@mui/icons-material/Assignment';
import Book from '@mui/icons-material/Book';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import SchoolIcon from '@mui/icons-material/School';
import PsychologyAltIcon from '@mui/icons-material/PsychologyAlt';
import {
  faSignOutAlt,
  faQuestionCircle,
} from '@fortawesome/free-solid-svg-icons';
import { logOut } from 'src/utils';
import config from 'src/config';
import theme from 'src/ui/styles/StyledComponentsTheme/themes/main';
import { routePaths, userRoles } from 'src/utils/constants';
import { toggleSidebar } from 'src/store/main/reducer';
import { useAppSelector } from 'src/store';

const NavMenu = (props) => {
  const isBirthDay = useAppSelector(({ main }) => main.events.isBirthDay);
  const isSidebarOpen = useAppSelector(({ main }) => main.isSidebarOpen);
  const dispatch = useDispatch();

  const onItemClick = useCallback(() => {
    if (props.isTablet) {
      dispatch(toggleSidebar(false));
    }
  }, [props.isTablet, dispatch]);

  return (
    <StyledMain isSidebarOpen={isSidebarOpen || props.isTablet}>
      <nav>
        {isBirthDay && (
          <LinkItem
            isTablet={false}
            onClick={onItemClick}
            to={routePaths.events.birthDayQuiz}
            title="Квиз"
            icon={<HelpOutlineIcon />}
          />
        )}

        {navList.map((item) => (
          <LinkItem
            key={item.to || item.href}
            isTablet={props.isTablet}
            onClick={onItemClick}
            {...item}
          />
        ))}
        <Divider />
        {outerLinks.map((item) => (
          <LinkItem
            key={item.to || item.href}
            isTablet={props.isTablet}
            onClick={onItemClick}
            {...item}
          />
        ))}
      </nav>

      <p className="log-out-item" onClick={logOut}>
        <FontAwesomeIcon icon={faSignOutAlt} />

        <span className="nav-item-title">Выход</span>
      </p>
    </StyledMain>
  );
};

const outerLinks = [
  {
    href: config.crmUrl,
    target: '_blank',
    title: 'CRM',
    icon: <TableChartIcon />,
    roles: Object.values(userRoles).filter((role) => role !== 'student'),
  },
  {
    href: config.oldStaffUrl,
    target: '_blank',
    title: 'Старый Staff',
    icon: <PeopleIcon />,
  },
  {
    href: config.guidebook,
    target: '_blank',
    title: 'Guidebook',
    icon: <Book />,
    roles: Object.values(userRoles).filter((role) => role !== 'student'),
  },
  {
    href: config.regulations,
    target: '_blank',
    title: 'Регламенты',
    icon: <Assignment />,
    roles: Object.values(userRoles).filter((role) => role !== 'student'),
  },
  {
    href: config.pmUtilsUrl,
    target: '_blank',
    title: 'PM утилиты',
    icon: <PictureAsPdfIcon />,
    roles: Object.values(userRoles).filter((role) => role !== 'student'),
  },
];

const navList = [
  {
    to: routePaths.home,
    title: 'Домой',
    icon: <HomeIcon />,
    onlyForTablets: true,
  },
  // {
  //   to: routePaths.articles,
  //   title: 'Статьи',
  //   icon: <WebIcon />,
  // },
  // {
  //   to: routePaths.hrBoards,
  //   title: 'Boards',
  //   icon: <ViewColumnIcon />,
  //   roles: ['hr', 'admin'],
  // },
  // {
  //   to: routePaths.sales.projects,
  //   title: 'Проекты',
  //   icon: <WorkIcon />,
  //   roles: ['sales', 'admin'],
  // },
  {
    to: routePaths.diagram,
    title: 'ERP Диаграмма',
    icon: <EqualizerIcon />,
    roles: ['sales', 'admin'],
  },
  {
    to: routePaths.staff,
    title: 'Сотрудники',
    icon: <PeopleStaff />,
    roles: ['hr', 'admin', 'officeManager'],
  },
  {
    to: routePaths.admin.listOfRequests,
    title: 'Заявки',
    icon: <FontAwesomeIcon icon={faQuestionCircle} />,
    roles: ['admin', 'hr', 'officeManager', 'sales'],
  },
  // {
  //   to: routePaths.sales.createPortfolio,
  //   title: 'Создание Портфолио',
  //   icon: <FolderSharedIcon />,
  //   roles: ['sales', 'admin'],
  // },
  {
    to: routePaths.sales.projects,
    title: 'Проекты',
    icon: <MemoryIcon />,
    roles: ['sales', 'admin'],
  },
  {
    to: routePaths.inventory,
    title: 'Инвентарь',
    icon: <ImportantDevices />,
    roles: ['admin', 'officeManager'],
  },
  {
    to: routePaths.calendar,
    title: 'Календарь событий',
    icon: <CalendarTodayIcon />,
    roles: Object.values(userRoles).filter((role) => role !== 'student'),
  },
  {
    to: routePaths.extra.extraList,
    title: 'Переработки',
    icon: <AccessTimeIcon />,
    roles: ['admin'],
  },
  {
    to: routePaths.reviewers.management,
    title: 'Ревьюеры',
    icon: <RateReviewIcon />,
    roles: ['admin'],
  },
  {
    to: routePaths.internship,
    title: 'Стажировка',
    icon: <SchoolIcon />,
    roles: ['admin', 'mentor'],
  },
  {
    to: routePaths.syllabus,
    title: 'Стажировка',
    icon: <PsychologyAltIcon />,
    roles: ['student'],
  },
  {
    to: routePaths.customers.list,
    title: 'Клиенты',
    icon: <PeopleIcon />,
    roles: ['admin', 'officeManager', 'sales'],
  },
];

const padding = 15;
const iconWidth = +theme.sidebar.width.closed.replace(/px/, '') - padding * 2;
const StyledMain = styled.main`
  background-color: ${({ theme }) => theme.colors.navbar.background};
  color: ${({ theme }) => theme.colors.navbar.text};
  flex-direction: column;
  flex-grow: 1;
  display: flex;
  justify-content: space-between;
  padding: ${padding}px 0;
  overflow: hidden;
  overflow-y: auto;

  .log-out-item {
    cursor: pointer;
    margin-top: 40px;
  }

  .log-out-item,
  a {
    display: flex;
    align-items: center;
    transition: ${({ theme }) => theme.transition};
    padding: 10px ${padding}px;
    padding-right: 40px;

    :hover {
      background-color: ${({ theme }) => darken(0.05, theme.colors.navbar.background)};
    }
  }

  .nav-item-title {
    transition: ${({ theme }) => theme.transition};
    opacity: ${({ isSidebarOpen }) => (isSidebarOpen ? 1 : 0)};
    white-space: nowrap;
  }

  .current-link {
    color: ${({ theme }) => theme.colors.primary.main};
    svg {
      fill: ${({ theme }) => theme.colors.primary.main};
    }
  }

  && svg {
    cursor: pointer;
    fill: ${({ theme }) => theme.colors.navbar.text};
    transition: ${({ theme }) => theme.transition};
    margin-right: 15px;

    ${({ isSidebarOpen }) => (false && !isSidebarOpen // eslint-disable-line no-constant-condition
    ? css`
            width: ${iconWidth}px;
            min-width: ${iconWidth}px;
            height: ${iconWidth}px;
            max-height: 40px;
          `
    : css`
            width: 30px;
            min-width: 30px;
            height: 30px;
            max-height: 30px;
          `)};
  }
`;

NavMenu.propTypes = {
  isTablet: PropTypes.bool,
};

NavMenu.defaultProps = {
  isTablet: false,
};

export default memo(NavMenu);
