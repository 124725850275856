import React from 'react';
import _ from 'lodash';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Tooltip from '@mui/material/Tooltip';
import Checkbox from '@mui/material/Checkbox';
import CustomSearch from 'src/pages/Reviewers/components/Filters/components/CustomSearch';
import TaskItem from '../taskItem';
import DetailTask from '../DetailTask/DetailTask';
import CreateAndEditTask from '../CreateAndEditTask/CreateAndEditTask';
import TasksAllWrapper from './TasksAll.styles';
import Modal from 'src/ui/components/Modal';
import { type TaskType } from '../../utils/types';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import {
  getCurrentInternHolder,
  onlyParentTasks,
  selectInternId,
} from '../../utils/selectors';
import thunk from '../../store/thunks';
import type { ResponseDeleteTaskType } from 'src/apiV3/internshipApi';

type PropsType = { dropDisabled: boolean };
const Tasks: React.FC<PropsType> = (props) => {
  const id = useAppSelector(selectInternId);
  const tasks = useAppSelector(onlyParentTasks);
  const tasksCurrentIntern = useAppSelector(getCurrentInternHolder);
  const [isSortingDirectionAsc, setIsSortingDirectionAsc] =
    React.useState(true);
  const [searchValue, setSearchValue] = React.useState('');
  const [isShowAll, setIsShowAll] = React.useState(false);
  const [isShowTaskDetail, setIsShowTaskDetails] = React.useState(false);
  const [isCreateTask, setIsCreateTask] = React.useState(false);
  const [currentTask, setCurrentTask] = React.useState<TaskType | null>(null);
  const dispatch = useAppDispatch();

  const internTaskIds = React.useMemo(() => {
    return tasksCurrentIntern?.plantTasks?.filter((task) => task.taskJob).map(
      (taskJob) => taskJob.taskJob?.parent,
    );
  }, [tasksCurrentIntern]);

  const sortedTaskMemo = React.useMemo(() => {
    let filteredTasks = tasks;
    if (searchValue) {
      filteredTasks = filteredTasks.filter((task) => {
        return task.title.toLowerCase().includes(searchValue.toLowerCase());
      });
    }
    if (!isShowAll) {
      filteredTasks = filteredTasks.filter(
        (task) => !internTaskIds?.includes(task.taskJobId),
      );
    }
    return isSortingDirectionAsc
      ? _.orderBy(filteredTasks, ['title'], ['asc'])
      : _.orderBy(filteredTasks, ['title'], ['desc']);
  }, [tasks, internTaskIds, isShowAll, isSortingDirectionAsc, searchValue]);

  const toOpenTaskDetail = (task: TaskType) => {
    setCurrentTask(task);
    setIsShowTaskDetails(true);
  };

  const toCloseTaskDetail = () => {
    setCurrentTask(null);
    setIsShowTaskDetails(false);
  };

  const toOpenCreateTaskWindow = () => {
    setIsCreateTask(true);
    setIsShowTaskDetails(false);
  };

  const toCloseCreateTaskWindow = () => {
    setIsCreateTask(false);
    setCurrentTask(null);
  };

  const toCreateOrEdit = async (task: TaskType) => {
    setIsCreateTask(false);
  };

  const changeSorting = () => {
    setIsSortingDirectionAsc((prev) => !prev);
  };

  const searchHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchValue(event.target.value);
  };

  const setVisible = () => {
    setIsShowAll((prev) => !prev);
  };
  const toDeleteTask = () => {
    if (!currentTask?.taskJobId) return;
    const data : ResponseDeleteTaskType = {
      userId: null,
      actionFlag: 'task',
      id: currentTask.taskJobId,
    };

    dispatch(thunk.deleteTask(data));
    setIsShowTaskDetails(false);
    setCurrentTask(null);
  };

  return (
    <TasksAllWrapper isSortingDirectionAsc={isSortingDirectionAsc}>
      <header className="header__allTask">
        <button className="add-button" onClick={toOpenCreateTaskWindow}>
          <AddCircleOutlineIcon />
        </button>
        <p>Все задания</p>
        <button className="sort-button" onClick={changeSorting}>
          <ArrowCircleDownIcon />
        </button>
      </header>

      <div className="filter-container">
        <div className="search-field">
          <CustomSearch value={searchValue} searchHandler={searchHandler} />
        </div>

        <Tooltip title="Показать все">
          <Checkbox
            className="tasks-check-box"
            checked={isShowAll}
            onChange={setVisible}
          />
        </Tooltip>
      </div>

      <Droppable droppableId="allTasks" isDropDisabled={props.dropDisabled}>
        {(provided) => (
          <div
            className="tasks"
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {sortedTaskMemo.map(
              (task, index) => task.taskJobId && (
                  <Draggable
                    key={task.taskJobId}
                    draggableId={task.taskJobId.toString()}
                    index={index}
                    isDragDisabled={
                      (internTaskIds &&
                        internTaskIds.includes(task.taskJobId)) ||
                      !id
                    }
                    disableInteractiveElementBlocking
                  >
                    {(provided) => (
                      <div
                        className="tasks-list"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <TaskItem
                          key={task.taskJobId}
                          task={task}
                          toOpenTaskDetail={toOpenTaskDetail}
                          internTaskIds={internTaskIds}
                        />
                      </div>
                    )}
                  </Draggable>
              ),
            )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>

      <Modal open={isShowTaskDetail} onClose={toCloseTaskDetail}>
        <DetailTask
        toDeleteTask={toDeleteTask}
          task={currentTask}
          toOpenCreateTaskWindow={toOpenCreateTaskWindow}
        />
      </Modal>

      <Modal open={isCreateTask} onClose={toCloseCreateTaskWindow}>
        <CreateAndEditTask
          flag="task"
          task={currentTask}
          onSubmit={toCreateOrEdit}
          onClose={toCloseCreateTaskWindow}
        />
      </Modal>
    </TasksAllWrapper>
  );
};

export default Tasks;
