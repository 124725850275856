import React from 'react';
import { taskLevels } from 'src/pages/Internship/utils/constant';
import type { TaskJobsLevelENUM } from 'src/pages/Internship/utils/types';

interface IPropsTaskProgress {
  startTask: Date | null;
  finishTask: Date | null;
  limits: number | null;
  levelCopy?: TaskJobsLevelENUM;
  timeLeft: {
    days: number;
    hours: number;
    minutes: number;
    totalSeconds: number;
  };
}

const TaskProgressInfo: React.FC<IPropsTaskProgress> = ({
  startTask,
  finishTask,
  limits,
  levelCopy,
  timeLeft,
}) => {
  const deadlineVisible = React.useMemo(() => {
    return startTask !== null && finishTask === null && timeLeft.totalSeconds;
  }, [startTask, finishTask, timeLeft]);

  return (
    <div
      className="task__level-limit"
    >
      <p>{`Срок на выполнение: ${limits} дн.`}</p>
      {levelCopy && <p>{`Уровень: ${taskLevels[levelCopy]}`}</p>}
      {deadlineVisible && (
        <div>
          <span>До дедлайна осталось: </span>
          <span>
            {timeLeft.days} дн. {timeLeft.hours} ч. {timeLeft.minutes} мин.
          </span>
        </div>
      )}
    </div>
  );
};

export default TaskProgressInfo;
