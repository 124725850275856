export enum EnvTypeENUM {
  development = 'development',
  local = 'local',
  test = 'test',
  staging = 'staging',
  production = 'production',
}

export const envType = (
  process.env.REACT_APP_ENV ||
  process.env.NODE_ENV ||
  EnvTypeENUM.development
) as EnvTypeENUM;

export const isDev = envType === EnvTypeENUM.development;
export const isTest = envType === EnvTypeENUM.test;
export const isStage = envType === EnvTypeENUM.staging;
export const isProd = envType === EnvTypeENUM.production;

const config = {
  apiUrl: 'http://localhost:4000/api',
  oldServerUrl: 'http://localhost:4001/api',
  oldStaffUrl: 'http://localhost:3001',
  crmUrl: 'http://localhost:4200',
  appId: 'staff_2.0',
  guidebook: 'https://www.figma.com/proto/XVCrdDJDd0CON1NWaPX5kH/%D0%93%D0%B0%D0%B9%D0%B4?scaling=min-zoom&page-id=0%3A1&starting-point-node-id=1%3A2&node-id=1%3A2',
  regulations: 'https://drive.google.com/drive/u/0/folders/1keDVBw0Bd3yFk7uD2LUBNmIMutCxWJ8k',
  pmTools: 'http://pm-tools.fusion-tech.pro',
  tokenCookieDomain: 'localhost',
  maxNumberOfAttempts: 10,
  pmUtilsUrl: 'http://pm-tools.fusion-tech.pro',
};

// LOCAL with deployed stage API
// const config = {
//   apiUrl: 'https://staff.demo.fusion-tech.pro/api',
//   oldStaffUrl: 'https://staff.demo.fusion-team.com',
//   crmUrl: 'https://crm.demo.fusion-team.com',
//   oldServerUrl: 'http://localhost:4001/api',
//   appId: 'staff_2.0',
//   guidebook: 'https://www.figma.com/proto/XVCrdDJDd0CON1NWaPX5kH/%D0%93%D0%B0%D0%B9%D0%B4?scaling=min-zoom&page-id=0%3A1&starting-point-node-id=1%3A2&node-id=1%3A2',
//   regulations: 'https://drive.google.com/drive/u/0/folders/1keDVBw0Bd3yFk7uD2LUBNmIMutCxWJ8k',
//   pmTools: 'http://pm-tools.fusion-tech.pro',
//   tokenCookieDomain: 'localhost',
//   maxNumberOfAttempts: 10,
//   pmUtilsUrl: 'http://pm-tools.fusion-tech.pro',
// };

// eslint-disable-next-line default-case
switch (envType) {
  case EnvTypeENUM.production:
    config.apiUrl = 'https://staff.fusion-team.com/api';
    config.oldStaffUrl = 'https://old-staff.fusion-team.com';
    config.crmUrl = 'https://crm.fusion-team.com';
    config.tokenCookieDomain = '.fusion-team.com';
    break;

  case EnvTypeENUM.local:
    config.apiUrl = 'http://new-api.staff.com/api';
    config.oldStaffUrl = 'http://old.staff.com';
    config.crmUrl = 'http://crm.staff.com';
    config.tokenCookieDomain = '.staff.com';
    break;

  case EnvTypeENUM.staging:
    config.apiUrl = 'https://staff.demo.fusion-tech.pro/api';
    config.oldStaffUrl = 'https://staff.demo.fusion-team.com';
    config.crmUrl = 'https://crm.demo.fusion-tech.pro';
    config.tokenCookieDomain = '.fusion-tech.pro';
    break;
}

export default config;
