import styled from 'styled-components';

export const InternsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.navbar.text};
  .intern__name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .sort-button {
    position: absolute;
    right: 8px;
    top: 7px;
    cursor: pointer;
  }
`;

export const InternWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
  padding: 3px 8px;
  border: 1px solid ${({ theme }) => theme.colors.navbar.text};
  cursor: pointer;
`;
