import React, { useRef } from 'react';
import Button from '@mui/material/Button';
import DetailTaskWrapper from './DetailTask.styles';
import { TaskJobsLevelENUM, type TaskType } from '../../utils/types';
import { getDurationItemStringString } from 'src/utils/';
import { taskLevels } from '../../utils/constant';
import { toast } from 'react-toastify';
import { useAppDispatch } from 'src/store/store';
import thunk from '../../store/thunks';

type PropsType = {
  task: TaskType | null;
  toOpenCreateTaskWindow?: () => void;
  toDeleteTask?: () => void;
};

const DetailTask: React.FC<PropsType> = ({
  task,
  toOpenCreateTaskWindow,
  toDeleteTask,
}) => {
  const myRef = useRef<HTMLDivElement>(null);
  if (!task) {
    return null;
  }

  const onEdit = () => {
    if (!toOpenCreateTaskWindow) return;
    toOpenCreateTaskWindow();
  };

  const onDelete = () => {
    if (!toDeleteTask) return;
    toDeleteTask();
  };

  return (
    <DetailTaskWrapper>
      <div className="task-title">{task.title.toUpperCase()}</div>
      <div
        className="task-description"
        ref={myRef}
        dangerouslySetInnerHTML={{ __html: task.description }}
      />
      <div className="task-item">
        {'Уровень: '}
        {task.level ? taskLevels[TaskJobsLevelENUM[task.level]] : 0}
      </div>
      <div className="task-item">
        {`Время на выполнение: ${getDurationItemStringString(
          task?.timeLimits,
          'day',
        )}`}
      </div>
      <div className="button-group">
        <Button variant="contained" onClick={onEdit}>
          Редактировать
        </Button>
        <Button variant="contained" onClick={onDelete}>
          Удалить
        </Button>
      </div>
    </DetailTaskWrapper>
  );
};
// deleteTask
export default DetailTask;
