import { css } from 'styled-components';

export default css`
  .ql-toolbar .ql-formats svg .ql-fill {
    fill: ${({ theme }) => theme.colors.mainText};
  }
  .ql-toolbar .ql-formats svg .ql-stroke {
    stroke: ${({ theme }) => theme.colors.mainText};
  }
  .ql-toolbar .ql-formats button:hover svg .ql-fill {
    fill: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats button:hover svg .ql-stroke {
    stroke: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-active svg .ql-fill {
    fill: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-active svg .ql-stroke {
    stroke: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-picker {
    color: inherit;
  }
  .ql-toolbar .ql-formats .ql-picker .ql-picker-item {
    border: 1px solid white;
  }
  .ql-toolbar .ql-formats .ql-picker .ql-picker-label:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-picker .ql-picker-label:hover svg .ql-fill {
    fill: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-picker .ql-picker-label:hover svg .ql-stroke {
    stroke: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-picker .ql-active {
    color: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-picker .ql-active svg .ql-fill {
    fill: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-picker .ql-active svg .ql-stroke {
    stroke: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-picker .ql-picker-options {
    background-color: ${({ theme }) => theme.colors.pageBackground};
  }
  .ql-toolbar .ql-formats .ql-picker .ql-picker-options .ql-picker-item:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-picker .ql-picker-options .ql-picker-item:hover svg .ql-fill {
    fill: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-picker .ql-picker-options .ql-picker-item:hover svg .ql-stroke {
    stroke: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-picker button:hover {
    color: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-picker button:hover svg .ql-fill {
    fill: ${({ theme }) => theme.colors.primary.main};
  }
  .ql-toolbar .ql-formats .ql-picker button:hover svg .ql-stroke {
    stroke: ${({ theme }) => theme.colors.primary.main};
  }
`;
