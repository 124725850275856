import styled from 'styled-components';

const CreateAndEditTaskWrapper = styled.form`
  margin-top: -6%;
  .task-title {
    text-align: center;
    padding: 15px;
    font-weight: 700;
    border-bottom: 1px solid ${({ theme }) => theme.colors.navbar.text};
    .MuiFormControl-root {
      width: 70%;
      .MuiFormHelperText-root {
        margin: 0;
      }
    }
    .MuiOutlinedInput-root {
      height: 50px;
      font-size: 18px;
      padding: 0 8px;
    }
    .MuiInputBase-input {
      padding: 0 5px;
    }
  }

  .task-description {
    padding-bottom: 15px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.navbar.text};
    ul,
    ol {
      padding: 0;
    }
    .MuiFormLabel-root {
      padding: 10px 0;
      font-size: 17px;
    }
    .ql-editor {
      min-height: 220px;
    }
    .ql-container {
      min-height: 220px;
    }
    .ql-stroke {
      stroke: ${({ theme }) => theme.colors.iconsIntern.new};
    }
    .ql-toolbar .ql-formats svg .ql-fill {
      fill: ${({ theme }) => theme.colors.mainText};
    }
    .ql-toolbar .ql-formats svg .ql-stroke {
      stroke: ${({ theme }) => theme.colors.mainText};
    }
    .ql-toolbar .ql-formats button:hover svg .ql-fill {
      fill: ${({ theme }) => theme.colors.primary.main};
    }
    .ql-toolbar .ql-formats button:hover svg .ql-stroke {
      stroke: ${({ theme }) => theme.colors.primary.main};
    }
    .ql-toolbar .ql-formats .ql-active svg .ql-fill {
      fill: ${({ theme }) => theme.colors.iconsIntern.new};
    }
    .ql-toolbar .ql-formats .ql-active svg .ql-stroke {
      stroke: ${({ theme }) => theme.colors.iconsIntern.new};
    }
    .ql-toolbar .ql-formats .ql-picker {
      color: inherit;
    }
    .ql-toolbar .ql-formats .ql-picker .ql-picker-item {
      border: 1px solid white;
    }
    .ql-toolbar .ql-formats .ql-picker .ql-picker-label:hover {
      color: ${({ theme }) => theme.colors.primary.main};
    }
    .ql-toolbar .ql-formats .ql-picker .ql-picker-label:hover svg .ql-fill {
      fill: ${({ theme }) => theme.colors.primary.main};
    }
    .ql-toolbar .ql-formats .ql-picker .ql-picker-label:hover svg .ql-stroke {
      stroke: ${({ theme }) => theme.colors.primary.main};
    }
    .ql-toolbar .ql-formats .ql-picker .ql-active {
      color: ${({ theme }) => theme.colors.iconsIntern.new};
    }
    .ql-toolbar .ql-formats .ql-picker .ql-active svg .ql-fill {
      fill: ${({ theme }) => theme.colors.iconsIntern.new};
    }
    .ql-toolbar .ql-formats .ql-picker .ql-active svg .ql-stroke {
      stroke: ${({ theme }) => theme.colors.iconsIntern.new};
    }
    .ql-toolbar .ql-formats .ql-picker .ql-picker-options {
      background-color: ${({ theme }) => theme.colors.pageBackground};
    }
    .ql-toolbar
      .ql-formats
      .ql-picker
      .ql-picker-options
      .ql-picker-item:hover {
      color: ${({ theme }) => theme.colors.primary.main};
    }
    .ql-toolbar
      .ql-formats
      .ql-picker
      .ql-picker-options
      .ql-picker-item:hover
      svg
      .ql-fill {
      fill: ${({ theme }) => theme.colors.primary.main};
    }
    .ql-toolbar
      .ql-formats
      .ql-picker
      .ql-picker-options
      .ql-picker-item:hover
      svg
      .ql-stroke {
      stroke: ${({ theme }) => theme.colors.primary.main};
    }
    .ql-toolbar .ql-formats .ql-picker button:hover {
      color: ${({ theme }) => theme.colors.primary.main};
    }
    .ql-toolbar .ql-formats .ql-picker button:hover svg .ql-fill {
      fill: ${({ theme }) => theme.colors.primary.main};
    }
    .ql-toolbar .ql-formats .ql-picker button:hover svg .ql-stroke {
      stroke: ${({ theme }) => theme.colors.primary.main};
    }
    .ql-formats .ql-bold .ql-active {
    }
    .ql-snow.ql-toolbar button:hover,
    .ql-snow .ql-toolbar button:hover,
    .ql-snow.ql-toolbar button:focus,
    .ql-snow .ql-toolbar button:focus,
    .ql-snow.ql-toolbar button.ql-active,
    .ql-snow .ql-toolbar button.ql-active,
    .ql-snow.ql-toolbar .ql-picker-label:hover,
    .ql-snow .ql-toolbar .ql-picker-label:hover,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active,
    .ql-snow.ql-toolbar .ql-picker-item:hover,
    .ql-snow .ql-toolbar .ql-picker-item:hover,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected {
      color: ${({ theme }) => theme.colors.iconsIntern.new};
    }
    .ql-snow.ql-toolbar button:hover .ql-fill,
    .ql-snow .ql-toolbar button:hover .ql-fill,
    .ql-snow.ql-toolbar button:focus .ql-fill,
    .ql-snow .ql-toolbar button:focus .ql-fill,
    .ql-snow.ql-toolbar button.ql-active .ql-fill,
    .ql-snow .ql-toolbar button.ql-active .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
    .ql-snow.ql-toolbar button:hover .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button:hover .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar button:focus .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button:focus .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke.ql-fill,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
      ${({ theme }) => theme.colors.iconsIntern.new};
    }
    .ql-snow.ql-toolbar button:hover .ql-stroke,
    .ql-snow .ql-toolbar button:hover .ql-stroke,
    .ql-snow.ql-toolbar button:focus .ql-stroke,
    .ql-snow .ql-toolbar button:focus .ql-stroke,
    .ql-snow.ql-toolbar button.ql-active .ql-stroke,
    .ql-snow .ql-toolbar button.ql-active .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
    .ql-snow.ql-toolbar button:hover .ql-stroke-miter,
    .ql-snow .ql-toolbar button:hover .ql-stroke-miter,
    .ql-snow.ql-toolbar button:focus .ql-stroke-miter,
    .ql-snow .ql-toolbar button:focus .ql-stroke-miter,
    .ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
    .ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-label:hover .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-item:hover .ql-stroke-miter,
    .ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
    .ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter {
      ${({ theme }) => theme.colors.iconsIntern.new};
    }
    .ql-snow a {
      color: ${({ theme }) => theme.colors.primary.main};
    }
    .ql-snow .ql-editor code {
      color: ${({ theme }) => theme.colors.gray.main};
    }
    .ql-snow .ql-editor pre {
      color: inherit;
      background-color: black;
    }
    .ql-snow .ql-editor blockquote {
      border-left: 4px solid ${({ theme }) => theme.colors.primary.main};
    }
  }
  .date__start-container {
    display: flex;
    flex-direction: column;
  }

  .task-item {
    position: relative;
    padding-top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    margin-bottom: 25px;
    .time-limit-p {
      width: 100%;
    }
    .error_formik {
      position: absolute;
      top: 100%;
      left: 0;
    }
  }

  .MuiDateCalendar-root {
    max-width: 300px;
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .MuiPickersCalendarHeader-root {
    padding: 0;
  }
  .MuiDayCalendar-header {
    justify-content: center;
  }
  .MuiDayCalendar-weekContainer {
    justify-content: center;
  }
  .task-item__date {
    display: flex;
    align-items: center;
    gap: 50px;
  }
  .date__start-info {
    display: flex;
    align-items: center;
  }

  .select-item {
    width: 200px;
    border: 1px solid ${({ theme }) => theme.colors.navbar.text};
  }

  .time-limit {
    width: 40px;
    .MuiFormControl-root {
      width: 80%;
      margin-top: -4px;
    }
    .MuiOutlinedInput-root {
      height: 35px;
    }
    .MuiInputBase-input {
      padding: 0 5px;
    }
  }

  .button-group {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid ${({ theme }) => theme.colors.navbar.text};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 768px) {
    .task-item__date {
      display: flex;
      flex-direction: column;
      align-items: start;
      gap: 4%;
    }
    .task-title {
      text-align: center;
      padding: none;
      font-weight: 700;
      border-bottom: 1px solid ${({ theme }) => theme.colors.navbar.text};
      display: flex;
      justify-content: center;
      .MuiFormControl-root {
        width: 80%;
      }
      .MuiOutlinedInput-root {
        height: 50px;
        font-size: 18px;
        padding: 0 8px;
      }
      .MuiInputBase-input {
        padding: 0 5px;
      }
    }
  }
  @media (max-width: 443px) {
    .task-title {
      padding: 0;
      padding-bottom: 10px;
      margin-top: -45px;
      .MuiFormControl-root {
        width: 70%;
      }
      .MuiInputBase-root {
        height: 30px;
        font-size: 14px;
      }
    }
    .task-description {
      .MuiFormLabel-root {
        font-size: 13px;
      }
    }
    .button-group {
      border-top: 1px solid ${({ theme }) => theme.colors.navbar.text};
      display: flex;
      flex-direction: column;
      gap: 5px;
    }
  }
  .task-item {
    padding-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
    .time-limit-p {
      max-width: 110px;
    }
    .error_formik {
      font-size: 15px;
      position: absolute;
      top: 100%;
      left: 0;
    }
    .task-title {
      .MuiFormControl-root {
        width: 70%;
        .MuiFormHelperText-root {
          margin: 0;
          font-size: 12px;
        }
      }
    }
  }
  .select-item--error {
    .select__control {
      border-color: ${({ theme }) => theme.colors.requests.status.denied.main};
    }
  }
`;

export default CreateAndEditTaskWrapper;
