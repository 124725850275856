import axios from 'axios';
import _random from 'lodash/random';

import config, { isDev } from 'src/config';
import helpers from 'src/utils/helpers';
import getAuthString from './getAuthString';
import createErrorResponseHandler from './createErrorResponseHandler';
import resetTokenForInstance from './resetTokenForInstance';
import getDeviceId, { DEVICE_ID_KEY } from './getDeviceId';

export type { ResponseType } from './http.types';

const BODY_SIZE = 100 * 1024 * 1024; // 100 MB

const http = axios.create({
  baseURL: `${config.apiUrl}/v3`,
  headers: {
    authorization: getAuthString(),
    [DEVICE_ID_KEY]: getDeviceId(),
  },
  maxContentLength: BODY_SIZE,
  maxBodyLength: BODY_SIZE,
});

export const resetToken = () => resetTokenForInstance(http);

http.interceptors.request.use(async (request) => {
  if (isDev) {
    await helpers.sleep(_random(100, 1000));
  }

  return request;
});

http.interceptors.response.use(undefined, createErrorResponseHandler(http));

export default http;
