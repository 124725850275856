import * as uuid from 'uuid';

import storageV2 from 'src/utils/storageV2';

export const DEVICE_ID_KEY = 'device-id';

const getDeviceId = () => {
  let storedDeviceId = storageV2.deviceId.get();

  if (!storedDeviceId) {
    storedDeviceId = uuid.v4();
    storageV2.deviceId.set(storedDeviceId);
  }

  return storedDeviceId;
};

export default getDeviceId;
