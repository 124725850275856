import axios from './axios';

const SYSTEM_PATH = '/system';

/**
 * @returns {Promise<{ currentDate: number }>}
 */
const getCurrentDate = () => {
  return axios.get(`${SYSTEM_PATH}/current-date`);
};

export default {
  getCurrentDate,
};
