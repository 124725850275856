import React from 'react';
import Intern from './Intern';
import { InternsWrapper } from './Interns.styles';
import type { UserType } from 'src/types';
import { useAppSelector, useAppDispatch } from 'src/store';

type PropsType = {
  interns: UserType[];
  currentIntern: UserType | null;
  setCurrentIntern: React.Dispatch<React.SetStateAction<UserType | null>>;
};

const Interns: React.FC<PropsType> = (props) => {
  const currentUser = useAppSelector(
    ({ main }) => main.user,
  ) as UserType | null;

  return (
    <InternsWrapper>
      <header className="header">Стажёры</header>

      <div className="interns">
        {props.interns.map((intern) => (
          <Intern
            currentUserId={currentUser?.id}
            key={intern.id}
            intern={intern}
            currentIntern={props.currentIntern}
            setCurrentIntern={props.setCurrentIntern}
          />
        ))}
      </div>
    </InternsWrapper>
  );
};

export default Interns;
