import { createAsyncThunk } from '@reduxjs/toolkit';
import dateFnsSub from 'date-fns/sub';
import dateFnsAdd from 'date-fns/add';
import dateFnsStartOfDay from 'date-fns/startOfDay';
import dateFnsEndOfDay from 'date-fns/endOfDay';
import dateFnsSetHours from 'date-fns/setHours';

import * as actions from './reducer';

import { getErpDataAxios } from '../../../apiV3/crm/erpApi';
import { toggleLoader } from '../../../store/main/reducer';
import erpDiagramApi from '../../../apiV2/erpDiagramApi';
import { REQUEST_TYPES_NAMES, REQUEST_TYPE_COLORS } from '../../../utils/constants';
import { getFullName } from '../../../utils';
import theme from '../../../ui/styles/StyledComponentsTheme/themes/main';

export const getData = createAsyncThunk(
  'diagram/getDevs',
  async (arg, { dispatch }) => {
    try {
      dispatch(toggleLoader(true));

      // eslint-disable-next-line import/no-named-as-default-member
      const { data: erpData } = await erpDiagramApi.getErpData();

      const {
        devIds,
        studentIds,
        requests,
        crmContracts,
        studentTasks,
        users,
        crmHotLeads,
      } = erpData;
      const usersObj = {};
      const hotLeadSaleIds = [];

      users.forEach((user) => {
        usersObj[user.id] = {
          ...user,
          fullName: getFullName(user),
        };
      });

      crmHotLeads.forEach((job) => {
        const sales = job.dedicated_seller;
        if (sales && usersObj[sales]) {
          hotLeadSaleIds.push(sales);
        }
      });
      const sortedDevsIds = devIds.sort((aId, bId) => {
        const aUser = usersObj[aId];
        const bUser = usersObj[bId];
        const aUserLastName = aUser.lastName || aUser.lastName_ru;
        const bUserLastName = bUser.lastName || bUser.lastName_ru;

        return aUserLastName > bUserLastName ? 1 : -1;
      });

      dispatch(actions.setDevIds(sortedDevsIds));
      dispatch(actions.setRequests(formatRequestToItem(requests)));
      dispatch(actions.setJobs(formatCrmTasksToItem(crmContracts, usersObj)));
      dispatch(actions.setStudentIds(studentIds));
      dispatch(actions.setStudentTasks(formatStudentTasksToItem(studentTasks, usersObj)));
      dispatch(actions.setHotLeadSaleIds(hotLeadSaleIds));
      dispatch(actions.setHotLeadTasks(formatHotLeadTasksToItem(crmHotLeads, usersObj)));
      dispatch(actions.setUsers(usersObj));
    } catch {
      dispatch(actions.setDevIds([]));
      dispatch(actions.setRequests([]));
      dispatch(actions.setJobs([]));
      dispatch(actions.setStudentIds([]));
      dispatch(actions.setStudentTasks([]));
      dispatch(actions.setHotLeadSaleIds([]));
      dispatch(actions.setHotLeadTasks([]));
    } finally {
      dispatch(actions.setIsLoading(true));
      dispatch(toggleLoader(false));
    }
  },
);

const getTooltip = (title = '', description = '') => {
  return `${title}${(description || '').trim() ? `\n------------\n${description}` : ''}`.trim();
};

export const getErpDataThunk = createAsyncThunk(
  'erpDiagram/getErpData',
  async (
    params,
    { rejectWithValue },
  ) => {
    try {
      const response = await getErpDataAxios(params);
      return response.data.payload;
    } catch (error) {
      rejectWithValue(error);
    }
  },
);

const formatRequestToItem = (requests) => {
  const itemsList = [];
  requests.forEach((request) => {
    // Time off
    if (request.dateFrom && request.type === 'dayOff') { return; }

    itemsList.push({
      id: `request--${request.id}`,
      group: request.user[0].id,
      title: REQUEST_TYPES_NAMES[request.type],
      tooltip: getTooltip(request.title, request.comment),
      backgroundColor: REQUEST_TYPE_COLORS[request.type],
      start_time: +dateFnsStartOfDay(
        request.dateFrom
          ? new Date(request.dateFrom)
          : dateFnsSub(new Date(request.dateTo), { days: 1 }), // For dayOff on full day type
      ),
      end_time: +dateFnsEndOfDay(new Date(request.dateTo)),

      type: 'request',
      request,
    });
  });

  return itemsList;
};

/** Count workdays with weekends
 * @param {Object} startDay task start time
 * @param {number} workDays workdays for task
 * @returns {number} Sum of workdays and weekends
 */
const getAllDays = (startDay, workDays) => {
  let currentDay = new Date(startDay);
  let counter = workDays;
  let allDays = 0;
  for (let i = 0; i <= workDays * 2; i++) {
    if (!(currentDay.getDay() === 0 || currentDay.getDay() === 6)) {
      counter -= 1;
    }
    allDays += 1;
    currentDay = dateFnsAdd(new Date(currentDay), { days: 1 });
    if (counter === 0) {
      return allDays;
    }
  }
};

/** Count startTime for probation task
 * @param {Object} date expected start time
 * @returns {Object} start time, exclude weekends
 */
const getStartTimeTask = (date) => {
  switch (date.getDay()) {
    case 6: {
      return dateFnsAdd(new Date(date), { days: 2 });
    }
    case 0: {
      return dateFnsAdd(new Date(date), { days: 1 });
    }
    default: {
      return date;
    }
  }
};

/** Count end time for studentTasks
 * @param {Object} task studentTask
 * @param {Object} finalDate expected start time for probation task
 * @returns {Object} end time
 */
const getEndTimeTask = (task, finalDate) => {
  if (task?.plan_taskJob?.finishTask) {
    return new Date(task?.plan_taskJob?.finishTask);
  }
  if (task?.plan_taskJob?.startTask) {
    return dateFnsAdd(new Date(task?.plan_taskJob?.startTask), {
      days: getAllDays(task?.plan_taskJob?.startTask, task.time_limits) - 1,
    });
  }
  return dateFnsAdd(new Date(finalDate), {
    days: getAllDays(finalDate, task.time_limits) - 1,
  });
};

const formatStudentTasksToItem = (studentPlans, usersObj) => {
  const itemsList = [];

  studentPlans.forEach((plan) => {
    let expectedFinalDate;
    const finalTask = plan.taskJobs?.find(((item) => item.level === 'final'));
    const probationTask = plan.taskJobs?.find((item) => item.level === 'probation');
    const mentor = usersObj[usersObj[plan.userId].mentor_id];
    const isEmptyTasks = ((!finalTask && !probationTask?.plan_taskJob?.startTask));
    if (isEmptyTasks) {
      return;
    }
    if (finalTask) {
      if (finalTask.plan_taskJob.finishTask) {
        expectedFinalDate = dateFnsAdd(
          new Date(finalTask.plan_taskJob.finishTask),
          { days: 1 },
        );
      } else {
        expectedFinalDate = dateFnsAdd(
          new Date(finalTask.plan_taskJob.startTask),
          {
            days: getAllDays(
              finalTask?.plan_taskJob.startTask,
              finalTask.time_limits,
            ),
          },
        );
      }

      itemsList.push({
        id: `task--${finalTask?.plan_taskJob?.id}`,
        group: plan.userId,
        title: finalTask.title,
        tooltip: getTooltip(finalTask.title, finalTask.description),
        backgroundColor: theme.colors.erp.studentTasks,
        start_time: +dateFnsSetHours(
          new Date(finalTask.plan_taskJob.startTask),
          9,
        ),
        end_time: +dateFnsSetHours(getEndTimeTask(finalTask), 19),

        type: 'studentTask',
        studentTask: {
          ...finalTask,
          mentor,
        }
        ,
      });
    }

    if (probationTask) {
      itemsList.push({
        id: `task--${probationTask.plan_taskJob?.id}`,
        group: plan.userId,
        title: probationTask.title,
        tooltip: getTooltip(probationTask.title, probationTask.description),
        backgroundColor: theme.colors.erp.studentTasks,
        start_time: +dateFnsSetHours(
          probationTask.plan_taskJob?.startTask
            ? new Date(probationTask.plan_taskJob?.startTask)
            : getStartTimeTask(expectedFinalDate),
          9,
        ),
        end_time: +dateFnsSetHours(
          getEndTimeTask(probationTask, expectedFinalDate),
          19,
        ),

        type: 'studentTask',
        studentTask: {
          ...probationTask,
          mentor,
        },
      });
    }
  });

  return itemsList;
};

const formatCrmTasksToItem = (jobs, usersObj) => {
  const itemsList = [];

  jobs.forEach((job) => {
    const manager = usersObj[job.subscription.find((subscriberId) => {
      const user = usersObj[subscriberId];

      return user?.tech_role === 'projectManager';
    })];

    const qaEngineer = job.subscription.filter((subscriberId) => {
      const user = usersObj[subscriberId];

      return user?.tech_role === 'qaEngineer';
    }).map((id) => usersObj[id]);

    const jobObject = {
      id: `job--${job.id}`,
      title: job.title,
      tooltip: getTooltip(job.title, job.description),
      start_time: +dateFnsStartOfDay(
        job.project_start_date
          ? new Date(job.project_start_date)
          : dateFnsSub(new Date(), { months: 2 }),
      ),
      end_time: +dateFnsEndOfDay(
        job.project_end_date
          ? new Date(job.project_end_date)
          : dateFnsAdd(new Date(), { months: 1 }),
      ),

      type: 'job',
      job: {
        ...job,
        manager,
        qaEngineer,
      },
    };

    job.subscription.forEach((userId) => {
      const user = usersObj[userId];

      if (!user || !user.isDev) { return; }

      itemsList.push({
        ...jobObject,
        id: `${jobObject.id}-${userId}`,
        group: userId,
      });
    });
  });

  return itemsList;
};

const formatHotLeadTasksToItem = (hotLeads, usersObj) => {
  const itemsList = [];

  hotLeads.forEach((job) => {
    const assignedUsers = [];

    job.subscription.forEach((subscriberId) => {
      const user = usersObj[subscriberId];
      if (user) {
        assignedUsers.push(user);
      }
    });

    itemsList.push({
      id: `hotLead--${job.id}`,
      group: job.dedicated_seller,
      title: job.title,
      tooltip: getTooltip(job.title, job.description),
      start_time: +dateFnsStartOfDay(new Date(job.project_start_date)),
      end_time: +dateFnsEndOfDay(
        job.project_end_date
          ? new Date(job.project_end_date)
          : dateFnsAdd(new Date(), { weeks: 2 }),
      ),

      type: 'hotLead',
      job: {
        ...job,
        assignedUsers,
      },
    });
  });

  return itemsList;
};
