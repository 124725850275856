import React from 'react';
import styled, { createGlobalStyle } from 'styled-components';

import Tooltip from '@mui/material/Tooltip';
import Portal from '@mui/material/Portal';
import Switch from '@mui/material/Switch';
import useEvents from 'src/store/main/useEvents';
import { ReactComponent as ChristmasTreeIcon } from './christmas-tree.svg';

import LocalStorageItem from 'src/utils/storageV2/LocalStorageItem';

const newYearCursorState = new LocalStorageItem({ key: 'new-year-cursor', defaultValue: true });

const NYCursor: React.FC = () => {
  const { isNewYear } = useEvents();
  const [isEnabled, setIsEnabled] = React.useState(newYearCursorState.get() ?? false);
  const [cursorPosition, setCursorPosition] = React.useState({ x: 0, y: 0 });

  React.useEffect(() => {
    newYearCursorState.set(isEnabled);

    if (!isEnabled) {
      return;
    }

    const handleMove = (ev: MouseEvent) => {
      const { clientX, clientY } = ev;

      setCursorPosition({ x: clientX, y: clientY });
    };

    window.addEventListener('mousemove', handleMove);
    return () => {
      window.removeEventListener('mousemove', handleMove);
    };
  }, [isEnabled]);

  if (!isNewYear) {
    return null;
  }

  return (
    <div>
      <Tooltip title={`${isEnabled ? 'Выключить' : 'Включить'} праздничный курсор`}>
        <Switch
          checked={isEnabled}
          onClick={() => setIsEnabled((prev) => !prev)}
        />
      </Tooltip>

      {isEnabled && (
        <>
          <SpecialCursorStyles />

          <Portal>
            <StyledChristmasTreeIcon
              style={{ left: cursorPosition.x, top: cursorPosition.y }}
            />
          </Portal>
        </>
      )}
    </div>
  );
};

const SpecialCursorStyles = createGlobalStyle`
  * {
    cursor: none !important;
  }
`;

const StyledChristmasTreeIcon = styled(ChristmasTreeIcon)`
  position: fixed;
  pointer-events: none;
  /* I don't care. It's just for fun :-D */
  z-index: 99999999;

  --size: 35px;

  width: var(--size);
  height: var(--size);
  rotate: -29deg;
  translate: -13px -8px;
`;

export default NYCursor;
