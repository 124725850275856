import { addDays } from 'date-fns';
import type { InternHolderType, PlanTaskJobType, TaskType } from './types';

export const isTaskOverdue = (days: number, startDateString: Date): boolean => {
  const startDate = new Date(startDateString);
  const deadline = addDays(startDate, days);
  const today = new Date();
  return today > deadline;
};

export const isTaskOverdueWithFinishDateAndDays = (
  startDate: Date,
  finishDate: Date,
  days: number,
): boolean => {
  if (finishDate === null) {
    return false;
  }

  const deadline = addDays(startDate, days);
  return finishDate > deadline;
};

export function findCurrentInternPlanTasks(
  id: number,
  internsHoldersTasks: InternHolderType[],
): InternHolderType | undefined {
  return internsHoldersTasks.find((currentHolder) => currentHolder.id === id);
}

export function comparePlanTaskJobs(
  a: PlanTaskJobType,
  b: PlanTaskJobType,
  chains: number[],
): number {
  if (!chains || !a.planTaskJobId || !b.planTaskJobId) {
    return 0;
  }
  const aIndex = chains.indexOf(a.planTaskJobId);
  const bIndex = chains.indexOf(b.planTaskJobId);

  if (aIndex === -1 && bIndex === -1) {
    return 0;
  } if (aIndex === -1) {
    return 1;
  } if (bIndex === -1) {
    return -1;
  }

  return aIndex - bIndex;
}

export function sortTasksPlan(
  currentInternPlanTasks: InternHolderType | undefined,
): PlanTaskJobType[] | undefined {
  return currentInternPlanTasks?.plantTasks
    .slice()
    .sort((a, b) => comparePlanTaskJobs(a, b, currentInternPlanTasks.chains));
}

export function mapTasks(
  tasksPlan: PlanTaskJobType[] | undefined,
): TaskType[] | undefined {
  return tasksPlan?.filter((task) => task.taskJob).map((task) => {
    const newTask: TaskType = {
      taskJobId: task.taskJob.taskJobId,
      title: task.taskJob.title,
      description: task.taskJob.description,
      timeLimits: task.taskJob.timeLimits,
      level: task.taskJob.level,
      isParent: task.taskJob.isParent,
      icon: getTaskIcon(task),
      planTask: task.planTaskJobId,
      startTask: task.startTask ? new Date(task.startTask) : null,
      finishTask: task.finishTask ? new Date(task.finishTask) : null,
    };
    return newTask;
  });
}

export function getTaskIcon(task: PlanTaskJobType) {
  if (task.startTask === null) {
    return 'new';
  } if (
    task.taskJob.timeLimits &&
    isTaskOverdue(task.taskJob.timeLimits, new Date(task.startTask)) &&
    task.finishTask === null
  ) {
    return 'deadline';
  } if (
    task.taskJob.timeLimits &&
    task.finishTask &&
    isTaskOverdueWithFinishDateAndDays(
      new Date(task.startTask),
      new Date(task.finishTask),
      task.taskJob.timeLimits,
    )
  ) {
    return 'badDone';
  } if (task.finishTask === null) {
    return 'started';
  }
  return 'done';
}
