import { createSlice } from '@reduxjs/toolkit';

const getInitialStore = () => ({
  projects: [],
  pdfUrl: '',
});

const cvSlice = createSlice({
  name: 'cvBuilder',
  initialState: getInitialStore(),
  reducers: {
    setProjects: (store, { payload }) => ({
      ...store,
      projects: payload,
    }),
    setPdfUrl: (store, { payload }) => ({
      ...store,
      pdfUrl: payload,

    }),
  },
});

export const {
  setProjects,
  setPdfUrl,
} = cvSlice.actions;

export default cvSlice.reducer;
