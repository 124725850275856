import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import type { SingleValue } from 'react-select';
import dayjs from 'dayjs';
import Select from 'src/ui/components/Select';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import RichTextBox from 'src/ui/components/RichTextBox';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CreateAndEditTaskWrapper from './CreateAndEditTask.styles';
import type { TaskType } from '../../utils/types';
import { requiredErrorMessage } from 'src/utils/constants';
import {
  changeDateEndPlanTaskJob,
  changeDateStartPlanTaskJob,
} from '../../store/reducer';
import { taskLevels } from '../../utils/constant';
import { useAppDispatch } from 'src/store/store';
import thunks from '../../store/thunks';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { toast } from 'react-toastify';
import { TaskJobsLevelENUM } from '../../utils/types';
import { FormHelperText } from '@mui/material';

const levelOptions = Object.entries(taskLevels).map(([value, label]) => ({
  value,
  label,
}));
const projectValidation = Yup.object().shape({
  title: Yup.string()
    .trim()
    .required(requiredErrorMessage)
    .min(3, 'Название слишком короткое')
    .max(255, 'Название слишком длинное'),
  description: Yup.string(),
  level: Yup.string()
    .oneOf(Object.values(TaskJobsLevelENUM))
    .required('Выберите уровень сложности'),
  timeLimits: Yup.number()
    .min(1)
    .required('Укажите корректное количество дней!'),
});

type PropsType = {
  task?: TaskType | null;
  onSubmit: (task: TaskType) => void;
  onClose: () => void;
  onUpdate?: (task: TaskType) => void;
  flag?: 'plan' | 'task';
  setCurrentTask?: React.Dispatch<React.SetStateAction<TaskType | null>>;
};

type ValueType = SingleValue<{
  value: string;
  label: string;
}>;

type VisibleStateType = {
  start: boolean;
  end: boolean;
};

const CreateAndEditTask: React.FC<PropsType> = (props) => {
  const dispatch = useAppDispatch();
  const [visible, setVisible] = useState<VisibleStateType>({
    start: false,
    end: false,
  });

  const startDateString = React.useMemo(() => {
    if (!props.task?.startTask) return;
    return props.task?.startTask.toLocaleDateString('ru-RU', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }, [props.task?.startTask]);

  const finishDateString = React.useMemo(() => {
    if (!props.task?.finishTask) return;
    return props.task?.finishTask.toLocaleDateString('ru-RU', {
      day: 'numeric',
      month: 'short',
      year: 'numeric',
    });
  }, [props.task?.finishTask]);

  const initialValue = React.useMemo(() => {
    if (!props.task) {
      return {
        title: '',
        description: '',
        level: '',
        timeLimits: 0,
      };
    }
    return {
      title: props.task.title,
      description: props.task.description,
      level: props.task.level ? props.task.level : '',
      timeLimits: props.task.timeLimits ? props.task.timeLimits : 0,
    };
  }, [props.task]);

  const conditions = React.useMemo(() => {
    return {
      isVisibleDateStart: props.task?.startTask && !(props.task.icon === 'new'),
      isVisibleDateFinish:
        props.task?.finishTask &&
        (props.task.icon === 'done' || props.task.icon === 'badDone'),
      isPlanTaskUser: props.flag === 'plan',
      isIncorrectDate:
        props.task &&
        props.flag === 'plan' &&
        props.task.startTask &&
        props.task.finishTask &&
        props.task.startTask.getTime() > props.task.finishTask.getTime(),
      isCreateNewTask: !props.task || !props.flag,
    };
  }, [props.task, props.flag]);

  const valueStartDateCalendar = React.useMemo(() => {
    return dayjs(props.task?.startTask);
  }, [props.task?.startTask]);

  const valueFinishDateCalendar = React.useMemo(() => {
    return dayjs(props.task?.finishTask);
  }, [props.task?.finishTask]);

  const formik = useFormik({
    initialValues: initialValue,
    validationSchema: projectValidation,
    enableReinitialize: true,
    onSubmit: (values) => {
      const data: TaskType = {
        title: values.title.trim(),
        description: values.description.trim(),
        level: values.level as unknown as TaskJobsLevelENUM,
        timeLimits: values.timeLimits,
      };
      onCreate();
    },
  });

  const onCreate = () => {
    if (conditions.isIncorrectDate) {
      return toast.error('Некорректные значения дат для задачи');
    }
    if (!props.task || !props.flag) {
      const data = {
        ...formik.values,
        level: formik.values.level as unknown as TaskJobsLevelENUM,
        id: Date.now(),
      };
      props.onClose();
      return dispatch(thunks.postNewTaskForInterns(data));
    }
    const data = {
      ...formik.values,
      level: formik.values.level as unknown as TaskJobsLevelENUM,
      id: props.task.taskJobId,
      flagAction: props.flag,
      startTask: props.flag === 'plan' ? props.task.startTask : null,
      finishTask: props.flag === 'plan' ? props.task.finishTask : null,
    };
    switch (props.flag) {
      case 'plan':
        dispatch(thunks.updatePlanTaskInfo(data));
        break;
      default:
        dispatch(thunks.updateTaskInfo(data));
        break;
    }
    props.onClose();
  };

  const handleSelectChange = (option: ValueType) => {
    formik.setFieldValue('level', option?.value);
  };

  const setStartValue = (value: dayjs.Dayjs | null) => {
    if (!value || !props.task?.planTask || !props.setCurrentTask) return;
    const formattedDate = value.toISOString();
    dispatch(
      changeDateStartPlanTaskJob({
        id: props.task.planTask,
        date: formattedDate,
      }),
    );
    props.setCurrentTask({
      ...props.task,
      startTask: new Date(formattedDate),
    });
  };

  const setEndValue = (value: dayjs.Dayjs | null) => {
    if (!value || !props.task?.planTask || !props.setCurrentTask) return;
    const formattedDate = value.toISOString();
    dispatch(
      changeDateEndPlanTaskJob({
        id: props.task.planTask,
        date: formattedDate,
      }),
    );
    props.setCurrentTask({
      ...props.task,
      finishTask: new Date(formattedDate),
    });
  };

  const handleSetStatusStart = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (!props.task || !props.task.taskJobId || !props.setCurrentTask) return;
    dispatch(
      thunks.updateTaskStatusFromMentor({
        actionFlag: 'start',
        planTaskJobId: props.task.taskJobId,
      }),
    );
    props.onClose();
  };

  const handleSetStatusFinish = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (!props.task || !props.task.taskJobId || !props.setCurrentTask) return;
    dispatch(
      thunks.updateTaskStatusFromMentor({
        actionFlag: 'finish',
        planTaskJobId: props.task.taskJobId,
      }),
    );
    props.onClose();
  };

  const handleRebootTask = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => {
    e.stopPropagation();
    if (!props.task || !props.task.taskJobId || !props.setCurrentTask) return;

    dispatch(
      thunks.updateTaskStatusFromMentor({
        actionFlag: 'reboot',
        planTaskJobId: props.task.taskJobId,
      }),
    );
    props.onClose();
  };

  const handleSetVisibleStartCalendar = () => {
    setVisible((prevState) => {
      return {
        ...prevState,
        start: !prevState.start,
      };
    });
  };

  const handleSetVisibleFinishCalendar = () => {
    setVisible((prevState) => {
      return {
        ...prevState,
        end: !prevState.end,
      };
    });
  };

  return (
    <CreateAndEditTaskWrapper onSubmit={formik.handleSubmit}>
      <div className="task-title">
        <TextField
          name="title"
          value={formik.values.title}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.title && formik.errors.title)}
          helperText={formik.touched.title && formik.errors.title}
          margin="normal"
          placeholder="Название задачи"
          fullWidth
        />
      </div>

      <div className="task-description">
        <RichTextBox
          name="description"
          value={formik.values.description}
          label="Описание задачи"
          onChange={formik.handleChange}
          onBlur={() => formik.handleBlur('description')}
        />
      </div>

      <div className="task-item">
        <p>Уровень:</p>
        <Select
          name="level"
          value={levelOptions.find(
            (level) => level.value === formik.values.level,
          )}
          classNamePrefix="select"
          className={`select-item ${
            formik.touched.level && formik.errors.level
              ? 'select-item--error'
              : ''
          }`}
          placeholder="Уровень"
          options={levelOptions}
          onChange={handleSelectChange}
          error={Boolean(formik.touched.level && formik.errors.level)}
        />
        {formik.touched.level && formik.errors.level && (
          <FormHelperText error className="error_formik">
            {formik.errors.level}
          </FormHelperText>
        )}
      </div>

      <div className="task-item time-limits_container">
        <p className="time-limit-p">Время на выполнение:</p>
        <TextField
          className="time-limit"
          name="timeLimits"
          value={formik.values.timeLimits}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={Boolean(formik.touched.timeLimits && formik.errors.timeLimits)}
        />
        <p>дней</p>
        {formik.touched.timeLimits && formik.errors.timeLimits && (
          <FormHelperText error className="error_formik">
            Укажите корректное количество дней
          </FormHelperText>
        )}
      </div>
      {conditions.isPlanTaskUser && (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <div className="task-item__date">
            <div className="date__start-container">
              <div className="date__start-info">
                <p>Дата начала:</p>
                {conditions.isVisibleDateStart ? (
                  <Button onClick={handleSetVisibleStartCalendar}>
                    {startDateString}
                  </Button>
                ) : (
                  <Button onClick={handleSetStatusStart}>Начать</Button>
                )}
              </div>
              {visible.start && (
                <DateCalendar
                  value={valueStartDateCalendar}
                  onChange={setStartValue}
                  views={['year', 'month', 'day']}
                />
              )}
            </div>
            {props.task?.startTask && (
              <div className="date__start-container">
                <div className="date__start-info">
                  <p>Дата окончания:</p>
                  {conditions.isVisibleDateFinish ? (
                    <Button onClick={handleSetVisibleFinishCalendar}>
                      {finishDateString}
                    </Button>
                  ) : (
                    <Button onClick={handleSetStatusFinish}>Закончить</Button>
                  )}
                </div>
                {visible.end && (
                  <DateCalendar
                    value={valueFinishDateCalendar}
                    onChange={setEndValue}
                    views={['year', 'month', 'day']}
                  />
                )}
              </div>
            )}
          </div>
        </LocalizationProvider>
      )}

      <div className="button-group">
        <Button variant="contained" type="submit">
          {props.task ? 'Сохранить' : 'Создать'}
        </Button>
        {props.task?.startTask && (
          <Button variant="contained" onClick={handleRebootTask}>
            Перезапустить
          </Button>
        )}
        <Button variant="contained" onClick={props.onClose}>
          Отмена
        </Button>
      </div>
    </CreateAndEditTaskWrapper>
  );
};

export default CreateAndEditTask;
