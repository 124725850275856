import React from 'react';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

type PropsType = {
  value: string;
  searchHandler: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const CustomSearch = (props: PropsType) => {
  return (
    <Grid item xs={12} sm={4}>
      <TextField
        value={props.value}
        onChange={props.searchHandler}
        name="search"
        label="Поиск"
        variant="outlined"
        fullWidth
      />
    </Grid>
  );
};

export default CustomSearch;
