import config from 'src/config';

const checkReferrer = () => {
  const referrer = document.referrer.replace(/\/$/, '');

  if (referrer === config.crmUrl) {
    window.history.back();
  }
};

export default checkReferrer;
