import { useAppDispatch, useAppSelector } from 'src/store/store';
import StyledSyllabus, { StyledLottie } from './Syllabus.styles';
import Task from './components/Task/Task';
import { useEffect, useState } from 'react';
import thunks from '../Internship/store/thunks';
import empty from './empty.json';
import { useLottie } from 'lottie-react';
import { getInternFilteredPlan } from '../Internship/utils/selectors';

const Syllabus = () => {
  const options = {
    animationData: empty,
    loop: true,
    autoplay: true,
  };
  const taskState = useAppSelector(getInternFilteredPlan);
  const dispatch = useAppDispatch();
  const { View } = useLottie(options);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const getDispatchStatus = async () => {
      await dispatch(thunks.getUserPlan());
      setIsLoading(false);
    };
    getDispatchStatus();
  }, [dispatch]);

  if (!isLoading && (!taskState || taskState.length === 0)) {
    return (
          <StyledLottie>
            <div className="container-lottie">
              {View}
              <p>Скоро у Тебя появятся задания</p>
            </div>
          </StyledLottie>
    );
  }

  if (!isLoading) {
    return (
      <StyledSyllabus>
          <div className="tasks-holder">
            <div className="nav-header">
              <h1 className="header-syllabus">УЧЕБНЫЙ ПЛАН</h1>
            </div>
            <div className="tasks">
              { taskState && taskState.map((task) => (
                task.taskJob &&
                (<Task
                    key={task.taskJob.taskJobId}
                    task={task.taskJob}
                    planTask={task}
                />)
              ))}
            </div>
          </div>
      </StyledSyllabus>
    );
  }
};

export default Syllabus;
