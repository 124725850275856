import styled from 'styled-components';

const DetailTaskWrapper = styled.div`
  margin-top: -27px;
  margin-bottom: -5px;

  .task-title {
    padding: 0 30px;
    text-align: center;
    padding-bottom: 15px;
    font-weight: 700;
    border-bottom: 1px solid ${({ theme }) => theme.colors.navbar.text};
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    white-space: normal;
  }

  .task-description {
    padding: 15px 0;
    border-bottom: 1px solid ${({ theme }) => theme.colors.navbar.text};
  }

  .task-item {
    padding-top: 15px;
  }

  .button-group {
    margin-top: 15px;
    padding-top: 15px;
    border-top: 1px solid ${({ theme }) => theme.colors.navbar.text};
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .task-description {
    font-size: 18px;
    word-wrap: break-word;
    overflow-wrap: break-word;
    hyphens: auto;
    white-space: normal;
    a {
      color: ${({ theme }) => theme.colors.primary.main};
      text-decoration: none;
    }

    a:hover {
      color: ${({ theme }) => theme.colors.primary.main};
      text-decoration: underline;
    }
    code {
      background-color: black;
      border-radius: 4px;
      padding: 2px 6px;
      font-family: "Courier New", Courier, monospace;
      color: inherit;
      border: 1px solid ${({ theme }) => theme.colors.navbar.text};
    }

    pre {
      tab-size: 2;
      background-color: black;
      border-radius: 5px;
      padding: 10px;
      font-family: "Courier New", Courier, monospace;
      color: inherit;
      border: 1px solid ${({ theme }) => theme.colors.navbar.text};
      overflow-x: auto;
      white-space: pre-wrap;
      line-height: 1.5;
      max-height: 400px;
      overflow-y: auto;
    }

    p {
      margin: 5px 0;
      font-size: inherit;
    }

    ol,
    ul {
      padding-left: 25px;
    }
    .ql-align-center {
      text-align: center;
    }

    .ql-align-right {
      text-align: end;
    }
    .ql-align-justify {
      text-align: justify;
    }

    blockquote {
      border: dotted ${({ theme }) => theme.colors.primary.primaryGray} 1px;
      border-left: solid ${({ theme }) => theme.colors.primary.main} 7px;
      padding: 20px;
      background-color: ${({ theme }) => theme.colors.diagram.headerBorder};
      color: inherit;
      font-style: italic;
      font-size: 14px;
    }

    .ql-align-justify {
      text-align: justify;
    }
  }
`;

export default DetailTaskWrapper;
