import { createSlice } from '@reduxjs/toolkit';

const getInitialStore = () => ({
  mainTitle: '',
  forTitle: '',
  date: '',
  technologiesOptions: [],
  languageOptions: [
    { label: 'English', value: 'en' },
    { label: 'Русский', value: 'ru' },
  ],
  selectedLanguage: { label: 'English', value: 'en' },
  selectedTechnologies: [],
  projects: [],
  selectedProjects: [],
  portfolioUrl: '',
  isProjectsEditorOpen: false,
  isSubmitBtnDisable: false,
});

const diagramSlice = createSlice({
  name: 'portfolioBuilder',
  initialState: getInitialStore(),
  reducers: {
    reset: (store) => ({
      ...getInitialStore(),
      technologiesOptions: store.technologiesOptions,
    }),
    setMainTitle: (store, { payload }) => ({
      ...store,
      mainTitle: payload,
    }),
    setForTitle: (store, { payload }) => ({
      ...store,
      forTitle: payload,
    }),
    setDate: (store, { payload }) => ({
      ...store,
      date: payload,
    }),
    setTechnologiesOptions: (store, { payload }) => ({
      ...store,
      technologiesOptions: payload,
    }),
    setSelectedTechnologies: (store, { payload }) => ({
      ...store,
      selectedTechnologies: payload,
    }),
    setSelectedLanguage: (store, { payload }) => ({
      ...store,
      selectedLanguage: payload,
    }),
    setProjects: (store, { payload }) => ({
      ...store,
      projects: payload,
    }),
    setSelectedProjects: (store, { payload }) => ({
      ...store,
      selectedProjects: payload,
    }),
    setPortfolioUrl: (store, { payload }) => ({
      ...store,
      portfolioUrl: payload,
    }),
    setIsProjectsEditorOpen: (store, { payload }) => ({
      ...store,
      isProjectsEditorOpen: payload,
    }),
    setIsSubmitBtnDisable: (store, { payload }) => ({
      ...store,
      isSubmitBtnDisable: payload,
    }),
  },
});

export const {
  reset,
  setMainTitle,
  setForTitle,
  setDate,
  setTechnologiesOptions,
  setSelectedTechnologies,
  setProjects,
  setSelectedProjects,
  setPortfolioUrl,
  setIsProjectsEditorOpen,
  setIsSubmitBtnDisable,
  setSelectedLanguage,
} = diagramSlice.actions;

export default diagramSlice.reducer;
