import React, { PureComponent } from 'react';
import styled, { keyframes } from 'styled-components';

import xzibitAudio from './sasha_soundtrack.mp3';
import carImage from './sasha_car.png';
import headImage from './sasha_head.png';

const audio = new Audio(xzibitAudio);

class Xzibit extends PureComponent {
  state = { show: false };

  componentDidMount() {
    audio.addEventListener('ended', this.endListener);
  }

  componentWillUnmount() {
    audio.removeEventListener('ended', this.endListener);
  }

  endListener = () => {
    this.play = false;
    audio.currentTime = 0;
    this.setState({ show: false });
    audio.currentTime = 0;
  };

  startAnimation = () => {
    this.setState({ show: true });
    audio.play();
  };

  render() {
    const { show } = this.state;

    return (
      <React.Fragment>
        {show
          ? null
          : (
            <Car onClick={this.startAnimation} />
          )
        }

        {!show
          ? null
          : (
            <StyledContainder>
              <img src={carImage} alt="car" className="car" />

              <img src={headImage} alt="sasha_head" className="head" />
            </StyledContainder>
          )
        }
      </React.Fragment>
    );
  }
}

const xzibitCarWidth = 70;

const xzibitCarAnimation = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(40deg);
  }
`;

const carRide = keyframes`
  from {
    right: -${xzibitCarWidth}vh;
  }

  to {
    right: calc(100% + ${xzibitCarWidth}vh);
  }
`;

const headAnimation = keyframes`
  from {
    bottom: 54%;
    right: 35%;
  }

  to {
    bottom: calc(54% - 20px);
    right: calc(35% + 20px);
  }
`;

const StyledContainder = styled.div`
  position: fixed;
  z-index: 2003;
  bottom: 10%;
  right: -${xzibitCarWidth}vh;
  width: ${xzibitCarWidth}vh;

  animation-name: ${xzibitCarAnimation}, ${carRide};
  animation-iteration-count: infinite;
  animation-duration: 0.8s, 20s;
  transform-origin: 82% 86%;
  animation-direction: alternate;

  .car {
    width: 100%;
  }

  .head {
    position: absolute;
    transform: rotate(5deg);
    width: 22%;
    bottom: 54%;
    right: 35%;

    animation-name: ${headAnimation};
    animation-iteration-count: infinite;
    animation-direction: alternate;
    animation-duration: 0.3s;
    animation-timing-function: linear;
  }
`;

const triggerCarWidth = 18;

const triggerCarAnimation = keyframes`
  0% {
  right: -${triggerCarWidth}vh;
  }

  30%,
  100% {
    right: calc(100% + ${triggerCarWidth}vh);
  }
`;

const Car = styled.div`
  background-image: url(${carImage});
  background-size: cover;
  background-repeat: no-repeat;
  position: fixed;
  width: ${triggerCarWidth}vh;
  height: 6vh;
  bottom: 10vh;
  right: -${triggerCarWidth}vh;
  /* right: 40px; */
  z-index: 2003;
  cursor: pointer;
  
  animation-name: ${triggerCarAnimation};
  animation-iteration-count: infinite;
  animation-duration: 7s;
  /* animation-duration: 20s; */
  animation-timing-function: linear;
  animation-delay: 10s;

  ::before {
    content: 'Не нажимать!';
    position: absolute;
    top: -4vh;
    left: -25%;
    width: 150%;
    font-size: 16px;
    text-align: center;
    color: ${({ theme }) => theme.colors.mainText};
  }
`;

export default Xzibit;
