import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { extraHoursPerPage } from 'src/utils/constants';
import extraHoursApi from 'src/apiV2/extraHoursApi';
import {
  setExtraList,
  setUnpaidExtraHours,
  setTotalPages,
} from './reducer';
import { toggleLoader } from '../../../store/main/reducer';

export const getExtraHoursList = createAsyncThunk(
  'extra/getFilteredExtraHoursList',
  async (args, { dispatch, getState }) => {
    try {
      const { extra } = getState();

      if (!extra.isPopover) {
        dispatch(toggleLoader(true));
      }

      const options = {
        page: extra.page,
        perPage: extraHoursPerPage,
        sortBy: extra.sortBy,
        sortDirection: extra.sortDirection,
      };

      if (extra.isProcessed) {
        options.isProcessed = extra.isProcessed.value;
      }

      if (extra.selectedUsers) {
        options.selectedUsers = [extra.selectedUsers.value];
      }

      const data = await extraHoursApi.getList(options);

      dispatch(setTotalPages(Math.ceil(data.total / options.perPage)));
      dispatch(setExtraList(data.data));
    } catch (err) {
      toast.error('Не удалось получить список переработок');
    } finally {
      dispatch(toggleLoader(false));
    }
  },
);

export const getAllUnpaid = createAsyncThunk(
  'extra/getAllUnpaid',
  async (args, { dispatch, getState }) => {
    try {
      const { extra } = getState();

      if (!extra.isPopover) {
        dispatch(toggleLoader(true));
      }

      const options = {
        isProcessed: false,
      };

      const data = await extraHoursApi.getList(options);

      dispatch(setUnpaidExtraHours(data.data));
    } catch (err) {
      toast.error('Не удалось получить список переработок');
    } finally {
      dispatch(toggleLoader(false));
    }
  },
);

export const createExtraHourItem = createAsyncThunk(
  'extra/createExtraHourItem',
  async (data, { dispatch }) => {
    try {
      dispatch(toggleLoader(true));

      const validData = {
        user_id: data.user_id,
        description: data.description,
        start: data.start,
        end: data.end,
        durationMinutes: data.durationMinutes,
      };

      await extraHoursApi.create(validData);
      dispatch(getExtraHoursList());

      toast.success('Успех! Вы добавили переработку');
    } catch (err) {
      toast.error('Не удалось добавить переработку');
    } finally {
      dispatch(toggleLoader(false));
    }
  },
);

export const updateExtraHoursItem = createAsyncThunk(
  'extra/updateExtraHourItem',
  async (data, { dispatch, getState }) => {
    try {
      const { id, newData } = data;
      const { extra } = getState();

      if (!extra.isPopover) {
        dispatch(toggleLoader(true));
      }

      const validData = {
        user_id: newData.user_id,
        description: newData.description,
        start: newData.start,
        end: newData.end,
        durationMinutes: newData.durationMinutes,
        isProcessed: newData.isProcessed,
      };

      await extraHoursApi.update(id, validData);
      dispatch(getExtraHoursList());

      if (!extra.isPopover) {
        toast.success('Успех! Вы обновили переработку');
      }
    } catch (err) {
      toast.error('Не удалось обновить переработку');
    } finally {
      dispatch(toggleLoader(false));
    }
  },
);

export const deleteExtraHourItem = createAsyncThunk(
  'extra/deleteExtraHourItem',
  async (id, { dispatch }) => {
    try {
      dispatch(toggleLoader(true));

      await extraHoursApi.deleteOne(id);
      dispatch(getExtraHoursList());

      toast.success('Успех! Вы удалили переработку');
    } catch (err) {
      toast.error('Не удалось удалить переработку');
    } finally {
      dispatch(toggleLoader(false));
    }
  },
);
