import axios from 'src/apiV2/axios';

const path = '/erp-diagram';

export const getErpData = () => {
  return axios.get(path);
};

export default {
  getErpData,
};
