import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import Select from 'react-select';
import moment from 'moment';
import PropTypes from 'prop-types';

import Grid from '@mui/material/Grid';
import InputLabel from '@mui/material/InputLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import InfoOutlined from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';

import SelectWrapper from 'src/ui/components/SelectWrapper';
import CustomDatePicker from 'src/ui/components/DatePicker/CustomDatePicker';
import { userOptionsForSelect } from 'src/utils/arrayToSelectOptions';
import StyledForm from './ExtraHoursForm.style';
import { extraObjectType, ExtraHoursValidationType } from '../../../utils/types';

const initialDescription = 'ИМЯ_ПРОЕКТА — 00:00\n\nКомментарий: ';
const tooltipDescription = 'Проектов может быть несколько, комментарий в свободной форме, если он нужен.';

const ExtraHourForm = (props) => {
  const usersList = useSelector(({ enums }) => enums.usersList);
  const currentUser = useSelector(({ main }) => main.user);

  const userOptions = useMemo(() => {
    return userOptionsForSelect(usersList);
  }, [usersList]);

  const startDate = useMemo(() => {
    const endOfWeek = moment().weekday(7).format('DD');
    if (+endOfWeek < 7) {
      return moment().startOf('month').format();
    }
    return moment().weekday(1).format();
  }, []);

  const endDate = useMemo(() => {
    const endOfWeek = moment().weekday(7).format();
    const endOfMonth = moment().endOf('month').format();
    if (moment(endOfMonth).diff(endOfWeek, 'days') < 0) {
      return moment().endOf('month').format();
    }
    return moment().weekday(7).format();
  }, []);

  const maxDate = useMemo(() => {
    const maxDateForAdmin = new Date(moment().endOf('year').format());
    if (currentUser?.role === 'admin') {
      return maxDateForAdmin;
    }
    return new Date();
  }, [currentUser?.role]);

  const formik = useFormik({
    initialValues: {
      date: new Date(),
      startDate: new Date(startDate),
      endDate: new Date(endDate),
      hour: '',
      minute: '',
      author: userOptions?.find((user) => user.value === currentUser.id),
      description: initialDescription,
    },
    validationSchema: props.validation,
    onSubmit: (values, formik) => (props.handleOnSubmit(values, formik)),
  });

  const { resetForm, setValues } = formik;
  useEffect(() => {
    if (!props.extraItemForEdit) {
      return resetForm();
    }
    const quantityHours = Math.floor(props.extraItemForEdit.durationMinutes / 60);
    const quantityMinutes = props.extraItemForEdit.durationMinutes - quantityHours * 60;
    setValues({
      date: new Date(props.extraItemForEdit.start),
      startDate: new Date(props.extraItemForEdit.start),
      endDate: new Date(props.extraItemForEdit.end),
      hour: quantityHours,
      minute: quantityMinutes,
      author: userOptions.find((user) => user.value === props.extraItemForEdit.user_id),
      description: props.extraItemForEdit.description,
    });
  }, [props.extraItemForEdit, resetForm, setValues, userOptions]);

  const onSelectChange = (value, data) => {
    formik.handleChange({ target: { value, name: data.name } });
  };

  const onDateChange = (value) => {
    formik.handleChange({ target: { value: value.startDate || value, name: 'startDate' } });
    formik.handleChange({ target: { value: value.endDate || value, name: 'endDate' } });
  };

  return (
    <StyledForm onSubmit={formik.handleSubmit}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        spacing={2}
        className="extra-creation-form__form"
      >
        <Grid item xs={12} className="extra-creation-form__header">
          <Typography
            variant="h2"
          >
            {props.extraItemForEdit ? 'Обновить переработку' : 'Добавить переработку'}
          </Typography>
        </Grid>
        <Grid item container lg={12} md={12} spacing={2} justifyContent="center">
          <Grid item lg={6} md={6} sm={12} className="extra-creation-form__date">
            <CustomDatePicker
              className="form-input-item"
              onChange={onDateChange}
              value={formik.values.endDate}
              maxDate={maxDate}
              startDate={formik.values.startDate}
              endDate={formik.values.endDate}
              isRange
              extraForm
            />
            {formik.touched.date && formik.errors.date && <p>{formik.errors.date}</p>}
          </Grid>
          <Grid item container lg={6} md={6} sm={12} spacing={1}>
            <Grid item container>
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={formik.values.hour}
                  name="hour"
                  onChange={(ev) => {
                    const newValue = +ev.target.value;
                    if (newValue < 0 || newValue > 23) {
                      return;
                    }
                    formik.handleChange(ev);
                  }}
                  fullWidth
                  onBlur={formik.handleBlur}
                  type="number"
                  placeholder="Часы"
                  error={Boolean(formik.touched.hour && formik.errors.hour)}
                  helperText={formik.touched.hour && formik.errors.hour}
                />
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="h2"
                  className="extra-creation-form__time"
                >
                  -
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  variant="outlined"
                  size="small"
                  value={formik.values.minute}
                  name="minute"
                  onChange={(ev) => {
                    const newValue = +ev.target.value;
                    if (newValue < 0 || newValue > 59) {
                      return;
                    }
                    formik.handleChange(ev);
                  }}
                  fullWidth
                  onBlur={formik.handleBlur}
                  type="number"
                  placeholder="Минуты"
                  error={Boolean(formik.touched.minute && formik.errors.minute)}
                  helperText={formik.touched.minute && formik.errors.minute}
                />
              </Grid>
            </Grid>
            {currentUser.role === 'admin' && !props.isPersonalExtra && (
              <Grid item xs={12} className="extra-creation-form__inputs">
                <InputLabel className="extra-creation-form__field-label">
                  Автор
                </InputLabel>
                <SelectWrapper>
                  <Select
                    className="extra-creation-form__inputs"
                    name="author"
                    classNamePrefix="select"
                    value={formik.values.author}
                    options={userOptions}
                    closeMenuOnSelect
                    onChange={onSelectChange}
                  />
                </SelectWrapper>
                {formik.touched.author && formik.errors.author && <p>{formik.errors.author}</p>}
              </Grid>
            )}
            <Grid item container xs={12} alignItems="flex-end">
              <InputLabel className="extra-creation-form__field-label">
                <Typography variant="h2" className="extra-creation-form__description-label">
                  Описание
                  <Tooltip title={tooltipDescription} placement="bottom">
                    <InfoOutlined className="extra-creation-form__info-label" />
                  </Tooltip>
                </Typography>
              </InputLabel>
              <TextField
                variant="outlined"
                multiline
                minRows={3}
                maxRows={6}
                value={formik.values.description}
                name="description"
                onChange={formik.handleChange}
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                onBlur={formik.handleBlur}
                // To disble unfocusing
                onKeyDown={(ev) => ev.stopPropagation()}
                error={Boolean(formik.touched.description && formik.errors.description)}
                helperText={formik.touched.description && formik.errors.description}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item container justifyContent="center" xs={12} spacing={3} className="extra-creation-form__buttons">
          <Grid item lg={6} xs={6}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={
                !formik.values.date &&
                !formik.values.author &&
                !formik.values.hour &&
                !formik.values.minute
              }
            >
              {props.extraItemForEdit ? 'Сохранить' : 'Добавить'}
            </Button>
          </Grid>
          <Grid item lg={6} xs={6}>
            <Button
              variant="contained"
              fullWidth
              onClick={() => props.toggleModalVisibility(true)}
            >
              Отмена
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </StyledForm>
  );
};

ExtraHourForm.propTypes = {
  extraItemForEdit: extraObjectType,
  toggleModalVisibility: PropTypes.func,
  isPersonalExtra: PropTypes.bool,
  handleOnSubmit: PropTypes.func,
  validation: ExtraHoursValidationType,
};

ExtraHourForm.defaultProps = {
  extraItemForEdit: null,
  toggleModalVisibility: () => null,
  isPersonalExtra: false,
  handleOnSubmit: () => null,
  validation: {},
};

export default ExtraHourForm;
