export const selectOptionsToArray = (selectOptions) => {
  if (selectOptions) {
    return selectOptions.map((item) => item.value);
  }
  return null;
};

export const REQUEST_TYPES = {
  dayOff: 'dayOff',
  technical: 'technical',
  documents: 'documents',
  common: 'common',
  vacation: 'vacation',
  medical: 'medical',
  performanceReview: 'performanceReview',
};

export const REQUEST_TYPES_NAMES = {
  dayOff: 'Отгул',
  technical: 'Технический',
  documents: 'Документы',
  common: 'Бытовой',
  vacation: 'Отпуск',
  medical: 'Больничный',
  performanceReview: 'Перформанс ревью',
};

export const REQUEST_STATUSES = {
  wait: 'wait',
  inProgress: 'inProgress',
  completed: 'completed',
  accept: 'accept',
  denied: 'denied',
};

export const REQUEST_STATUS_NAMES = {
  wait: 'Ожидает рассмотрения',
  inProgress: 'Выполняется',
  completed: 'Выполнена',
  accept: 'Одобрена',
  denied: 'Отклонена',
};

export const REQUEST_TYPE_OPTIONS = [
  { label: REQUEST_TYPES_NAMES.dayOff, value: REQUEST_TYPES.dayOff },
  { label: REQUEST_TYPES_NAMES.technical, value: REQUEST_TYPES.technical },
  { label: REQUEST_TYPES_NAMES.documents, value: REQUEST_TYPES.documents },
  { label: REQUEST_TYPES_NAMES.common, value: REQUEST_TYPES.common },
  { label: REQUEST_TYPES_NAMES.vacation, value: REQUEST_TYPES.vacation },
  { label: REQUEST_TYPES_NAMES.medical, value: REQUEST_TYPES.medical },
  { label: REQUEST_TYPES_NAMES.performanceReview, value: REQUEST_TYPES.performanceReview },
];

export const REQUEST_STATUS_OPTIONS = [
  { label: REQUEST_STATUS_NAMES.wait, value: REQUEST_STATUSES.wait },
  { label: REQUEST_STATUS_NAMES.inProgress, value: REQUEST_STATUSES.inProgress },
  { label: REQUEST_STATUS_NAMES.completed, value: REQUEST_STATUSES.completed },
  { label: REQUEST_STATUS_NAMES.accept, value: REQUEST_STATUSES.accept },
  { label: REQUEST_STATUS_NAMES.denied, value: REQUEST_STATUSES.denied },
];
