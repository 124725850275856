export type { ErrorResponseType, ResponseType, FindAndCountMetaType, ListResponseType } from 'src/apiV3/http/http.types';
export type { UserType } from './userTypes';
export type { QuizQuestionType } from './events';
export type { JobPositionType, JobPositionSortByType } from './jobPositionsTypes';
export { UserRoleENUM, UserStatusENUM, UserTechRoleENUM, UserSortByENUM } from './userTypes';
export type { CustomerType } from './customerTypes';
export type { CompanyType } from './companyTypes';
export type { ICVData } from './CVData';

export enum SortDirectionENUM {
  asc = 'ASC',
  desc = 'DESC',
}

export enum ProjectRolesENUM {
  PM = 'PM',
  sales = 'Sales',
  codeReviewer = 'Code Reviewer',
  techLead = 'Tech Lead',
  QA = 'QA',
  designer = 'Designer',
  developer = 'Developer',
}
