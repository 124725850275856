import React from 'react';
import type { SingleValue } from 'react-select';
import Select from 'react-select';
import SelectWrapper from 'src/ui/components/SelectWrapper';
import type { ResponseDeleteTaskType } from 'src/apiV3/internshipApi';
import internshipApi from 'src/apiV3/internshipApi';
import TasksInternsWrapper from './TasksInterns.styles';
import type { UserType } from 'src/types';
import { useAppDispatch, useAppSelector } from 'src/store/store';
import TaskItem from '../taskItem';
import { type TaskType } from '../../utils/types';
import DetailTask from '../DetailTask/DetailTask';
import Modal from 'src/ui/components/Modal';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import CreateAndEditTask from '../CreateAndEditTask/CreateAndEditTask';
import { getCurrentInternTasks, selectInternId } from '../../utils/selectors';
import classnames from 'classnames';
import thunk from '../../store/thunks';

type PropsType = {
  mentorsOptions?: {
    label: string;
    value: string | number;
  }[];
  currentIntern: UserType | null;
  updateMentors: (
    userId: number,
    mentorId: string | number | undefined
  ) => void;
  interns: UserType[];
};
const TasksInterns: React.FC<PropsType> = (props) => {
  const id = useAppSelector(selectInternId);
  const tasks = useAppSelector(getCurrentInternTasks);
  const [currentTask, setCurrentTask] = React.useState<TaskType | null>(null);
  const [isShowTaskDetail, setIsShowTaskDetails] = React.useState(false);
  const [isUpdateTask, setIsUpdateTask] = React.useState(false);
  const currentInternInfo = props.interns.find((intern) => intern.id === id);
  const dispatch = useAppDispatch();
  const mentorsOptions = React.useMemo(() => {
    if (!props.mentorsOptions) {
      return undefined;
    }
    return [{ label: 'Не назначен', value: 0 }, ...props.mentorsOptions];
  }, [props.mentorsOptions]);
  const initialValue = React.useMemo(() => {
    if (!id && !currentInternInfo) {
      return mentorsOptions?.filter((mentor) => {
        return mentor.value === 0;
      })[0];
    }
    return mentorsOptions?.filter((mentor) => {
      return mentor.value === currentInternInfo?.mentor_id;
    })[0];
  }, [id, mentorsOptions, currentInternInfo]);

  const notEmptyHolderTask = React.useMemo(() => {
    return id && tasks;
  }, [id, tasks]);

  const changeHandler = async (
    newValue: SingleValue<{
      label: string;
      value: string | number;
    }>,
  ) => {
    if (!id || !props.updateMentors) {
      return;
    }

    if (!newValue) {
      await internshipApi.updateMentor({ userId: id, mentorId: 0 });
      props.updateMentors(id, 0);
      return;
    }
    const data = {
      userId: id,
      mentorId: +newValue.value,
    };
    await internshipApi.updateMentor(data);
    props.updateMentors(data.userId, data.mentorId);
  };

  const toOpenTaskDetail = (task: TaskType) => {
    setCurrentTask({
      ...task,
      taskJobId: task.planTask,
      id: task.taskJobId,
    });
    setIsShowTaskDetails(true);
  };

  const toCloseTaskDetail = () => {
    setIsShowTaskDetails(false);
  };

  const toOpenCreateTaskWindow = () => {
    setIsUpdateTask(true);
    setIsShowTaskDetails(false);
  };

  const toCloseCreateTaskWindow = () => {
    setIsUpdateTask(false);
  };
  const toCreateOrEdit = async (task: TaskType) => {
    setIsUpdateTask(false);
  };

  const toDeleteTask = () => {
    if (!currentTask?.id) return;
    const data : ResponseDeleteTaskType = {
      id: currentTask.id,
      actionFlag: 'plan',
      userId: id,
    };
    dispatch(thunk.deleteTaskPlan(data));
    setIsShowTaskDetails(false);
    setCurrentTask(null);
  };

  return (
    <TasksInternsWrapper>
      <header className="header">Задания стажёра</header>

      <SelectWrapper className="mentors-selector">
        <Select
          options={mentorsOptions}
          value={initialValue}
          onChange={changeHandler}
          classNamePrefix="select"
          isSearchable
          isClearable
          isDisabled={!id}
        />
      </SelectWrapper>

      <Droppable droppableId="internTasks">
        {(provided) => (
          <div
            className="tasks"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {notEmptyHolderTask &&
              tasks?.map(
                (task, index) => task.taskJobId && (
                    <Draggable
                      key={task.taskJobId}
                      draggableId={task.taskJobId.toString()}
                      index={index}
                      disableInteractiveElementBlocking
                    >
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="tasks-list"
                        >
                          <TaskItem
                            isParenTask={false}
                            key={task.taskJobId}
                            task={task}
                            toOpenTaskDetail={toOpenTaskDetail}
                          />
                        </div>
                      )}
                    </Draggable>
                ),
              )}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
      <Modal open={isShowTaskDetail} onClose={toCloseTaskDetail}>
        <DetailTask
        toDeleteTask={toDeleteTask}
          task={currentTask}
          toOpenCreateTaskWindow={toOpenCreateTaskWindow}
        />
      </Modal>

      <Modal open={isUpdateTask} onClose={toCloseCreateTaskWindow}>
        <CreateAndEditTask
          flag="plan"
          setCurrentTask={setCurrentTask}
          task={currentTask}
          onSubmit={toCreateOrEdit}
          onClose={toCloseCreateTaskWindow}
        />
      </Modal>
    </TasksInternsWrapper>
  );
};

export default TasksInterns;
