import axios from 'axios';
import _ from 'lodash';

import config, { isDev } from 'src/config';
import tokenUtil from 'src/utils/token';
import { logOut } from 'src/utils';
import getDeviceId, { DEVICE_ID_KEY } from '../apiV3/http/getDeviceId';

const BODY_SIZE = 100 * 1024 * 1024; // 100 MB

const customAxios = axios.create({
  baseURL: `${config.apiUrl}/v2`,
  headers: {
    [DEVICE_ID_KEY]: getDeviceId(),
  },
  maxContentLength: BODY_SIZE,
  maxBodyLength: BODY_SIZE,
  // TO-DO: Return when we will have a cookie authorization
  // withCredentials: true,
});

export const setToken = (token) => {
  customAxios.defaults.headers.Authorization = `Bearer ${token}`;
};

customAxios.defaults.headers.app_id = config.appId;
setToken(tokenUtil.access.get());

customAxios.interceptors.request.use(async (request) => {
  if (isDev) {
    // eslint-disable-next-line no-promise-executor-return
    await new Promise((res) => setTimeout(res, _.random(100, 500)));
  }
  // TO-DO: Return when we will have a device check
  // request.headers.device = '';
  return request;
});

customAxios.interceptors.response.use(
  ({ data }) => data,
  async (err) => {
    if (err?.response?.status === 401) {
      if (!refreshPromise) {
        refreshPromise = refreshToken();
      }
      const isReseted = await refreshPromise;

      if (!isReseted) {
        throw err;
      }
      const request = err.config;

      request.headers.Authorization = `Bearer ${tokenUtil.access.get()}`;

      return customAxios(request);
    }

    return Promise.reject(err.response);
  },
);

let refreshPromise = null;
const refreshToken = async () => {
  try {
    const { data } = await axios.post(
      `${config.apiUrl}/v2/auth/refresh`,
      { token: tokenUtil.refresh.get() },
      { headers: { [DEVICE_ID_KEY]: getDeviceId() } },
    );

    tokenUtil.access.set(data.access);
    tokenUtil.refresh.set(data.refresh);

    setToken(data.access);

    return true;
  } catch (err) {
    logOut();
    return false;
  } finally {
    refreshPromise = null;
  }
};

export default customAxios;
