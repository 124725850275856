import styled from 'styled-components';
import MainPageContent from 'src/ui/components/MainPageContent';

const InternshipWrapper = styled(MainPageContent)`
  /* display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 1px; */
  /* > * {
    width: 31%;
  } */

  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1%;

  .header {
    position: relative;
    display: grid;
    grid-template-columns: 1fr;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 40px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.navbar.text};
  }

  .header__allTask {
    position: relative;
    display: grid;
    grid-template-columns: 40px 1fr 40px;
    justify-content: center;
    align-items: center;
    text-align: center;
    min-height: 40px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.navbar.text};
  }

  .tasks {
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    flex: 1 1 auto;
    width: -webkit-fill-available;
    padding: 5px;
  }
  .interns {
    overflow-y: auto;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    gap: 3px;
    width: -webkit-fill-available;
    padding: 5px;
  }
  .tasks-list {
    will-change: transform;
    touch-action: none;
  }
  .task {
    /* touch-action: none; */
    display: grid;
    grid-template-columns: 1fr;
    position: relative;
    align-items: center;
    justify-content: center;
    min-height: 40px;
    border: 1px solid ${({ theme }) => theme.colors.navbar.text};
    cursor: pointer;
    user-select: none;
    margin-bottom: 3px;
    background-color: ${({ theme }) => theme.colors.pageBackground};
    text-overflow: ellipsis;
    .title__grid {
      width: 100%;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  .task-disabled {
    color: ${({ theme }) => theme.colors.navbar.text};
  }
  .task-intern {
    display: grid;
    grid-template-columns: 27px 1fr;
    /* .title__grid {
      width: 100%;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
    } */
  }
  .deadline {
    border: 1px solid ${({ theme }) => theme.colors.iconsIntern.deadline};
    color: ${({ theme }) => theme.colors.iconsIntern.deadline};
  }
  .current-item {
    border: 1px solid ${({ theme }) => theme.colors.primary.main};
  }

  .current-mentor {
    background-color: ${({ theme }) => theme.colors.primary.dark};
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 200px auto;
    gap: 1%;

    & > :first-child {
      grid-column: 1 / -1;
    }

    & > :nth-child(2) {
      grid-column: 1 / 2;
    }

    & > :nth-child(3) {
      grid-column: 2 / -1;
    }
  }
`;

export default InternshipWrapper;
