import React from 'react';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import HighlightOffOutlinedIcon from '@mui/icons-material/HighlightOffOutlined';
import theme from 'src/ui/styles/StyledComponentsTheme/themes/main';

interface IIconTaskProps {
  icon: 'new' | 'started' | 'done' | 'deadline' | 'badDone';
}

const IconTaskProcess: React.FC<IIconTaskProps> = ({ icon }) => {
  const iconMap = {
    new: (
      <RadioButtonUncheckedIcon sx={{ color: theme.colors.iconsIntern.new }} />
    ),
    started: (
      <AccessTimeIcon sx={{ color: theme.colors.iconsIntern.started }} />
    ),
    deadline: (
      <HighlightOffOutlinedIcon
        sx={{ color: theme.colors.iconsIntern.deadline }}
      />
    ),
    badDone: (
      <CheckCircleOutlineIcon
        sx={{ color: theme.colors.iconsIntern.badDone }}
      />
    ),
    done: (
      <CheckCircleOutlineIcon sx={{ color: theme.colors.iconsIntern.done }} />
    ),
  };

  return iconMap[icon] || null;
};

export default IconTaskProcess;
