import type { ProjectRolesENUM, UserType } from 'src/types';
import type { ResponseType } from '../http';
import http from '../http';

const ERP_PATH = '/crm/erp';

type TaskItemType = {
  startDate: Date;
  endDate: Date;
  id: number;
  role: ProjectRolesENUM;
  user_id: number;
  task_id: number;
  createdAt: Date;
  updatedAt: Date;
  title: string;
};

type TaskItemWithUserType = TaskItemType & {
  user: UserType;
};

export type ErpDataParamsType = {
  dateFrom: Date;
  dateTo: Date;
  onlyDevs: boolean;
};

export type CrmTasksType = {
  tasks: TaskItemWithUserType[];
};

export const getErpDataAxios = (params: ErpDataParamsType) => {
  return http.get<ResponseType<CrmTasksType>>(`${ERP_PATH}/data`, { params });
};
