/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

import { getCurrentQuery } from 'src/utils/hooks/useQueryString';
import { optionErpGroups } from 'src/pages/ErpDiagram/constants';
import { getErpDataThunk } from 'src/pages/ErpDiagram/store/thunks';

const queryData = getCurrentQuery();
const TAB_PARAMS_NAME = 'tab';
export const TAB_OPTIONS = ['diagram', 'list', 'diagramV2'];
const initialTab = TAB_OPTIONS.findIndex((i) => i === queryData[TAB_PARAMS_NAME]);

const initialGroups = optionErpGroups.filter((option) => {
  return queryData.selectedGroups?.includes(option.value);
});

export const getInitialStore = () => ({
  devs: null,
  requests: null,
  jobs: null,
  students: null,
  studentTasks: null,
  hotLeadSales: null,
  hotLeadTasks: null,
  users: null,
  isLoading: false,
  tab: initialTab !== -1 ? initialTab : 0,
  filter: {
    selectedUsers: [],
    selectedGroups: (queryData.selectedGroups === 'null' || initialGroups.length) ? initialGroups : optionErpGroups,
  },
  tasks: [],
});

const diagramSlice = createSlice({
  name: 'erpDiagram',
  initialState: getInitialStore(),
  reducers: {
    setDevIds: (store, { payload }) => ({
      ...store,
      devIds: payload,
    }),
    setRequests: (store, { payload }) => ({
      ...store,
      requests: payload,
    }),
    setJobs: (store, { payload }) => ({
      ...store,
      jobs: payload,
    }),
    setStudentIds: (store, { payload }) => ({
      ...store,
      studentIds: payload,
    }),
    setStudentTasks: (store, { payload }) => ({
      ...store,
      studentTasks: payload,
    }),
    setHotLeadSaleIds: (store, { payload }) => ({
      ...store,
      hotLeadSaleIds: payload,
    }),
    setHotLeadTasks: (store, { payload }) => ({
      ...store,
      hotLeadTasks: payload,
    }),
    setUsers: (store, { payload }) => ({
      ...store,
      users: payload,
    }),
    setIsLoading: (store, { payload }) => ({
      ...store,
      isLoading: payload,
    }),
    updateFilter: (store, { payload }) => ({
      ...store,
      filter: {
        ...store.filter,
        [payload.name]: payload.value,
      },
    }),
    clearFilter: (store) => ({
      ...store,
      filter: {
        selectedUsers: [],
        selectedGroups: optionErpGroups,
      },
    }),
    setTab: (store, { payload }) => ({
      ...store,
      tab: payload,
    }),
  },
  extraReducers: (builder) => {
    builder.addCase(getErpDataThunk.fulfilled, (state, action) => {
      state.tasks = action.payload.tasks || [];
    });
  },
});

export const {
  setDevIds,
  setRequests,
  setJobs,
  setStudentIds,
  setStudentTasks,
  setHotLeadSaleIds,
  setHotLeadTasks,
  setUsers,
  updateFilter,
  clearFilter,
  setTab,
  setIsLoading,
} = diagramSlice.actions;

export default diagramSlice.reducer;
