import styled, { css } from 'styled-components';

type PropsType = {
  isSortingDirectionAsc: boolean;
};

const TasksAllWrapper = styled.div<PropsType>`
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.navbar.text};

  .add-button {
    margin: 0 auto;
    width: 100%;
    max-width: 30px;
    max-height: 30px;
    cursor: pointer;
    background: none;
    border: none;
    .MuiSvgIcon-root-ffjXHZ {
      width: 100%;
      height: 100%;

      margin: 0 auto;
    }
  }

  .sort-button {
    width: 100%;
    margin: 0 auto;
    max-height: 30px;
    max-width: 30px;
    background: none;
    border: none;
    cursor: pointer;
    ${(props) => {
    if (!props.isSortingDirectionAsc) {
      return css`
          transform: rotate(180deg);
          top: 2px;
        `;
    }
  }};
    .MuiSvgIcon-root-ffjXHZ {
      width: 100%;
      height: 100%;
      margin: 0 auto;
    }
  }

  .filter-container {
    display: flex;
    flex-direction: row;
    padding: 5px 0 5px 5px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.navbar.text};
    .search-field {
      width: 100%;
      .MuiInputBase-root {
        height: 38px;
      }
      .MuiFormLabel-root {
        margin-top: -7px;
      }
      .MuiFormLabel-root.Mui-focused {
        margin-top: 2px;
      }
    }

    .tasks-check-box {
      max-width: 40px;
      max-height: 40px;
    }
  }
`;

export default TasksAllWrapper;
