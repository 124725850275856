import React, { memo } from 'react';
import styled from 'styled-components';
import { useSelector } from 'react-redux';

import { isMdWidth } from 'src/utils';
import useWidth from 'src/utils/hooks/useWidth';

const PageTitle = () => {
  const width = useWidth();

  const title = useSelector(({ main }) => main.pageTitle);

  if (!isMdWidth(width)) { return null; }

  return (
    <StyledTitle>
      {title}
    </StyledTitle>
  );
};

const StyledTitle = styled.p`
  padding: 15px;
  text-transform: uppercase;
  opacity: 0.7;
`;

export default memo(PageTitle);
