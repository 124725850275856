/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const getInitialStore = () => ({
  crmTasks: [],
  users: [],
  crmTasksPage: 1,
  crmTasksCount: 1,
  crmTasksSearch: '',
  usersSearch: '',
  usersPage: 1,
  usersCount: 1,
  crmTasksSortBy: 'project_start_date',
  crmTasksSortDirection: 'straight',
  usersSortBy: 'lastName_ru',
  usersSortDirection: 'straight',
  usersSelectedTechnologies: null,
  crmTasksSelectedTechnologies: null,
  onlyDevFilter: true,
  currentUserForEdit: null,
  currentTaskForEdit: null,
});

const reviewersSlice = createSlice({
  name: 'reviewers',
  initialState: getInitialStore(),
  reducers: {
    setCrmTasks(store, { payload }) {
      store.crmTasks = payload;
    },
    setUsers(store, { payload }) {
      store.users = payload;
    },
    setCrmTasksPage(store, { payload }) {
      store.crmTasksPage = payload;
    },
    setUsersPage(store, { payload }) {
      store.usersPage = payload;
    },
    setCrmTasksCount(store, { payload }) {
      store.crmTasksCount = payload;
    },
    setUsersCount(store, { payload }) {
      store.usersCount = payload;
    },
    setCrmTasksSearch(store, { payload }) {
      store.crmTasksSearch = payload;
    },
    setUsersSearch(store, { payload }) {
      store.usersSearch = payload;
    },
    setCrmTasksSortBy(store, { payload }) {
      store.crmTasksSortBy = payload;
    },
    setCrmTasksSortDirection(store, { payload }) {
      store.crmTasksSortDirection = payload;
    },
    setUsersSortBy(store, { payload }) {
      store.usersSortBy = payload;
    },
    setUsersSortDirection(store, { payload }) {
      store.usersSortDirection = payload;
    },
    setUsersSelectedTechnologies(store, { payload }) {
      store.usersSelectedTechnologies = payload ? payload.map((option) => {
        return +option.value;
      }) : null;
    },
    setCrmTasksSelectedTechnologies(store, { payload }) {
      store.crmTasksSelectedTechnologies = payload ? payload.map((option) => {
        return +option.value;
      }) : null;
    },
    setOnlyDevFilter(store, { payload }) {
      store.onlyDevFilter = payload;
    },
    setCurrentUserForEdit(store, { payload }) {
      store.currentUserForEdit = payload;
    },
    setCurrentTaskForEdit(store, { payload }) {
      store.currentTaskForEdit = payload;
    },
  },
});

export const {
  setCrmTasks,
  setUsers,
  setCrmTasksPage,
  setUsersPage,
  setCrmTasksCount,
  setUsersCount,
  setCrmTasksSearch,
  setUsersSearch,
  setCrmTasksSortBy,
  setCrmTasksSortDirection,
  setUsersSortBy,
  setUsersSortDirection,
  setUsersSelectedTechnologies,
  setCrmTasksSelectedTechnologies,
  setOnlyDevFilter,
  setCurrentUserForEdit,
  setCurrentTaskForEdit,
} = reviewersSlice.actions;

export default reviewersSlice.reducer;
